import _ from "lodash";
import moment from "moment";
import Vue from "vue";

export const setStockAuto = (products, fulfilled) => {
  var isStockCompany = Vue.prototype.$branch.isStockCompany;
  products.filter((product) => {
    [product].concat(product.isBundle ? product.children : []).forEach((p) => {
      p.availableStocks.forEach((i) => {
        if (!i.batchName) {
          i.batchName = "-";
        }
      });
      p.stocks = p.availableStocks.mergeTotal("stockId", "availableQty");

      var bs = _.orderBy(
        p.availableStocks,
        [
          "isDefaultTmp",
          "expiryDateTmp",
          "manufacturingDateTmp",
          "inventoryDateTmp",
        ],
        ["desc", "asc", "asc", "asc"]
      );

      p.batchNames = bs;

      p.fulfills = [];
      p.remain = product.remain * (p.bundleQuantity || 1);
      if (fulfilled && fulfilled.length) {
        fulfilled
          .filter(
            (i) =>
              i.productId == p.productId &&
              i.bundleId == p.bundleId &&
              i.isGift == p.isGift
          )
          .map((f) => {
            var oldS = p.fulfills.find((i) => i.stockId == f.stockId);
            if (oldS) {
              oldS.batchNames.push({
                quantity: f.quantity,
                batchName: f.batchName,
              });
            } else {
              var bs = p.batchNames.filter((i) => i.stockId == f.stockId);
              p.fulfills.push({
                stockId: f.stockId,
                allBatchNames: bs,
                batchNames: [
                  {
                    quantity: f.quantity,
                    batchName: f.batchName == null ? "-" : f.batchName,
                  },
                ],
              });
            }
          });
      } else {
        p.remain = product.remain * (p.bundleQuantity || 1);
        var remain = p.remain;
        p.fulfills = [];
        bs.filter((a) => !a.isNotForSell).forEach((b) => {
          if (remain <= 0) {
            return;
          }
          var stock = p.fulfills.find((i) => i.stockId == b.stockId);
          var q = Math.min(remain, b.availableQty);

          remain = remain - q;
          if (q > 0) {
            if (stock) {
              stock.batchNames.push({
                quantity: q,
                batchName: b.batchName,
              });
            } else {
              p.fulfills.push({
                stockId: b.stockId,
                allBatchNames: bs.filter((i) => i.stockId == b.stockId),
                batchNames: [
                  {
                    quantity: q,
                    batchName: b.batchName,
                  },
                ],
              });
            }
          }
        });
        if (remain > 0 && (p.saleOver || !isStockCompany)) {
          if (p.fulfills[0]) {
            p.fulfills[0].batchNames[0].quantity += remain;
          } else {
            var s = bs[0] || {};
            p.fulfills = [
              {
                stockId: s.stockId,
                allBatchNames: bs.filter((i) => i.stockId == s.stockId),
                batchNames: [
                  {
                    quantity: remain,
                    batchName: s.batchName,
                  },
                ],
              },
            ];
          }
          remain = 0;
        }
      }

      if (p.fulfills.length == 0) {
        p.fulfills = [
          {
            stockId: "",
            allBatchNames: [],
            batchNames: [
              {
                quantity: 0,
                batchName: "-",
              },
            ],
          },
        ];
      }
      p.quantityInput = product.remain * (p.bundleQuantity || 1);
      p.fulfills.forEach((i) => {
        i.quantity = i.batchNames.reduce((a, b) => a + +b.quantity, 0);
      });
      p.totalFF = p.fulfills.reduce((a, b) => a + +b.quantity, 0);
      p.availableQty = p.availableStocks.reduce(
        (a, b) => a + +b.availableQty,
        0
      );

      if (
        p.remain > p.availableQty &&
        !p.isBundle &&
        !p.noCheckWarehouse &&
        !p.saleOver &&
        isStockCompany
      ) {
        p.missStock = true;
      }
      if (
        p.availableQty == 0 &&
        // p.totalFF == 0 &&
        !p.isBundle &&
        !p.noCheckWarehouse &&
        !p.saleOver &&
        isStockCompany
      ) {
        p.outOfStock = true;
      }
    });
  });
  return products;
};

export const countPriceB2B = (order) => {
  let total = 0;
  order.totalQuantity = 0;
  order.beforeSaleOffTotal = 0;
  order.saleOffTotal = 0;
  order.discountCustomerTotal = 0;
  order.surchargeProductTotal = 0;
  order.costTotal = 0;
  order.taxMoney = 0;
  order.totalAfterTaxProduct = 0;
  order.invoiceInitials = order.invoiceInitials || [];
  order.totalProductQuantity = 0;
  order.totalGiftQuantity = 0;
  order.priceLevelId = order.priceLevelId || Vue.prototype.$priceLevelId;
  order.branchId = order.branchId || Vue.prototype.$branchId;
  if (!order.sameTax) {
    order.percentTax = 0;
  }
  order.invoiceInitials
    .filter((i) => !i.isDelete)
    .map((item) => {
      if (item.productPrices && item.productPrices.length && !order.id) {
        var priceItem = item.productPrices.find(
          (a) =>
            a.branches.id == order.branchId &&
            a.priceLevel.id == order.priceLevelId
        );
        item.productPrice = priceItem ? priceItem.price : item.priceDefault;
      }
      if (item.isGift) {
        item.productPrice = 0;
      }
      item.costPrice = item.costPrice || 0;
      item.surchargeType = item.surchargeType || "MONEY";
      item.saleOffType = item.saleOffType || "MONEY";

      if (item.saleOffType == "PERCENT" && item.saleOffValue > 100) {
        item.saleOffValue = 100;
      }
      item.percentTax = item.percentTax || 0;
      item.surchargeValue = item.surchargeValue || 0;
      item.saleOffValue = item.saleOffValue || 0;
      if (Math.abs(item.saleOffValue) > Math.abs(item.productPrice)) {
        item.saleOffValue = item.productPrice;
      }

      item.discountCustomerPercent = item.discountCustomerPercent || 0;
      item.saleOffMoney =
        item.saleOffType == "PERCENT"
          ? (item.saleOffValue / 100) * item.productPrice
          : item.saleOffValue;
      item.surchargeMoney =
        item.surchargeType == "PERCENT"
          ? (item.surchargeValue / 100) * item.productPrice
          : item.surchargeValue;

      item.discountCustomerMoney =
        item.discountCustomerPercent * 0.01 * item.productPrice;
      item.totalDiscount = item.saleOffMoney + item.discountCustomerMoney;
      item.priceAfter =
        item.productPrice - item.totalDiscount + item.surchargeMoney;

      item.rawQuantity = item.productQuantity;
      item.cashSaleOff = Math.round(item.rawQuantity * item.saleOffMoney);

      item.discountCustomer = Math.round(
        item.rawQuantity * item.discountCustomerMoney
      );
      item.surcharge = Math.round(item.rawQuantity * item.surchargeMoney);
      item.costTotal = Math.round(item.rawQuantity * item.costPrice);

      item.total = Math.round(item.productPrice * item.rawQuantity);

      item.totalPayment =
        item.total - item.cashSaleOff - item.discountCustomer + item.surcharge;

      order.beforeSaleOffTotal += item.total;
      order.saleOffTotal += item.cashSaleOff;
      order.discountCustomerTotal += item.discountCustomer;
      order.surchargeProductTotal += item.surcharge;
      order.costTotal += item.costTotal;

      total += item.totalPayment;
      order.totalQuantity += item.rawQuantity;

      if (item.isGift) {
        order.totalGiftQuantity += item.rawQuantity;
      } else {
        order.totalProductQuantity += item.rawQuantity;
      }

      if (item.isBundle) {
        item.children.forEach((child) => {
          child.rawQuantity = child.bundleQuantity * item.rawQuantity;
          child.productQuantity = child.rawQuantity;
          child.costTotal = child.rawQuantity * child.costPrice;
          order.costTotal += child.costTotal;
        });
      }
    });

  order.invoiceInitials = order.invoiceInitials.filter(
    (i) => i.productQuantity > 0
  );
  order.totalQuantity = Math.round(order.totalQuantity * 100) / 100;
  order.total = total;

  order.totalDiscountInvoice = 0;

  if (order.isMemberDiscount) {
    order.membershipDiscountPercent = order.customer.discountPercent || 0;
    order.membershipDiscountMoney =
      (order.total * order.membershipDiscountPercent) / 100;
  } else {
    order.membershipDiscountPercent = 0;
    order.membershipDiscountMoney = 0;
  }
  order.totalDiscountInvoice += order.membershipDiscountMoney;
  order.numberPointConvert = order.numberPointConvert || 0;
  order.pointToMoney = order.pointToMoney || 1;

  order.moneyPointConvert = Math.round(
    order.numberPointConvert * order.pointToMoney
  );

  if (
    Math.abs(order.moneyPointConvert) >
    Math.abs(total - order.membershipDiscountMoney)
  ) {
    order.moneyPointConvert = total - order.membershipDiscountMoney;
    order.numberPointConvert = Math.round(
      order.moneyPointConvert / order.pointToMoney
    );
  }
  order.totalDiscountInvoice += order.moneyPointConvert;

  order.memberShipAmount =
    order.moneyPointConvert + order.membershipDiscountMoney;

  const totalAfterMember = total - order.totalDiscountInvoice;

  order.voucherDiscount = 0;
  order.couponDiscount = 0;
  // order.cashDiscount = 0;
  order.cashDiscountPercent =
    Math.round((order.cashDiscountPercent || 0) * 100) / 100;

  if (order.invoiceDiscounts) {
    order.invoiceDiscounts
      .filter((i) => !i.isDelete)
      .map((item) => {
        if (item.discountType === "PERCENT") {
          item.discountMoney = (item.discountValue * totalAfterMember) / 100;
          order.couponDiscount += item.discountMoney;
        } else {
          item.discountMoney = item.discountValue;
          order.voucherDiscount += item.discountMoney;
        }
      });
  }
  if (Math.abs(order.voucherDiscount) > Math.abs(totalAfterMember)) {
    order.voucherDiscount = totalAfterMember;
  }
  if (Math.abs(order.couponDiscount) > Math.abs(totalAfterMember)) {
    order.couponDiscount = totalAfterMember;
  }

  order.totalDiscountInvoice += order.couponDiscount;
  order.totalDiscountInvoice += order.voucherDiscount;

  const totalAfterDiscount =
    totalAfterMember - order.voucherDiscount - order.couponDiscount;
  order.cashDiscountType = order.cashDiscountType || "MONEY";
  order.cashDiscount = order.cashDiscount || 0;
  order.cashDiscountPercent = order.cashDiscountPercent || 0;

  if (order.cashDiscountType == "MONEY") {
    order.cashDiscountPercent = (order.cashDiscount * 100) / totalAfterDiscount;
    order.cashDiscountPercent =
      Math.round((order.cashDiscountPercent || 0) * 100) / 100;
  } else {
    if (order.cashDiscountPercent > 100) {
      order.cashDiscountPercent = 100;
    }
    order.cashDiscount = totalAfterDiscount * order.cashDiscountPercent * 0.01;
  }

  if (Math.abs(order.cashDiscount) > Math.abs(totalAfterDiscount)) {
    if (order.cashDiscountType == "MONEY") {
      order.cashDiscount = totalAfterMember - order.voucherDiscount;
      order.cashDiscountPercent =
        (order.cashDiscount * 100) / totalAfterDiscount;
    }
  }

  order.cashDiscount = Math.round(order.cashDiscount);
  order.totalDiscountInvoice += order.cashDiscount;
  order.surcharges = order.surcharges || [];
  order.surcharge = order.surcharges
    .filter((i) => !i.isDelete)
    .reduce((a, b) => a + b.amount, 0);

  order.totalSurcharge = order.surcharge + order.surchargeProductTotal;

  order.totalDiscount =
    order.totalDiscountInvoice +
    order.saleOffTotal +
    order.discountCustomerTotal;

  order.totalDiscountProduct = order.saleOffTotal + order.discountCustomerTotal;

  order.invoiceInitials = order.invoiceInitials.map((item) => {
    if (order.beforeSaleOffTotal) {
      item.percent = item.total / order.beforeSaleOffTotal;
    } else {
      item.percent = item.rawQuantity / order.totalQuantity;
    }

    if (order.totalQuantity == 0) {
      item.percent = 100;
    }

    item.orderSaleOff = Math.round(item.percent * order.totalDiscountInvoice);
    item.orderSaleOffItem = item.orderSaleOff / item.rawQuantity;

    item.orderSurcharge = Math.round(item.percent * order.surcharge);
    item.orderSurchargeItem = item.orderSurcharge / item.rawQuantity;

    item.totalBeforeTax =
      item.totalPayment - item.orderSaleOff + item.orderSurcharge;

    if (order.sameTax) {
      item.percentTax = order.percentTax;
    }

    item.taxMoney = item.totalBeforeTax * item.percentTax * 0.01;
    item.totalAfterTax = item.totalBeforeTax + item.taxMoney;

    item.priceItem =
      Math.round((100 * item.totalBeforeTax) / item.rawQuantity) / 100;
    order.taxMoney += item.taxMoney;
    order.totalAfterTaxProduct += item.totalAfterTax;

    return item;
  });

  order.grandTotalBeforeTax =
    order.beforeSaleOffTotal - order.totalDiscount + order.totalSurcharge;

  order.grandTotal = order.grandTotalBeforeTax + order.taxMoney;
  order.invoiceCardPayments = order.invoiceCardPayments || [];
  order.invoiceCashPayments = order.invoiceCashPayments || [];

  order.invoiceCashPayments = order.invoiceCashPayments.filter(
    (i) => i.totalPayment
  );
  order.invoiceCardPayments = order.invoiceCardPayments.filter(
    (i) => i.totalPayment
  );

  order.extraCash = order.invoiceCashPayments
    .filter((a) => a.isExtra)
    .reduce((a, b) => a + b.totalPayment, 0);

  order.extraCard = order.invoiceCardPayments
    .filter((a) => a.isExtra)
    .reduce((a, b) => a + b.totalPayment, 0);

  order.extraMoney = order.extraCash + order.extraCard;

  order.cashTotal = order.invoiceCashPayments
    .filter((a) => !a.isExtra)
    .reduce((a, b) => a + b.totalPayment, 0);
  order.cardTotal = order.invoiceCardPayments
    .filter((a) => !a.isExtra)
    .reduce((a, b) => a + b.totalPayment, 0);

  order.cashTotalMoney = order.invoiceCashPayments
    .filter((a) => a.totalPayment > 0 && !a.isExtra)
    .reduce((a, b) => a + b.totalPayment, 0);
  order.cashTotalReturnMoney =
    -1 *
    order.invoiceCashPayments
      .filter((a) => a.totalPayment < 0)
      .reduce((a, b) => a + b.totalPayment, 0);

  order.cardTotalMoney = order.invoiceCardPayments
    .filter((a) => a.totalPayment > 0 && !a.isExtra)
    .reduce((a, b) => a + b.totalPayment, 0);
  order.cardTotalReturnMoney =
    -1 *
    order.invoiceCardPayments
      .filter((a) => a.totalPayment < 0)
      .reduce((a, b) => a + b.totalPayment, 0);

  order.totalMoney = order.cashTotalMoney + order.cardTotalMoney;
  order.totalMoneyReturn =
    order.cashTotalReturnMoney + order.cardTotalReturnMoney - order.extraMoney;

  order.remainPayment = order.grandTotal - (order.cardTotal + order.cashTotal);
  order.paidMoney = order.cardTotal + order.cashTotal;

  order.needRefundPayment = 0;
  if (order.remainPayment <= 0) {
    order.needRefundPayment = order.remainPayment * -1;
    order.remainPayment = 0;
    order.paymentStatus = "PAID";
  } else if (order.remainPayment == order.grandTotal) {
    order.paymentStatus = "UNPAID";
  } else {
    order.paymentStatus = "PARTIAL_PAID";
  }

  order.employeeSales = (order.employeeSales || []).map((i) => {
    return {
      id: i.id,
      isDelete: i.isDelete,
      employeeId: i.employeeId,
      employeeName: i.employeeName,
      percentSale: i.percentSale,
      moneySale: i.percentSale * order.grandTotalBeforeTax * 0.01,
    };
  });

  order.invoiceProcessSteps = order.invoiceProcessSteps || [];
  order.invoiceProcessSteps = (order.invoiceProcessSteps || []).map(
    (process) => {
      return countPriceProcess(process, order);
    }
  );
  order.totalDebt = _.sumBy(
    order.invoiceProcessSteps.filter((a) => a.isDebt),
    "grandTotal"
  );

  order.remainDebt = order.totalDebt - order.paidMoney;

  var channel = Vue.prototype.$channels.find(
    (a) => a.name == order.channel
  ) || { isOnline: false };
  order.isOnline = channel.isOnline;

  const totalProductNeed = _.sumBy(order.invoiceInitials, "productQuantity");
  const totalProductStock = _.sumBy(
    _.flatMap(
      order.invoiceProcessSteps.filter(
        (a) => a.doneStock && a.processStatus == "DONE"
      ),
      "products"
    ),
    "productQuantity"
  );

  if (totalProductStock < totalProductNeed) {
    order.stockStatus = "PROCESSING";
  }

  if (totalProductStock == 0) {
    order.stockStatus = "UNFINISHED";
  }

  if (totalProductStock == totalProductNeed) {
    order.stockStatus = "FINISHED";
  }

  return order;
};

export const countPriceProcess = (process, order) => {
  process.total = 0;
  process.costTotal = 0;
  process.totalQuantity = 0;
  process.beforeSaleOffTotal = 0;
  process.discountCustomer = 0;
  process.surchargeProductTotal = 0;
  process.saleOffTotal = 0;
  process.taxMoney = 0;

  (process.products || [])
    .filter((a) => !a.isDelete)
    .map((item) => {
      item.quantity = item.quantity || item.productQuantity;

      item.productPrice = item.productPrice || 0;
      item.costPrice = item.costPrice || 0;

      if (item.isGift) {
        item.productPrice = 0;
      }
      item.surchargeValue = item.surchargeValue || 0;
      item.saleOffValue = item.saleOffValue || 0;
      item.discountCustomerPercent = item.discountCustomerPercent || 0;
      item.saleOffMoney =
        item.saleOffType == "PERCENT"
          ? (item.saleOffValue / 100) * item.productPrice
          : item.saleOffValue;
      item.surchargeMoney =
        item.surchargeType == "PERCENT"
          ? (item.surchargeValue / 100) * item.productPrice
          : item.surchargeValue;

      item.discountCustomerMoney =
        item.discountCustomerPercent * 0.01 * item.productPrice;
      item.priceAfter =
        item.productPrice -
        item.saleOffMoney -
        item.discountCustomerMoney +
        item.surchargeMoney;

      item.cashSaleOff = Math.round(item.quantity * item.saleOffMoney);
      item.discountCustomer = Math.round(
        item.quantity * item.discountCustomerMoney
      );
      item.surcharge = Math.round(item.quantity * item.surchargeMoney);
      item.costTotal = Math.round(item.quantity * item.costPrice);
      item.total = Math.round(item.productPrice * item.quantity);

      item.totalPayment =
        item.total - item.cashSaleOff - item.discountCustomer + item.surcharge;
      console.log(item);

      process.surchargeProductTotal += item.surcharge;
      process.discountCustomer += item.discountCustomer;
      process.total += item.totalPayment;
      process.costTotal += item.costTotal;
      process.totalQuantity += item.quantity;
      process.beforeSaleOffTotal += item.total;
      process.saleOffTotal += item.cashSaleOff;
    });

  if (order.total == 0) {
    process.percentDone = process.totalQuantity / order.totalQuantity;
  } else {
    process.percentDone = process.total / order.total;
  }
  process.percentDone = Math.abs(process.percentDone);

  process.cashDiscount = Math.round(order.cashDiscount * process.percentDone);
  if (process.isProcessReturn) {
    process.cashDiscount = process.cashDiscount * -1;
  }

  process.voucherDiscount = Math.round(
    order.voucherDiscount * process.percentDone
  );

  process.couponDiscount = Math.round(
    order.couponDiscount * process.percentDone
  );

  process.moneyPointConvert = Math.round(
    order.moneyPointConvert * process.percentDone
  );

  process.membershipDiscountMoney = Math.round(
    order.membershipDiscountMoney * process.percentDone
  );

  process.surchargeProcess = (process.surcharges || [])
    .filter((a) => !a.isDelete)
    .reduce((a, b) => a + +b.amount, 0);

  process.surchargeOrder = Math.round(order.surcharge * process.percentDone);
  process.surcharge = process.surchargeProcess + process.surchargeOrder;

  process.totalDiscount =
    process.cashDiscount +
    process.voucherDiscount +
    process.couponDiscount +
    process.membershipDiscountMoney +
    process.moneyPointConvert;
  process.products = (process.products || []).map((item) => {
    if (process.beforeSaleOffTotal) {
      item.percent = item.total / process.beforeSaleOffTotal;
    } else {
      item.percent = item.quantity / process.totalQuantity;
    }

    console.log(
      item.percent,
      item.total,
      item.quantity,
      process.beforeSaleOffTotal,
      process.totalQuantity
    );
    item.orderSaleOff = Math.round(item.percent * process.totalDiscount);
    item.orderSaleOffItem = item.orderSaleOff / item.quantity;

    item.orderSurcharge = Math.round(item.percent * process.surcharge);
    item.orderSurchargeItem = item.orderSurcharge / item.quantity;

    item.totalBeforeTax =
      item.totalPayment - item.orderSaleOff + item.orderSurcharge;

    item.taxMoney = Math.round(item.totalBeforeTax * item.percentTax * 0.01);

    process.taxMoney += item.taxMoney;

    item.totalAfterTax = item.totalBeforeTax + item.taxMoney;

    item.priceItem = item.totalBeforeTax / item.quantity;
    return item;
  });

  process.grandTotalBeforeTax = Math.round(
    process.total - process.totalDiscount + process.surcharge
  );

  process.grandTotal = Math.round(
    process.taxMoney + process.grandTotalBeforeTax
  );

  console.log("process >>>>>", process);

  return process;
};

export const refundPayment = async (selected, _this, cb) => {
  var res = await _this.$api.getInvoicesSummary(
    { branchId: _this.$branchId, id_in: selected },
    selected.length,
    0,
    "id_desc"
  );
  var orders = res.list.map((a) => {
    a.cardTotal - a.cashTotal > 0;
    return a;
  });
  if (!orders.length) {
    _this.$noti.open("Những hoá đơn bạn chọn đã hoàn đủ tiền", true);
    return;
  }
  var total = orders.reduce((a, b) => a + b.cardTotal + b.cashTotal, 0);

  var accountantCash = _this.$financeAuto.find(
    (i) => i.keyPayment == "REFUND_CASH"
  );

  const inputs = [
    {
      title: `Số tiền hoàn trả (max: ${_this.$formatMoney(total)})`,
      key: "total",
      required: true,
      value: total,
      width: "40%",
      type: "price",
      disabled: orders.length != 1,
      max: total,
    },
    {
      title: "Hoàn tiền qua",
      key: "paymentType",
      required: true,
      width: "60%",
      type: "select",
      value: "CASH",
      selectList: orders[0].customers
        ? _this.$paymentTypes
        : _this.$paymentTypes.filter((a) => a.value != "DEBIT"),
    },
    {
      title: "Người nộp",
      key: "transferRepresent",
      required: true,
      width: "50%",
      value: _this.$user.fullName,
    },

    {
      title: "Người nhận",
      key: "receiverRepresent",
      required: true,
      width: "50%",
      value:
        (orders.length > 0 &&
          orders[0].customers &&
          orders[0].customers.customerName) ||
        "Khách lẻ",
    },

    {
      title: "Thông tin - địa chỉ người nộp",
      key: "address",
      width: "100%",
      value: _this.$user.fullName,
    },
    {
      title: "Ghi chú",
      key: "des",
      width: "100%",
      type: "textarea",
      value: `Hoàn tiền cho đơn hàng ${orders.map((i) => i.code).join(", ")}`,
    },
    {
      title: "Tài khoản kế toán",
      type: "title",
    },
    {
      title: "Yếu tố chi",
      key: "elementCode",
      width: "50%",
      type: _this.$financeCodes.filter((a) => a.accountantType == "EXPENSE")
        .length
        ? "select"
        : "text",
      selectList: _this.$financeCodes.filter(
        (a) => a.accountantType == "EXPENSE"
      ),
    },

    {
      title: "Quyển số",
      key: "bookNo",
      width: "25%",
    },

    {
      title: "Số phiếu",
      key: "codeNumber",
      width: "25%",
    },
    {
      title: "Mã ĐTPN nộp (có)",
      key: "transferCode",
      width: "50%",
      disabled: !!_this.$user.employeeCode,
      value: _this.$user.employeeCode,
    },

    {
      title: "Mã ĐTPN nhận (nợ)",
      key: "receiverCode",
      width: "50%",
      disabled: true,

      value:
        (orders.length > 0 &&
          orders[0].customers &&
          orders[0].customers.customerCode) ||
        "KHACH_LE",
    },

    {
      title: "ĐTPN nộp (có)",
      key: "transfer",
      width: "50%",
      value: _this.$user.fullName,
    },
    {
      title: "ĐTPN nhận (nợ)",
      key: "receiver",
      width: "50%",

      value:
        (orders.length > 0 &&
          orders[0].customers &&
          orders[0].customers.customerName) ||
        "Khách lẻ",
    },

    {
      title: "Tải khoản có",
      key: "debtAccId",
      width: "50%",
      type: "select",
      selectList: _this.$accountList,
      value: accountantCash && accountantCash.credit.id,
    },
    {
      title: "Tải khoản nợ",
      key: "availableAccId",
      width: "50%",
      selectList: _this.$accountList,
      value: accountantCash && accountantCash.debt.id,
      type: "select",
    },
  ];

  const config = {
    title:
      orders.length == 1
        ? `Hoàn tiền cho đơn ${orders[0].code} - (${_this.$formatMoney(total)})`
        : `Hoàn tiền cho ${orders.length} đơn - (${_this.$formatMoney(total)})`,
  };
  _this.$root.formPopup.openPopup(inputs, config, (res) => {
    var incomeData = [];
    var remain = res.total;
    var invoices = orders.map((i) => {
      const item = {
        id: i.id,
        orderStatus: i.orderStatus,
        paymentStatus: i.paymentStatus,
        remainPayment: 0,
      };
      if (res.paymentType == "CASH" || res.paymentType == "DEBIT") {
        item.invoiceCashPayments = [
          {
            currencyUnit: Vue.prototype.$currency,
            totalCash: remain * -1,
            totalPayment: remain * -1,
            exchangePrice: 1,
            isDebit: res.paymentType == "DEBIT",
          },
        ];
        item.invoiceCardPayments = [];
        item.totalCash = i.cashTotal + remain * -1;
        item.totalCard = i.cardTotal + 0;
      } else {
        item.invoiceCashPayments = [];
        item.invoiceCardPayments = [
          {
            totalPayment: remain * -1,
            cardType: res.paymentType.split("&&")[0],
            cardNumber: res.paymentType.split("&&")[1],
          },
        ];

        item.totalCard = i.cardTotal + remain * -1;
        item.totalCash = i.cashTotal + 0;
      }
      item.paidMoney = item.totalCash + item.totalCard;
      item.remainDebt = i.totalDebt - item.paidMoney;
      item.remainPayment = i.grandTotal - item.paidMoney;

      remain = remain - i.remainPayment;
      if (remain < 0) {
        remain = 0;
      }

      if (item.remainPayment > 0) {
        item.paymentStatus = "PARTIAL_PAID";
      }
      if (item.remainPayment == 0) {
        item.paymentStatus = "PAID";
      }
      if (item.remainPayment == item.grandTotal) {
        item.paymentStatus = "UNPAID";
      }
      return item;
    });

    var paymentReqs = [];
    if (res.debtAccId && res.availableAccId && res.total > 0) {
      paymentReqs.push({
        debtAccId: res.debtAccId,
        availableAccId: res.availableAccId,
        totalPayment: res.total,

        transfer: res.transfer,
        receiver: res.receiver,
        receiverCode: res.receiverCode,
        transferCode: res.transferCode,
      });
    }

    if (paymentReqs && paymentReqs.length) {
      var elment = _this.$financeCodes.find((a) => a.id == res.elementCode);
      incomeData.push({
        bookNo: res.bookNo,
        codeNumber: res.codeNumber,
        elementCode: res.elementCode,
        elementName: elment ? elment.name : res.elementCode,
        totalPayment: res.total,
        transfer: res.transfer,
        transferRepresent: res.transferRepresent,
        receiver: res.receiver,
        receiverRepresent: res.receiverRepresent,
        receiverCode: res.receiverCode,
        transferCode: res.transferCode,

        accountantType: "EXPENSE",
        paymentReqs: paymentReqs,
        address: res.address,
        paymentDetailReqs: {
          currencyUnit: _this.$currency,
          originalCurrency: res.total,
          exchangePrice: 1,
          totalPayment: res.total,
          isCashPayment: res.paymentType == "CASH",
        },
        description: res.des,
      });
    }
    _this.$root.formPopup.sending = true;

    _this.$api
      .paymentInvoice(invoices, incomeData, _this.$branchId)
      .then(async () => {
        _this.$noti.open(_this.$t("common.payment_success"));
        _this.$root.formPopup.closePopup();

        // invoices.forEach((i) => {
        for (let item of invoices) {
          const oldOrder = orders.find((a) => a.id == item.id);
          var totalDebit = item.invoiceCashPayments
            .filter((a) => a.isDebit)
            .reduce((a, b) => a + b.totalPayment, 0);
          if (totalDebit != 0 && oldOrder) {
            const dataCredit = {
              customerId: oldOrder.customers ? oldOrder.customers.id : "",
              transfer: oldOrder.customers
                ? oldOrder.customers.customerName
                : "",
              receiver: _this.$user.fullName,
              creditDate: moment().format("YYYY-MM-DD"),
              money: totalDebit * -1,
              description: "Trả tiền đơn trả hàng " + oldOrder.code,
              branchId: _this.$branchId,
            };
            await _this.$api.newCredit(dataCredit, _this.$companyId, []);
          }
        }

        cb({ invoices, paymentType: res.paymentType });
      })
      .catch(() => {
        _this.$root.formPopup.sending = false;
      });
  });
};

export const getFinanceFields = (viewDetail) => [
  {
    key: "code",
    searchKey: ["code_ilike", "bookNo_ilike", "codeNumber_ilike"],
    className: "nobreak  pointer ",
    title: "Mã phiếu",
    showData: (r) =>
      `<a tabindex="1" class="bold">#${r.code}</a><p class="sub-text m-0">${
        r.isManual ? "" : "Tạo tự động"
      }</p> <p class="sub-text">Quyển số: ${r.bookNo || "-"} | ${
        r.codeNumber || "-"
      }</p>`,

    click: (r) => {
      viewDetail && viewDetail(r);
    },
    showDataRaw: (r) => r.code,
  },
  {
    key: "isManual",
    noShow: () => true,
    showDataRaw: (r) => (r.isManual ? "Tạo tự động" : ""),
    title: "Hình thức tạo",
  },
  {
    key: "elementCode",
    title: "Yếu tố thu/chi",
    width: "200px",
    searchKey: "elementCode_like",
    showData: (r) => {
      if (!r.elementCode) {
        return "-";
      }
      return `<span class="nobreak">${r.elementCode || "-"} | ${
        r.elementName || ""
      }</span>`;
    },

    searchType: "select",
    searchList: Vue.prototype.$financeCodes.map((a) => ({
      id: a.code,
      name: `${a.code} | ${a.name}`,
    })),
    showDataRaw: (r) => r.elementCode,
  },
  {
    key: "elementName",
    noShow: () => true,
    title: "Tên yếu tố",
  },
  {
    key: "bookNo",
    title: "Quyển số",
    noShow: () => true,
  },

  {
    key: "codeNumber",
    noShow: () => true,
    title: "Số phiếu",
  },
  {
    key: "bookNo",
    title: "Quyển số",
    noShow: () => true,
  },
  {
    key: "totalPayment",
    title: "Số tiền",
    className: "nobreak text-right",
    searchType: "number",
    showDataRaw: (r) => r.totalPayment,
    showData: (r) => {
      if (r.accountantType == "INCOME") {
        return (
          '<span class="text-success bold">' +
          ' <i class="fa fa-arrow-up " ></i>' +
          Vue.prototype.$formatMoney(r.totalPayment) +
          "</span>"
        );
      }
      if (r.accountantType == "EXPENSE") {
        return (
          '<span class="text-danger bold">' +
          ' <i class="fa fa-arrow-down " ></i> ' +
          Vue.prototype.$formatMoney(r.totalPayment) +
          "</span>"
        );
      }
    },
  },
  {
    key: "contracts.contractName",
    searchKey: ["contracts.contractName_ilike", "contracts.contractCode_ilike"],
    title: "Hợp đồng / Dự án",
    showData: (row) => {
      if (!row.contracts) return "-";
      return `<p>${row.contracts && row.contracts.contractName}<br /> <small>(${
        row.contracts && row.contracts.contractCode
      })</small></p>`;
    },
  },
  {
    key: "receiver",
    searchKey: ["receiver_ilike", "receiverCode_ilike"],
    title: "ĐTPN nhận (nợ)",
    width: "200px",
    showDataRaw: (r) => r.receiver,
    showData: (r) =>
      `<label>${r.receiverCode || ""}</label><p>${r.receiver || ""}</p>`,
  },
  {
    key: "receiverCode",
    noShow: () => true,
    title: "Mã ĐTPN nhận (nợ)",
  },

  {
    key: "transfer",
    searchKey: ["transfer_ilike", "transferCode_ilike"],
    title: "ĐTPN nộp (có)",
    showDataRaw: (r) => r.transfer,
    width: "200px",
    showData: (r) =>
      `<label>${r.transferCode || ""}</label><p>${r.transfer || ""}</p>`,
  },
  {
    key: "transferCode",
    noShow: () => true,
    title: "Mã ĐTPN nộp (có)",
  },

  {
    key: "receiverRepresent",
    title: "Người nhận",
    width: "200px",
    searchKey: "receiverRepresent_like",
  },

  {
    key: "transferRepresent",
    title: "Người nộp",
    width: "200px",
    searchKey: "transferRepresent_like",
  },

  {
    key: "startDate",
    title: "Ngày giao dịch",
    className: "nobreak",
    searchType: "date-range",
    showData: (r) =>
      r.startDate ? Vue.prototype.$formatDate(r.startDate) : "",
  },
  {
    key: "description",
    title: "Diễn giải",
    searchKey: "description_ilike",
    width: "400px",
    showDataRaw: (r) => r.description,
    showData: (r) =>
      r.description && r.description.length > 70
        ? r.description.substring(0, 50) + "[...]"
        : r.description,
  },
  {
    key: "incomeExpenseRefs",
    title: "Tham khảo",
    searchKey: [
      "incomeExpenseRefs.invoices.code_ilike",
      "incomeExpenseRefs.purchaseOrders.code_ilike",
      "incomeExpenseRefs.warehouseReceipts.code_ilike",
      "incomeExpenseRefs.warehouseOutReceipt.code_ilike",
    ],
    className: "bold",
    width: "200px",
    showData: (r) => {
      var out = [];
      if (r.incomeExpenseRefs && r.incomeExpenseRefs.length <= 2) {
        r.incomeExpenseRefs.map((i) => {
          if (i.invoices) {
            out.push(`<span class="nobreak">#${i.invoices.code}</span>`);
          }
          if (i.purchaseOrders) {
            out.push(`<span class="nobreak">#${i.purchaseOrders.code}</span>`);
          }
          if (i.warehouseReceipts) {
            out.push(
              `<span class="nobreak">#${i.warehouseReceipts.code}</span>`
            );
          }
          if (i.warehouseOutReceipt) {
            out.push(
              `<span class="nobreak">#${i.warehouseOutReceipt.code}</span>`
            );
          }
        });
        return out.join(", ");
      } else {
        [r.incomeExpenseRefs[0], r.incomeExpenseRefs[1]].map((i) => {
          if (i.invoices) {
            out.push(`<span class="nobreak">#${i.invoices.code}</span>`);
          }
          if (i.purchaseOrders) {
            out.push(`<span class="nobreak">#${i.purchaseOrders.code}</span>`);
          }
        });
        return (
          out.join(", ") +
          `<span class="tag nobreak">+ ${r.incomeExpenseRefs.length - 2}</span>`
        );
      }
    },
  },
  {
    key: "payment",
    title: "Hạch toán",
    searchKey: [
      "incomeExpensePayments.debtAcc.companyCircularCategory.code_ilike",
      "incomeExpensePayments.availableAcc.companyCircularCategory.code_ilike",
    ],
    noSort: true,
    className: "nobreak",
    showDataRaw: (r) => r.payments.map((i) => i.totalPayment).join("\n"),

    showData: (r) =>
      r.payments
        .map(
          (i) =>
            (r.payments.length > 1
              ? `<b style="width:100px">
              ${Vue.prototype.$formatMoney(i.totalPayment)}
              </b> | `
              : "") +
            `${_.get(i, "debtAcc.detail.code", "")}.${_.get(
              i,
              "debtAcc.detail.name",
              ""
            )} 
              <i class="fa fa-arrow-left"></i> 
              ${_.get(i, "availableAcc.detail.code", "")}.${_.get(
              i,
              "availableAcc.detail.name",
              ""
            )}`
        )
        .join("<br />"),
  },
  {
    key: "debtAcc",
    showDataRaw: (r) =>
      r.payments
        .map((i) => `${i.debtAcc.detail.code}.${i.debtAcc.detail.name}`)
        .join("\n"),
    noShow: () => true,
    title: "Tài khoản nợ",
  },
  {
    key: "availableAcc",
    showDataRaw: (r) =>
      r.payments
        .map(
          (i) => `${i.availableAcc.detail.code}.${i.availableAcc.detail.name}`
        )
        .join("\n"),
    noShow: () => true,
    title: "Tài khoản có",
  },

  {
    key: "incomeExpensePaymentDetails.totalPayment",
    title: "Thanh toán",
    searchType: "number",
    className: "nobreak text-right",
    showData: (r) =>
      r.paymentDetails
        .map((i) => {
          var out =
            (i.isCashPayment
              ? "Tiền mặt: "
              : `${i.cardType}|${i.cardNumber}: `) +
            `<strong>${Vue.prototype.$formatMoney(i.totalPayment)}</strong>`;
          return out;
        })
        .join("<br />"),

    showDataRaw: (r) =>
      r.paymentDetails
        .map((i) => {
          var out =
            (i.isCashPayment
              ? "Tiền mặt: "
              : `${i.cardType}|${i.cardNumber}: `) + i.totalPayment;
          return out;
        })
        .join("\n"),
  },
];
export const getOrderFields = (_this, viewDetail) => {
  const subStatuses = [];
  return [
    {
      title: "Mã đơn hàng",
      key: "code",
      searchKey: "code_ilike",
      className: "pointer nobreak",
      showDataRaw: (r) => r.code,
      showData: (row) => {
        var out = "";
        var type = row.isB2B
          ? "Bán sỉ"
          : row.orderType == "SALES"
          ? "Bán POS"
          : "Bán lẻ";
        if (row.orderType == "RETURN_PRODUCT") {
          out += `
            <p class="m-0 font-12">(Trả hàng)</p>
              <p class="bold text-danger">
            <i class="mdi mdi-cart-off" ></i>
            ${row.code}
            </p>
            `;
        } else {
          out += `
            <p class="m-0 font-12">
          ${type}
          </p>
          <p class="bold text-success"><i class="mdi mdi-cart" /></i>
            ${row.code}
          </p>
          `;
        }
        return (
          out + `<p class="sub-text w-200px text-trim">${row.title || ""}</p>`
        );
      },
      click: (row) => {
        viewDetail && viewDetail(row);
      },
      width: "100%",
    },
    {
      title: "Loại đơn",
      showData: (r) => {
        return r.isB2B
          ? "Bán sỉ"
          : r.orderType == "SALES"
          ? "Bán POS"
          : "Bán lẻ";
      },
      key: "orderType",
      searchType: "select",
      searchList: [
        { name: "Bán sỉ", id: "DEBT_B2B" },
        { name: "Bán POS", id: "SALES" },
        { name: "Bán lẻ", id: "DEBT" },
        { name: "Trả hàng", id: "RETURN_PRODUCT" },
      ],
    },
    {
      key: "contracts.contractName",
      searchKey: [
        "contracts.contractName_ilike",
        "contracts.contractCode_ilike",
      ],
      width: "200px",
      title: "Hợp đồng / Dự án",
      showData: (row) => {
        if (!row.contracts) return "-";
        return `<p>${
          row.contracts && row.contracts.contractName
        }<br /> <small>(${
          row.contracts && row.contracts.contractCode
        })</small></p>`;
      },
    },
    {
      key: "channels",
      title: "Kênh bán",
      className: () => "nobreak",
      showData: (row) => row.channels,
      searchType: "multi-select",
      searchList: _this.$channels.map((a) => ({ ...a, id: a.name })),
    },

    {
      title: "Người tạo",
      key: "invoiceDate",
      searchKey: "invoiceCreateByUserName_ilike",
      className: "nobreak",
      showData: (r) =>
        `<p>${r.invoiceCreateByUserName}</p>
        <p class="m-0 font-12">
          ${_this.$formatDate(r.createdAt)}
          |
          ${_this.$formatTime(r.createdAt)}
        </p>`,
      showDataRaw: (r) => r.invoiceCreateByUserName,
    },

    {
      title: "NV phụ trách",
      key: "personInCharge.fullName",
      searchKey: [
        "personInCharge.fullName_ilike",
        "personInCharge.employeeCode_ilike",
      ],
      className: "nobreak",
      showData: (r) => r.personInCharge && r.personInCharge.fullName,
    },

    {
      title: "NV hưởng doanh số",
      key: "employeeSales.employeeId",
      searchType: "multi-select",
      searchList: _this.$branch.employeeBranches.map((a) => ({
        id: a.employees.id,
        name: a.employees.fullName,
      })),
      showData: (r) =>
        (r.employeeSales || [])
          .map(
            (a) =>
              `<span class="nobreak">${a.employeeName} (${a.percentSale}%) </span>`
          )
          .join(", "),
      showDataRaw: (r) =>
        (r.employeeSales || []).map((a) => a.employeeName).join("\n"),
    },
    {
      title: "Tỉ lê NV hưởng doanh số",
      key: "employeeSales.percentSale",
      showData: (r) =>
        (r.employeeSales || []).map((a) => a.percentSale).join("\n"),
      noShow: () => true,
    },
    {
      title: "Ngày tạo",
      key: "createdAt",
      searchType: "date-range",
      showData: (r) =>
        `${_this.$formatDate(r.createdAt)} |  ${_this.$formatTime(
          r.createdAt
        )} `,
      showDataRaw: (r) =>
        r.createdAt ? `${_this.$formatDate(r.createdAt)}` : "",
    },
    {
      title: "Giờ tạo",
      key: "createdTimeAt",
      noShow: () => true,
      showData: (r) => `${_this.$formatTime(r.createdAt)}`,
      showDataRaw: (r) =>
        r.createdAt ? `${_this.$formatTime(r.createdAt)}` : "",
    },

    {
      title: "Ngày báo cáo",
      searchType: "date-range",
      key: "invoiceDate",
      showData: (r) =>
        `${_this.$formatDate(r.invoiceDate)} |  ${_this.$formatTime(
          r.invoiceDate
        )}`,
      showDataRaw: (r) =>
        r.invoiceDate ? `${_this.$formatDate(r.invoiceDate)}` : "",
    },
    {
      title: "Giờ báo cáo",
      noShow: () => true,
      key: "invoiceTimeDate",
      showData: (r) => `${_this.$formatTime(r.invoiceDate)}`,
      showDataRaw: (r) =>
        r.invoiceDate ? `${_this.$formatTime(r.invoiceDate)}` : "",
    },

    {
      key: "stockStatus",
      title: "Trạng thái hàng",
      showData: (row) => {
        if (row.stockStatus === "FINISHED") {
          return `<span class="status status-success">Đã giao xong</span>`;
        } else if (row.stockStatus === "PROCESSING") {
          return `<span class="status status-info">Đang xử lý</span>`;
        } else if (row.stockStatus === "UNFINISHED") {
          return `<span class="status status-warning">Chưa giao</span>`;
        }
      },
      className: () => "nobreak",
      searchType: "multi-select",
      minWidth: "100px",
      searchList: [
        { id: "FINISHED", name: "Đã hoàn thành" },
        { id: "PROCESSING", name: "Đang xử lý" },
        { id: "UNFINISHED", name: "Chưa hoàn thành" },
        { id: "DELETED", name: "Đã xoá" },
        { id: "CANCEL", name: "Đã huỷ" },
      ],
    },
    {
      key: "paymentStatus",
      title: _this.$t("sale.payment_status"),
      className: () => "nobreak",
      searchType: "multi-select",
      minWidth: "100px",
      searchList: [
        { id: "PENDING", name: "Chờ thanh toán" },
        { id: "PROCESSING", name: "Đang xử lý" },
        { id: "FAIL", name: "Thanh toán thất bại" },
        { id: "PAID", name: "Đã thanh toán" },
        { id: "UNPAID", name: "Chưa thanh toán" },
        { id: "PARTIAL_PAID", name: "Thanh toán 1 phần" },
      ],

      showData: (row) => {
        if (row.paymentStatus === "PAID") {
          return `<span class="status status-success">${_this.$t(
            "common." + row.paymentStatus
          )}</span>`;
        }
        if (row.paymentStatus === "UNPAID") {
          return `<span class="status status-danger">${_this.$t(
            "common." + row.paymentStatus
          )}</span>`;
        }

        if (row.paymentStatus === "PARTIAL_PAID") {
          return `<span class="status status-warning">${this.$t(
            "common." + row.paymentStatus
          )}</span>`;
        }
        if (row.paymentStatus === "FAIL") {
          return `<span class="status status-danger">Thanh toán thất bại </span>`;
        }
        if (row.paymentStatus === "PENDING") {
          return `<span class="status status-warning">Chờ xác nhận</span>`;
        }
      },
    },

    {
      title: "Trạng thái đơn",
      key: "orderStatus",
      searchKey: "orderStatus_ilike",
      noSort: true,

      searchType: "multi-select",
      minWidth: "100px",
      searchList: [
        { id: "FINISHED", name: "Đã hoàn thành" },
        { id: "PROCESSING", name: "Đang xử lý" },
        { id: "UNFINISHED", name: "Chưa hoàn thành" },
      ],
      showDataRaw: (r) => {
        if (r.orderStatus) {
          var out = "";
          var o = subStatuses.find((a) => a.code == r.subStatus);
          var sub = o ? o.name : r.subStatus;
          sub = sub ? "(" + sub + ")" : "";
          if (r.isB2B) {
            sub = "";
          }
          if (r.orderStatus == "PROCESSING") {
            out += `Đang xử lý ${sub}`;
          } else {
            out += `${_this.$t("bill_detail.status_" + r.orderStatus)}`;
          }
          return out;
        }
        return "";
      },
      showData: (r) => {
        var out = "";
        var o = subStatuses.find((a) => a.code == r.subStatus);
        var sub = o ? o.name : r.subStatus;
        sub = sub ? "(" + sub + ")" : "";
        if (r.isB2B) {
          sub = "";
        }

        if (r.orderStatus == "FINISHED") {
          out += `<p class="status status-success">
          ${_this.$t("bill_detail.status_" + r.orderStatus)}
          <p>`;
        }
        if (r.orderStatus == "UNFINISHED") {
          out += `<p class="status status-warning">
          ${_this.$t("bill_detail.status_" + r.orderStatus)}
          <p>`;
        }
        if (r.orderStatus == "PROCESSING") {
          out += `<p class="status status-info">Đang xử lý ${sub}</p>`;
        }

        if (r.orderStatus == "DELETED") {
          out += `<p class="status status-danger">
          ${_this.$t("bill_detail.status_" + r.orderStatus)}
          <p>`;
        }

        if (r.orderStatus == "CANCEL") {
          out += `<p class="status status-danger">
          ${_this.$t("bill_detail.status_" + r.orderStatus)}
          <p>`;
        }

        if (r.orderStatus == "FINISHED")
          return (
            out +
            `<p class="m-0 font-12">
          ${_this.$formatDate(r.invoiceDate)}
          |
          ${_this.$formatTime(r.invoiceDate)}
        </p>`
          );
        return out;
      },
    },
    {
      title: "Khách hàng",
      searchKey: [
        "customers.phoneNumber_ilike",
        "customers.customerName_ilike",
        "customers.customerCode_ilike",
      ],
      key: "customers.customerName",
      minWidth: "200px",
      showData: (r) =>
        `
        <label>${r.customers ? r.customers.customerCode : ""}</label>
        <p class="text-trim-2">${
          r.customers ? r.customers.customerName : ""
        }</p><p class="sub-text">${
          r.customers ? r.customers.phoneNumber : ""
        }</p>`,
      showDataRaw: (r) => (r.customers ? r.customers.customerName : ""),
    },

    {
      title: "SĐT Khách",
      key: "customers.phoneNumber",
      showDataRaw: (r) => (r.customers ? r.customers.phoneNumber : ""),
      noShow: () => true,
    },
    {
      title: "Mã Khách",
      key: "customers.customerCode",
      showDataRaw: (r) => (r.customers ? r.customers.customerCode : ""),
      noShow: () => true,
    },
    _this.$permission("ADMIN:VIEW_COST_ORDER")
      ? {
          title: "Tổng giá vốn",
          key: "costTotal",
          className: "text-right",
          showData: (r) => _this.$formatMoney(r.costTotal, true),
          searchType: "number",
          showDataRaw: (r) => r.costTotal,
        }
      : null,
    {
      title: "Tổng tiền",
      key: "total",
      className: "text-right",
      showData: (r) => _this.$formatMoney(r.beforeSaleOffTotal, true),
      searchType: "number",
      showDataRaw: (r) => r.beforeSaleOffTotal,
    },
    {
      title: "CK / KM",
      key: "totalDiscount",
      className: "text-right",
      noSort: true,
      showData: (r) => _this.$formatMoney(r.totalDiscount, true),
      searchType: "number",
      showDataRaw: (r) => r.totalDiscount,
    },
    {
      title: "Phụ phí",
      key: "totalSurcharge",
      className: "text-right",
      showData: (r) => _this.$formatMoney(r.totalSurcharge, true),
      searchType: "number",
      showDataRaw: (r) => r.totalSurcharge,
    },
    {
      title: "Thuế",
      key: "taxMoney",
      className: "text-right",
      showData: (r) => _this.$formatMoney(r.taxMoney, true),
      searchType: "number",
      showDataRaw: (r) => r.taxMoney,
    },
    {
      title: "Tổng cộng",
      key: "grandTotal",
      className: "text-right",
      showData: (r) => _this.$formatMoney(r.grandTotal, true),
      searchType: "number",
      showDataRaw: (r) => r.grandTotal,
    },

    {
      title: "Đã thanh toán",
      key: "paidMoney",
      className: "text-right",
      noSort: true,
      showData: (r) => _this.$formatMoney(r.paidMoney, true),
      searchType: "number",
      showDataRaw: (r) => r.paidMoney,
    },

    {
      title: "Chưa thanh toán",
      key: "remainPayment",
      className: "text-right",
      showData: (r) => _this.$formatMoney(r.remainPayment, true),
      searchType: "number",
      showDataRaw: (r) => r.remainPayment,
    },
    {
      title: "Tiền Công nợ",
      key: "remainDebt",
      className: "text-right",
      showData: (r) => _this.$formatMoney(r.remainDebt, true),
      searchType: "number",
      showDataRaw: (r) => r.remainDebt,
    },

    {
      key: "description",
      width: "300px",
      searchKey: "description_ilike",
      title: "Ghi chú",
      showData: (row) => row.description,
    },
    {
      key: "invoiceProcessSteps",
      width: "300px",
      searchKey: [
        "invoiceProcessSteps.stepName_ilike",
        "invoiceProcessSteps.code_ilike",
        "invoiceProcessSteps.processDate_ilike",
      ],
      title: "Tiến trình",
      showData: (row) =>
        (row.invoiceProcessSteps || [])
          .map((a) => `${a.code} (${_this.$formatDate(a.processDate)})`)
          .join("<br />"),
    },

    {
      key: "invoiceCashPayments.paymentTime",
      width: "300px",
      searchType: "date-range",
      searchKey: [
        "invoiceCashPayments.paymentTime",
        "invoiceCardPayments.paymentTime",
      ],
      title: "Thanh toán",
      showData: (row) => {
        return (
          (row.invoiceCashPayments || [])
            .map(
              (a) =>
                `Tiền mặt: ${_this.$formatDate(
                  a.paymentTime
                )}:  ---- ${_this.$formatMoney(a.totalPayment)} `
            )
            .join("<br />") +
          (row.invoiceCardPayments || [])
            .map(
              (a) =>
                `CK: ${_this.$formatDate(
                  a.paymentTime
                )}: --------- ${_this.$formatMoney(a.totalPayment)} `
            )
            .join("<br />")
        );
      },
    },
    // {
    //   key: "invoiceInitials.products",
    //   searchKey: [
    //     "invoiceInitials.products.name_ilike",
    //     "invoiceInitials.products.code_ilike",
    //   ],
    //   width: "300px",
    //   title: "Sẩn phẩm đặt hàng",
    //   showData: (r) =>
    //     r.invoiceInitials
    //       .slice(0, 3)
    //       .map(
    //         (a) =>
    //           `<p class="no-break m-0"><strong>${a.productQuantity} ${a.products.unit.name} ${a.products.code} </strong> | ${a.products.name}</p>`
    //       )
    //       .join("") +
    //     (r.invoiceInitials.length > 3
    //       ? `<p>vả ${r.invoiceInitials.length - 3} sp khác</p>`
    //       : ""),
    // },]
  ].filter((a) => a);
};

export const getFieldPO = (_this) => {
  return [
    {
      key: "code",
      searchKey: "code_ilike",
      title: _this.$t("po.code"),
      width: "150px",
      showData: (row) => "#" + row.code,
      className: () => "nobreak bold pointer",
      link: (r) => "/stock/purchase/" + r.id,
    },

    {
      key: "suppliers.supplierName",
      searchKey: "suppliers.supplierName_ilike",
      title: "Nhà cung cấp",
      showData: (row) => row.suppliers && row.suppliers.supplierName,
      className: () => "nobreak",
    },
    {
      key: "contracts.contractName",
      searchKey: [
        "contracts.contractName_ilike",
        "contracts.contractCode_ilike",
      ],
      title: "Hợp đồng / Dự án",
      showData: (row) => {
        if (!row.contracts) return "-";
        return `<p>${
          row.contracts && row.contracts.contractName
        }<br /> <small>(${
          row.contracts && row.contracts.contractCode
        })</small></p>`;
      },
    },

    {
      key: "status",
      title: "Trạng thái",
      showData: (row) => {
        let status = "";
        let statusName = "";
        if (row.status == "DONE") {
          status = "success";
          statusName = "Hoàn thành";
        }
        if (row.status == "INPROGRESS") {
          status = "info";
          statusName = "Đang hoạt động";
        }
        if (row.status == "CLOSE") {
          status = "basic";
          statusName = "Đã đóng";
        }
        return `<span class="status status-${status}">${statusName}</span>`;
      },
      searchType: "multi-select",
      searchList: [
        { id: "DONE", name: "Hoàn thành" },
        { id: "INPROGRESS", name: "Đang hoạt động" },
        { id: "CLOSE", name: "Đã đóng" },
      ],
    },

    {
      key: "paymentStatus",
      title: _this.$t("po.payment_status"),
      showData: (row) => {
        if (row.status == "CLOSE") {
          return "";
        }
        let status = "";
        if (row.paymentStatus == "UNPAID") {
          status = "basic";
        }
        if (row.paymentStatus == "PARTIAL_PAID") {
          status = "warning";
        }
        if (row.paymentStatus == "PAID") {
          status = "success";
        }
        return `<span class="status status-${status}">${_this.$t(
          "po.payment_status_" + row.paymentStatus
        )}</span>`;
      },

      searchType: "multi-select",
      searchList: [
        { id: "UNPAID", name: "Chưa thanh toán" },
        { id: "PARTIAL_PAID", name: "Thanh toán 1 phần" },
        { id: "PAID", name: "Đã thanh toán" },
      ],
    },
    {
      key: "inventoryStatus",
      title: _this.$t("po.inventory_status"),
      showData: (row) => {
        if (row.status == "CLOSE") {
          return "";
        }
        let status = "";
        if (row.inventoryStatus == "INIT") {
          status = "info";
        }
        if (row.inventoryStatus == "PROCESSING") {
          status = "warning";
        }
        if (row.inventoryStatus == "FINISHED") {
          status = "success";
        }
        return `<span class="status status-${status}">${_this.$t(
          "po.inventory_status_" + row.inventoryStatus
        )}</span>`;
      },
      searchType: "multi-select",
      searchList: [
        { id: "INIT", name: "Mới tạo" },
        { id: "PROCESSING", name: "Đang nhập" },
        { id: "FINISHED", name: "Hoàn thành" },
      ],
    },
    {
      key: "quantity",
      title: "Đã nhập / SD",
      className: () => "nobreak text-right",
      showData: (row) => _this.$formatQuantity(row.quantity),
      searchType: "number",
      showDataRaw: (r) => r.quantity,
    },
    {
      key: "totalQuantity",
      title: _this.$t("po.total_quantity"),
      className: () => "nobreak text-right",
      showData: (row) => _this.$formatQuantity(row.totalQuantity),
      showDataRaw: (r) => r.totalQuantity,
      searchType: "number",
    },

    {
      key: "totalBeforeTax",
      title: "Tiền hàng",
      showData: (row) => _this.$formatMoney(row.totalBeforeTax),
      className: () => "nobreak text-right",
      searchType: "number",
      showDataRaw: (r) => r.totalBeforeTax,
    },
    {
      key: "taxMoney",
      title: "thuế",
      showData: (row) => _this.$formatMoney(row.taxMoney),
      className: () => "nobreak text-right",
      searchType: "number",
      showDataRaw: (r) => r.taxMoney,
    },
    {
      key: "grandTotal",
      title: _this.$t("po.grand_total"),
      showData: (row) => _this.$formatMoney(row.grandTotal),
      className: () => "nobreak text-right",
      searchType: "number",
      showDataRaw: (r) => r.grandTotal,
    },

    {
      key: "payment",
      title: _this.$t("po.payment"),
      showData: (row) => _this.$formatMoney(row.payment),
      searchType: "number",
      className: () => "nobreak text-right",
      showDataRaw: (r) => r.payment,
    },

    {
      key: "purchaseCreateDate",
      title: _this.$t("po.created_date"),
      showData: (row) =>
        `${_this.$formatDate(row.purchaseCreateDate)} | ${_this.$formatTime(
          row.purchaseCreateDate
        )}`,
      className: () => "nobreak text-right",
    },
    {
      key: "description",
      searchKey: "description_ilike",
      title: _this.$t("po.description"),
      width: "250px",
      className: "oneline",
      showData: (row) => row.description,
    },
  ];
};

export const getFieldWorks = () => {
  return [
    {
      title: "Mã sản xuất",
      width: "120px",
      minWidth: "120px",
      key: "code",
      link: (r) => "/work/" + r.code,
      className: "no-break",
      searchKey: "code_like",
    },
    {
      title: "Tên đợt sản xuất - chế biến",
      width: "100%",
      minWidth: "200px",
      key: "name",
      searchKey: "name_like",
    },
    {
      title: "Trạng thái",
      className: "no-break",
      key: "status",
      showData: (r) => {
        if (r.status == "INIT")
          return `<span class="status status-basic">Mới tạo</span>`;
        if (r.status == "PROCESSING")
          return `<span class="status status-info">Đang xử lý</span>`;
        if (r.status == "DONE")
          return `<span class="status status-success">Đã hoàn thành</span>`;
        if (r.status == "REJECTED")
          return `<span class="status status-danger">Từ chối</span>`;
        if (r.status == "CANCEL")
          return `<span class="status status-danger">Đã hủy</span>`;
      },
      searchType: "multi-select",
      searchList: [
        { id: "INIT", name: "Mới tạo" },
        { id: "PROCESSING", name: "Đang xử lý" },
        { id: "DONE", name: "Đã hoàn thành" },
        { id: "REJECTED", name: "Từ chối" },
        { id: "CANCEL", name: "Đã hủy" },
      ],
      minWidth: "150px",
    },
    {
      key: "contracts.contractName",
      searchKey: "contracts.contractName_ilike",
      title: "Hợp đồng / Dự án",
      showData: (row) => {
        if (!row.contracts) return "-";
        return `<p>${
          row.contracts && row.contracts.contractName
        }<br /> <small>(${
          row.contracts && row.contracts.contractCode
        })</small></p>`;
      },
    },
    {
      key: "reporter",
      width: "170px",
      title: "Người quản lý",
      showData: (e) => (e.reporter ? e.reporter.fullName : "-"),
      searchKey: "reporter.fullName_ilike",
    },
    {
      key: "fromTime",
      width: "170px",
      title: "Ngày bắt đầu",
      showData: (r) =>
        `Từ ${r.fromTime ? moment(r.fromTime).format("DD/MM/YYYY") : "-"} <br/>
       đến ${r.toTime ? moment(r.toTime).format("DD/MM/YYYY") : "-"}`,

      // searchType: 'date'
    },
    {
      key: "invoices.code",
      searchKey: "invoices.code_ilike",
      title: "Đơn hàng",
      showData: (r) => (r.invoices ? r.invoices.code : "-"),
      link: (r) => r.invoices && `/order/${r.invoices.code}`,
    },
    {
      key: "description",
      searchKey: "description_ilike",
      title: "Ghi chú",
      width: "300px",
    },
  ];
};
