import Vue from "vue";
import printJS from "print-js";
import moneyToString from "./readMoney";
import { countPriceB2B } from "../pages/order/service";
import _ from "lodash";
import moment from "moment";

const XLSX = window.XLSX;

export const printStyle = `
@import url('https://fonts.googleapis.com/css?family=Montserrat:500&display=swap&subset=vietnamese');
@page {
    margin: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6.
td,
th,
p,
span,
strong {
    font-family: 'Montserrat'
}

.p-2{
  padding: 12px;
}
.pr-2{
  padding-right: 12px;
}
.pl-2{
  padding-left: 12px;
}
.pb-2{
  padding-bottom: 12px;
}
.pt-2{
  padding-top: 12px;
}


.p-4{
  padding: 24px;
}
.pr-4{
  padding-right: 24px;
}
.pl-4{
  padding-left: 24px;
}
.pb-4{
  padding-bottom: 24px;
}
.pt-4{
  padding-top: 24px;
}

 
.bold{
  font-weight: bold;
}
table {
    width: 100%;
    border-collapse: collapse;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.bold {
    font-weight: bold
}

.nobreak{
  white-space: nowrap;
}
h1,h2,h3,h4,h5,h6{
  margin: 10px 0;
}

p {
    margin: 0
}

.no-border{
  border: 0 !important;
}
.bill-table th{
  background: #ccc
}
.bill-table th,
.bill-table td{
  border: 1px solid #555;
  padding: 2px 4px;
}

table{
  margin: 0;
}
.bill-table{
  margin-top: 20px;
  margin-bottom: 20px;

}
figure.table{
  width: 100%;
  margin: 0!important;
}
`;

export const printStyleSmall = `
${printStyle}

td,
th,
p,
a{
    font-size: 12px;
}
.text-small{
  font-size: 10px;
}
`;

export const printStyleLarge = ` 

${printStyle}

td,
th,
p,
a,
span,
strong {
    font-size: 15px;
}
.text-small{
  font=size: 12px
}
.main td,
.main th{
    border: 1px solid #555;
    padding: 3px;
}
.bill-table th,
.bill-table td{
border: 1px solid #555;
}

`;

export function printTableExcel(
  list,
  headers,
  filename = "export",
  ws_name = "export",
  sheets = [],
  titlePages = []
) {
  filename = filename + ".xlsx";

  ws_name = ws_name || filename;

  const merge = [];
  const createXLSLFormatObj = [];
  titlePages.map((a, idx) => {
    createXLSLFormatObj.push([a]);
    merge.push({ s: { r: idx, c: 0 }, e: { r: idx, c: headers.length } });
  });

  var wscols = [];
  const headerTable = [];
  headerTable.push("#");
  headers.map((col, idx) => {
    headerTable.push(col.titleRaw || col.title || col.key);
    wscols[idx + 1] = (col.titleRaw || col.title || col.key).length;
  });

  createXLSLFormatObj.push(headerTable);

  let i = 1;
  list.map((item) => {
    const newItem = [];
    newItem.push(!item._noIndex ? i++ : "");
    headers.map((col, idx) => {
      var d = col.showDataRaw
        ? col.showDataRaw(item)
        : col.showData
        ? ((col.showData(item) || "") + "").replace(/<[^>]*>?/gm, "")
        : item[col.key];
      newItem.push(d);

      wscols[idx + 1] = Math.max(wscols[idx + 1], (d + "").length);
    });
    createXLSLFormatObj.push(newItem);
  });
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
  ws["!merges"] = merge;
  XLSX.utils.book_append_sheet(wb, ws, (ws_name || "").substring(0, 30));
  sheets.forEach((s) => {
    var sData = XLSX.utils.aoa_to_sheet(s.data);
    var cols = [];
    s.data.forEach((r) => {
      r.forEach((d, idx) => {
        cols[idx] = Math.max(cols[idx] || 0, d ? (d + "").length : 0);
      });
    });
    sData["!cols"] = cols.map((a) => ({ wch: a + 4 }));
    XLSX.utils.book_append_sheet(wb, sData, s.name.substring(0, 30));
  });

  ws["!cols"] = wscols.map((a) => ({ wch: a + 4 }));

  XLSX.writeFile(wb, filename);
}

export function printTable(
  list,
  headers,
  title = "",
  subTitle = "",
  footer = "",
  info = ""
) {
  const branch = JSON.parse(localStorage.getItem("branch"));
  let topHeader = `
    <table>
      <tr>
        <td style="min-width: 150px; width: 150px">
          <img width="80%" src="${branch.companies.logoUrl}" />
        </td>
        <td style="width: 40%">
          <p><b>${branch.branchName} (${branch.branchCode})</b></p>
          <p>Địa chỉ: ${branch.address || ""}</p>
          <p>Số điện thoại: ${branch.phoneNumber || ""}</p>
        </td>
        <td style="width: 60%" >
          <h3 class="text-center">${title}</h3>
          <p class="text-center">${subTitle}</p>
        </td>
      </tr>
    </table>
    <hr />
    `;

  let header = `<tr class="row-data large main" style="background: #ccc"><th class="text-right" style="width: 30px; min-width: 30px">#</th>`;

  headers.map((col) => {
    header += `<th 
                  class="${
                    typeof col.className == "function"
                      ? col.className({})
                      : col.className
                  } nobreak"
                >
                ${col.titleRaw || col.title || col.key}
              </th>`;
  });

  header += `</tr>`;

  let rows = [];

  list.map((item, index) => {
    let row = '<tr class="row-data ">';

    if (item._type == "line") {
      // row += `<td style="padding: 0" colspan="${headers.length + 1}"><hr /></td>`;
    } else {
      row += `<td class="text-right ${item._noIndex ? "no-border" : ""}" >${
        item.index || (!item._noIndex ? index + 1 : "")
      }</td>`;
      headers
        .filter((c) => !c.isHide || !c.isHide(item))
        .map((col) => {
          row += `<td 
          style="width: ${col.width};" ${
            item._className ? 'class="' + item._className + '"' : ""
          } 
          class="${
            typeof col.className == "function"
              ? col.className(item)
              : col.className
          }" 
          
          rowspan="${
            (typeof col.rowspan == "function"
              ? col.rowspan(item)
              : col.rowspan) || 1
          }" 
          colspan="${
            (typeof col.colspan == "function"
              ? col.colspan(item)
              : col.colspan) || 1
          }"  
          >
            ${col.showData ? col.showData(item) : item[col.key]}
          </td>`;
        });
    }
    row += "</tr>";

    rows.push(row);
  });

  const html = `
    <div style="padding: 15px">
    <table >
      <thead>
       <tr>
        <td colspan="${headers.length + 1}">${topHeader}</td>
      </tr >
      <tr>
      <td colspan="${headers.length + 1}">${info}</td>
    </tr >
       ${header}
      </thead>
      <tbody class="large main">
        ${rows.join("")}
      </tbody>
       <tfoot>
        <tr><td style="height: 20px"></td></tr>
      </tfoot>
    </table>
    ${footer}
    </div>
    `;
  printJS({
    printable: [],
    properties: [],
    header: html,
    style: printStyleLarge,
    type: "json",
  });
  return html;
}

export const formatBillPrint = (orderRaw) => {
  var order = JSON.parse(JSON.stringify(orderRaw));
  if (order.numberPointConvert) {
    order.pointToMoney = order.moneyPointConvert / order.numberPointConvert;
  }
  order.invoiceInitials = order.invoiceInitials;
  if (order.orderType === "RETURN_PRODUCT") {
    order.invoiceInitials.forEach((item) => {
      item.productPrice = Math.abs(item.productPrice);
      item.saleOffValue = Math.abs(item.saleOffValue);
    });
  }
  order = countPriceB2B(order);

  const formatDate = Vue.prototype.$formatDate;
  const formatFullDate = Vue.prototype.$formatFullDate;
  const formatMoney = Vue.prototype.$formatMoney;
  const formatTime = Vue.prototype.$formatTime;

  let branch = localStorage.getItem("branch");
  branch = branch ? JSON.parse(branch) : {};
  
  let company = localStorage.getItem("company");
  company = company ? JSON.parse(company) : {};

  let items = order.invoiceInitials
    .filter((i) => !i.bundleId)
    .map((item) => {
      var i = { ...item };
      i.totalProduct = i.isGift ? 0 : i.productQuantity;
      i.totalGift = i.isGift ? i.productQuantity : 0;
      i.totalQuantity = i.totalProduct + i.totalGift;
      i.productName = i.productName || i.products.name;
      i.productCode = i.productCode || i.products.code;
      i.unitName = i.unitName || i.products.unit.name;

      return i;
    })
    .mergeTotal(
      "productCode",
      "totalDiscount,discountCustomerMoney,taxMoney,percentTax,total,productPrice,totalProduct,totalGift,totalQuantity,totalPayment,priceAfter,saleOffMoney,surcharge,surchargeValue,discountCustomer,cashSaleOff"
    )
    .map((i) => ({
      ...i,
      total: formatMoney(i.total),
      totalPayment: formatMoney(i.totalPayment),
      productPrice: formatMoney(i.productPrice),
      priceAfter: formatMoney(i.priceAfter),
      saleOffMoney: formatMoney(i.saleOffMoney),
      surcharge: formatMoney(i.surcharge),
      surchargeValue: formatMoney(i.surchargeValue),
      surchargeMoney: formatMoney(i.surchargeMoney),
      discountCustomer: formatMoney(i.discountCustomer),
      cashSaleOff: formatMoney(i.cashSaleOff),
      taxMoney: formatMoney(i.taxMoney),
      percentTax: formatMoney(i.percentTax),
      discountCustomerMoney: formatMoney(i.discountCustomerMoney),
      totalDiscount: formatMoney(i.totalDiscount),
    }));

  items = _.orderBy(items, "isGift", "asc");
  items = items.map((a, index) => ({
    index: index + 1,
    ...a,
  }));

  const paymentDetail = `
      <div>
        ${order.invoiceCashPayments
          .map(
            (a) =>
              `<p>Tiền mặt (${formatFullDate(a.paymentTime)}): ${formatMoney(
                a.totalPayment
              )}</p>`
          )
          .join("")}
        ${order.invoiceCardPayments
          .map(
            (a) =>
              `<p>CK / ${a.cardNumber} - ${a.cardType} (${formatFullDate(
                a.paymentTime
              )}): ${formatMoney(a.totalPayment)}</p>`
          )
          .join("")}
      </div>
    
    `;
  return {
    ...order,
    status: order.orderStatus == "FINISHED" ? "Đã hoàn thành" : "In tạm",
    logo: branch.companies.logoUrl
      ? '<img width="100" src="' + branch.companies.logoUrl + '" />'
      : "",
    companyAddress: branch.companies.address,
    companyTaxNumber: branch.companies.taxNumber,
    customerName: order.customers ? order.customers.customerName : "Khách lẻ",
    customerPhone: order.customers ? order.customers.phoneNumber : "",
    customerPoint: order.customers ? order.customers.point : "",
    customerAddress: order.customers ? order.customers.address : "",
    branchAddress: branch.address || "",
    branchPhone: branch.phoneNumber,
    companyPhone: branch.companies.phoneNumber,
    companyName: branch.companies.companyName,
    items,
    tax: formatMoney(order.taxMoney),
    total: formatMoney(order.total),
    grandTotal: formatMoney(order.grandTotal),
    totalReturn: formatMoney(order.totalReturn),
    cashTotal: formatMoney(order.cashTotal),
    surcharge: formatMoney(order.surcharge),
    cardTotal: formatMoney(order.cardTotal),
    totalDiscount: formatMoney(order.totalDiscount),
    cashDiscount: formatMoney(order.cashDiscount),

    beforeSaleOffTotal: formatMoney(order.beforeSaleOffTotal),
    voucherDiscount: formatMoney(order.voucherDiscount),
    couponDiscount: formatMoney(order.couponDiscount),

    totalDiscountInvoice: formatMoney(order.totalDiscountInvoice),

    saleOffTotal: formatMoney(order.saleOffTotal),
    totalDiscountProduct: formatMoney(order.totalDiscountProduct),
    discountCustomerTotal: formatMoney(order.discountCustomerTotal),

    extraCard: formatMoney(order.extraCard),
    extraCash: formatMoney(order.extraCash),
    extraMoney: formatMoney(order.extraMoney),

    cashTotalMoney: formatMoney(order.cashTotalMoney),
    cardTotalMoney: formatMoney(order.cardTotalMoney),
    cashTotalReturnMoney: formatMoney(order.cashTotalReturnMoney),
    cardTotalReturnMoney: formatMoney(order.cardTotalReturnMoney),
    remainPayment: formatMoney(order.remainPayment),
    paidMoney: formatMoney(order.paidMoney),

    totalMoney: formatMoney(order.totalMoney),
    totalMoneyReturn: formatMoney(order.totalMoneyReturn),

    totalPayment: formatMoney(order.cashTotal + order.cardTotal),
    invoiceDate:
      formatDate(order.invoiceDate) + " | " + formatTime(order.invoiceDate),
    datePrint: formatDate() + " | " + formatTime(),
    totalDebt: formatMoney(
      order.customers
        ? order.customers.totalDebt + order.customers.totalCredit
        : 0
    ),
    totalGift: items.reduce((a, b) => a + b.totalGift, 0),
    totalProduct: items.reduce((a, b) => a + b.totalProduct, 0),
    totalQuantity: items.reduce((a, b) => a + b.totalQuantity, 0),
    paymentDetail: paymentDetail,
    branch: branch,
    company: company
    // remainDebt: formatMoney(order.customers ? order.customers.remainDebt : 0),
  };
};

export const formatBillProcessPrint = (data) => {
  const formatMoney = Vue.prototype.$formatMoney;

  Object.keys(data).map((key) => {
    if (typeof data[key] == "number") {
      data[key] = formatMoney(data[key]);
    }
  });

  const process = data.process;
  Object.keys(process).map((key) => {
    if (typeof process[key] == "number") {
      process[key] = formatMoney(process[key]);
    }
  });

  return {
    ...data,
    process: process,
    items: data.items.map((i, idx) => {
      Object.keys(i).map((key) => {
        if (typeof i[key] == "number") {
          i[key] = formatMoney(i[key]);
        }
      });
      return {
        ...i,
      };
    }),
  };
};

export function printBill(listOrder, billTemplate) {
  var html = "";
  listOrder.map((a) => {
    var d = a;
    if (
      billTemplate.templateType == "ORDER" ||
      billTemplate.templateType == "REFUND"
    ) {
      d = formatBillPrint(a);
    }
    if (billTemplate.templateType == "ORDER_PROCESS") {
      d = formatBillProcessPrint(a);
    }
    d.branch = Vue.prototype.$branch;
    d.company = Vue.prototype.$company;

    html += renderTemplate(billTemplate, d);
    if (listOrder.length > 1) {
      html += '<div style="page-break-after: always;"></div>';
    }
  });
  printJS({
    printable: [],
    properties: [],
    header: html,
    style: printStyle + " " + (billTemplate.style || ""),
    type: "json",
  });
}

export function printShiftSalesReport(reportData, rePrint) {
  const formatDate = Vue.prototype.$formatDate;
  const formatMoney = Vue.prototype.$formatMoney;
  const formatTime = Vue.prototype.$formatTime;
  const branchInfo = Vue.prototype.$branch;

  let cashPaymentDetails = [];
  reportData.salesReport.cashPaymentDetails.forEach((p) => {
    cashPaymentDetails.push(
      ` <tr class="row-data">
                <td>${p.currency}</td>
                <td  class="text-right">${formatMoney(p.total)}</td>
            </tr>
          `
    );
  });
  let cardPaymentDetails = [];
  reportData.salesReport.cardPaymentDetails.forEach((p) => {
    cardPaymentDetails.push(
      `
            <tr class="row-data">
                <td>${p.cardNumber}</td>
                <td  class="text-right">${formatMoney(p.total)}</td>
            </tr>
          `
    );
  });

  let html = `
        <div style="padding: 0 20px; font-size: 600;">
          <h5 class="text-center upercase">${
            branchInfo.companies.companyName
          } - ${branchInfo.branchName}</h5>
          <p class="text-center">${branchInfo.address}</p>
          <p class="text-center">Tel: ${branchInfo.phoneNumber}</p>
          <h5 class="text-center">BÁO CÁO GIAO CA
          <p  class="text-center"> ${rePrint ? "(IN LẠI)" : ""}</p>
          </h5>

          <p><strong>Người giao: </strong>${
            reportData.usersTransfer.userName
          }</p>
          <p><strong>Người nhận: </strong>${
            reportData.usersReceiver.userName
          }</p>

          <p><strong> Ngày: </strong> ${formatDate(
            reportData.endDate
          )} | ${formatTime(reportData.endDate)}</p>
          <p><strong> CA: </strong> ${reportData.shiftName}</p>

          <hr />
          <table class="table">
              <thead>
                  <tr class="row-data" style="background: #ccc">
                      <th  class="text-left">Chỉ tiêu tài chính</th>
                      <th  class="text-right">Số tiền</th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="row-data">
                      <td class="bold">Tổng hàng bán</td>
                      <td  class="text-right bold">
                        ${formatMoney(reportData.salesReport.total)}
                      </td>
                  </tr>
                  <tr class="row-data">
                      <td class="bold">Tổng số hoá đơn</td>
                      <td  class="text-right bold">
                        ${formatMoney(reportData.salesReport.invoiceCounts)}   
                      </td>
                  </tr>
                  <tr class="row-data">
                      <td>Tổng giảm giá</td>
                      <td  class="text-right">${formatMoney(
                        reportData.salesReport.discountTotal
                      )}</td>
                  </tr>
                  <tr class="row-data">
                      <td>Tổng doanh thu</td>
                      <td  class="text-right">${formatMoney(
                        reportData.salesReport.grandTotal
                      )}</td>
                  </tr>

                  <tr class="row-data">
                        <td>Công nợ</td>
                        <td  class="text-right">${formatMoney(
                          reportData.salesReport.incurredDebt -
                            reportData.salesReport.paidDebt
                        )}</td>
                  </tr>
                  <tr class="row-data">
                      <td class="bold">Thanh toán</td>
                      <td class="text-right bold">${formatMoney(
                        reportData.salesReport.paymentTotal
                      )}</td>
                  </tr>
                  ${cashPaymentDetails.join("")}
                  ${cardPaymentDetails.join("")}


                  <tr class="row-data bold">
                        <td>TỔNG TIỀN CỦA CA</td>
                        <td  class="text-right">${formatMoney(
                          reportData.salesReport.realRevenueTotal
                        )}</td>
                  </tr>
                  <tr class="row-data bold">
                        <td>TỔNG TIỀN NGÀY</td>
                        <td  class="text-right">${formatMoney(
                          reportData.realRevenueTotalByDate
                        )}</td>
                  </tr>
              </tbody>
          </table>
          <hr />
          <p class="text-center">
              <span style="margin-right: 10px;" >Ngày......</span>
              <span  style="margin-right: 10px;" >Tháng......</span>
              <span style="margin-right: 10px;">Năm......</span>
          </p>
          <p class="text-center" style="margin-bottom: 50px; margin-top:50px"><strong>Người lập phiếu</strong></p>
          <p class="text-center"><strong></strong></p>
        </div>
      `;
  printJS({
    printable: [],
    properties: [],
    header: html,
    style: printStyleSmall,
    type: "json",
  });
}

export const printIncomeExpense = (item) => {
  const formatDate = Vue.prototype.$formatDate;
  const formatMoney = Vue.prototype.$formatMoney;

  const templateIncome = `
  <div style="padding: 20px">
    <table style="width:100%">
        <tr>
            <td width="40%">
                <p>Đơn vị: $branchName$</p>
                <p>Địa chỉ: $branchAddress$</p>
            </td>
            <td width="60%" style="text-align: center">

                <p><b>Mẫu số 1 - TT</b></p>
                <p><i>(Ban hành theo thông tư số 200/2014/TT-BTC Ngày 22/12/2014 của trưởng BTC)</i></p>
            </td>
        </tr>
    </table>
    <br />
   
    <table style="width:100%">
        <tr>
                <td width="70%">
                <div style="text-align: center">
                <h3>$type$</h3>
                <p>$dateCreated$</p>
            </div>
            </td>
            <td width="30%">
                <p>Quyển số: $bookNo$ </p>
                <p>Số phiếu: $codeNumber$ </p>
                <br/>
                <p>Nợ: $debt$ </p>
                <p>Có: $credit$</p>
            </td>
        </tr>
    </table>
    <br />
    <div>
        <p>Họ và tên người nộp tiền: $transfer$</p>
        <p>Địa chỉ: $address$</p>
        <p>Lý do nộp: $reason$</p>
        <p>Số tiền: $amount$ (viết bằng chữ)  $stringMoney$</p>
        <p>Kèm theo: $ref$ chứng từ gốc </p>
        <br />
    </div>
    <table style="width:100%">
      <tr>
        <td width="20%" style="text-align: center">
          <p><b>Giám đốc</b></p>
          <p>(Ký, họ tên, đóng dấu)</p>
          <br />
          <br />
          <br />
          <br />
          <br />
        </td> 
        <td width="20%" style="text-align: center">
          <p><b>Kế toán trưởng</b></p>
          <p>(Ký, họ tên)</p>
          <br />
          <br />
          <br />
          <br />
          <br />
        </td> 
        <td width="20%" style="text-align: center">
          <p><b>Người nộp tiền</b></p>
          <p>(Ký, họ tên)</p>
          <br />
          <br />
          <br />
          <br />
          <br />
        </td> 
        <td width="20%" style="text-align: center">
          <p><b>Người lập</b></p>
          <p>(Ký, họ tên)</p>
          <br />
          <br />
          <br />
          <br />
          <br />
        </td> 
        <td width="20%" style="text-align: center">
          <p><b>Thủ quỹ</b></p>
          <p>(Ký, họ tên)</p>
          <br />
          <br />
          <br />
          <br />
          <br />
        </td> 
      </tr>
    </table>
</div>
`;

  var replaceObj = {
    ...item,
    bookNo: item.bookNo || "_",
    codeNumber: item.codeNumber || "_",
    type: item.accountantType == "INCOME" ? "PHIẾU THU" : "PHIẾU CHI",
    branchName: item.branches.branchName,
    branchAddress: item.branches.address,
    totalReceipt: formatMoney(item.totalPayment),
    totalReceiptString: moneyToString(item.totalPayment),
    dateCreated: formatDate(item.startDate),
    debt: item.payments
      .map((i) => i.debtAcc.detail.code)
      .unique()
      .join(", "),
    credit: item.payments
      .map((i) => i.availableAcc.detail.code)
      .unique()
      .join(", "),
    transfer: item.transfer,
    address: item.address,
    reason: item.description,
    amount: formatMoney(item.totalPayment),
    ref: "",
    stringMoney: moneyToString(item.totalPayment),
    currentDate: formatDate(new Date()),
    originalCurrency: item.originalCurrency,
    origin: formatMoney(item.totalPayment),
  };
  let html = templateIncome;
  Object.keys(replaceObj).map((i) => {
    var key = "$" + i + "$";
    html = html.replace(key, replaceObj[i], "g");
  });
  printJS({
    printable: [],
    properties: [],
    header: html,
    style: printStyleSmall,
    type: "json",
  });
};

export const exportToExcel = () => {
  var htmls = "";
  var uri = "data:application/vnd.ms-excel;base64,";
  var template =
    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
  var base64 = function (s) {
    return window.btoa(unescape(encodeURIComponent(s)));
  };

  var format = function (s, c) {
    return s.replace(/{(\w+)}/g, function (m, p) {
      return c[p];
    });
  };

  htmls = "YOUR HTML AS TABLE";

  var ctx = {
    worksheet: "Worksheet",
    table: htmls,
  };

  var link = document.createElement("a");
  link.download = "export.xls";
  link.href = uri + base64(format(template, ctx));
  link.click();
};

export const renderTemplate = (raw, data) => {
  console.log(raw);
  var rawTemplate = JSON.parse(JSON.stringify(raw));
  rawTemplate.templateItem = rawTemplate.templateItem || "";
  var fIndex = rawTemplate.templateItem.indexOf("<tbody>");
  var lIndex = rawTemplate.templateItem.indexOf("</tbody>");

  var row = rawTemplate.templateItem.slice(fIndex + 7, lIndex);
  var table = rawTemplate.templateItem.replace(row, "");
  var b = (data.items || [])
    .map((i) => {
      var v = row;
      Object.keys(i).map((key) => {
        var regex = new RegExp("\\$p." + key + "\\$", "g");
        v = v.replace(regex, _.get(i, key));
      });
      return v;
    })
    .join("");
  console.log("b", b, row, data.items);
  table = table.insert(fIndex + 7, b);

  data["billData"] = `<div class="bill-table">${table}</div>`;
  var template = rawTemplate.template || "";
  const regex = /\$(.*?)\$/g;
  template = template.replace(regex, (match, key) => {
    return _.get(data, key) || "";
  });
  return template;
};

export const printExcelStock = (
  list,
  filename = "Tonkho",
  haveCost,
  haveDetail
) => {
  filename += ".xlsx";

  const formatDate = Vue.prototype.$formatDate;
  const branch = Vue.prototype.$branch;

  var ws_name = "Tồn theo sản phẩm";
  const productList = [];
  var cols = [];
  var header = [
    "STT",
    "Mã SP",
    "Tên SP",
    "Đơn vị",
    "Giá bán",
    "Giá vốn",

    "Chi nhánh",
    ...branch.stocks.map((a) => a.stockName),
  ].map((i, idx) => {
    cols[idx] = i.length;
    return { v: i, s: { fill: { fgColor: "00ff00" } } };
  });
  if (!haveCost) {
    header.splice(5, 1);
  }
  productList.push(header);

  var i = 1;
  list.map((product) => {
    var productRow = [
      i++,
      product.code,
      product.name,
      product.unit ? product.unit.name : "",
      product.productPrices.find(
        (a) => a.branches.id == branch.id && a.priceLevel.isDefault
      )?.price,
      product.productCostPrices[0]?.costPrice,

      branch.branchName,

      ...branch.stocks.map((a) =>
        _.sumBy(
          product.inventories.filter((i) => i.stockId == a.stockId),
          "availableQty"
        )
      ),
    ];
    if (!haveCost) {
      productRow.splice(5, 1);
    }
    productRow.map((d, idx) => {
      cols[idx] = Math.max(cols[idx], (d + "").length);
    });
    productList.push(productRow);
  });
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.aoa_to_sheet(productList);

  ws["!cols"] = cols.map((a) => ({ wch: a + 4 }));
  // ws["!merges"] = merge;
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  if (haveDetail) {
    // xuats theo kho lo
    const stockList = [];

    var colPayment = [];
    var headerPayment = [
      "STT",
      "Mã SP",
      "Tên SP",
      "Đơn vị",

      "Giá bán",
      "Giá vốn",

      "Chi nhánh",
      "Kho",
      "Lô",
      "Ngày nhập",
      "Ngày sản xuất",
      "Ngày hết hạn",
      "Số lượng",
    ].map((i, idx) => {
      colPayment[idx] = i.length;
      return { v: i, s: { fill: { fgColor: "00ff00" } } };
    });

    if (!haveCost) {
      header.splice(5, 1);
    }
    stockList.push(headerPayment);
    var i2 = 1;
    list.map((product) => {
      product.inventories.map((i) => {
        const newItem = [
          i2++,
          product.code,
          product.name,
          product.unit ? product.unit.name : "",
          product.productPrices.find(
            (a) => a.branches.id == branch.id && a.priceLevel.isDefault
          )?.price,
          product.productCostPrices[0]?.costPrice,
          branch.branchName,
          i.stockName,
          i.batchName,
          i.inventoryDate ? formatDate(i.inventoryDate) : "",
          i.manufacturingDate ? formatDate(i.manufacturingDate) : "",
          i.expiryDate ? formatDate(i.expiryDate) : "",
          i.availableQty,
        ];

        if (!haveCost) {
          header.splice(5, 1);
        }
        newItem.map((d, idx) => {
          colPayment[idx] = Math.max(colPayment[idx], (d + "").length);
        });
        stockList.push(newItem);
      });
    });

    var wsPayment = XLSX.utils.aoa_to_sheet(stockList);
    wsPayment["!cols"] = colPayment.map((a) => ({ wch: a + 4 }));
    XLSX.utils.book_append_sheet(wb, wsPayment, "Kho - Lô");
  }

  XLSX.writeFile(wb, filename);
};

export const printExcelInvoice = (
  list,
  filename = "Doanhthu",
  from,
  to,
  haveDetail,
  haveCost
) => {
  const formatDate = Vue.prototype.$formatDate;
  const formatTime = Vue.prototype.$formatTime;

  filename += "_" + from + "-" + to + ".xlsx";

  let branch = localStorage.getItem("branch");
  branch = JSON.parse(branch);

  var ws_name = "Doanh thu";
  const orderList = [];
  orderList.push([branch.companies && branch.companies.companyName]);
  orderList.push([{ v: "", s: { font: { bold: true, sz: 22 } } }]);
  orderList.push([branch.address + " | " + branch.phoneNumber]);
  orderList.push([]);
  orderList.push(["BÁO CÁO CHI TIẾT DOANH THU NGÀY"]);
  orderList.push(["Từ ngày: " + from + " đến ngày: " + to]);
  orderList.push([]);
  var cols = [];
  var header = [
    "STT",
    "Mã phiếu",
    "Chi nhánh",
    "Loại hoá đơn",
    "Ngày lập",
    "Ngày ghi nhận",
    "Tổng số lượng",
    "Tiền vốn",
    "Tiền hàng",
    "CK/KM",
    "Phụ phí",
    "Trước thuế",
    "Thuế",
    "Sau thuế",
    "Đã thanh toán",
    "Tiền mặt",
    "Chuyển khoản",
    "Còn lại",
    "Tổng công nợ",
    "Công nợ còn lại",
    "Phải thu",
    "Phải trả",
    "NV tạo",
    "NV phụ trách",
    "Kênh bán",
    "Mã khách hàng",
    "Khách hàng",
    "Sđt khách",
    "Địa chỉ khách",
    "Ghi chú",
  ].map((i, idx) => {
    cols[idx] = i.length;
    return { v: i, s: { fill: { fgColor: "00ff00" } } };
  });
  if (!haveCost) {
    header.splice(6, 1);
  }
  orderList.push(header);

  var i = 1;
  list.map((invoice) => {
    var invoiceRow = [
      i++,
      invoice.code,
      invoice.branchName,
      invoice.orderType != "RETURN_PRODUCT" ? "Đơn bán " : "Trả hàng",
      formatDate(invoice.invoiceDate) + " " + formatTime(invoice.invoiceDate),
      invoice.invoiceDetails.reduce((a, b) => (a += b.productQuantity), 0),
      invoice.costTotal,
      invoice.beforeSaleOffTotal,
      invoice.totalDiscount,
      invoice.totalSurcharge,
      invoice.grandTotalBeforeTax,
      invoice.taxMoney,
      invoice.grandTotal,
      invoice.paidMoney,
      invoice.cashTotal,
      invoice.cardTotal,
      invoice.remainPayment,
      invoice.totalDebt,
      invoice.remainDebt,
      invoice.orderType != "RETURN_PRODUCT" ? invoice.grandTotal : 0,
      invoice.orderType == "RETURN_PRODUCT" ? invoice.grandTotal : 0,
      invoice.invoiceCreateByUserName,
      invoice.personInCharge ? invoice.personInCharge.fullName : "",
      invoice.channels,
      invoice.customers ? invoice.customers.customerCode : "",
      invoice.customers ? invoice.customers.customerName : "",
      invoice.customers ? invoice.customers.phoneNumber : "",
      invoice.customers ? invoice.customers.address : "",
      invoice.description,
    ];
    if (!haveCost) {
      invoiceRow.splice(6, 1);
    }
    invoiceRow.map((d, idx) => {
      cols[idx] = Math.max(cols[idx], (d + "").length);
    });
    orderList.push(invoiceRow);
  });
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.aoa_to_sheet(orderList);
  ws["!merges"] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 20 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 20 } },
    { s: { r: 2, c: 0 }, e: { r: 2, c: 20 } },
    { s: { r: 3, c: 0 }, e: { r: 3, c: 20 } },
    { s: { r: 4, c: 0 }, e: { r: 4, c: 20 } },
    { s: { r: 5, c: 0 }, e: { r: 5, c: 20 } },
    { s: { r: 6, c: 0 }, e: { r: 6, c: 20 } },
  ];
  ws["!cols"] = cols.map((a) => ({ wch: a + 4 }));
  // ws["!merges"] = merge;
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  if (haveDetail) {
    // xuất cho thanh toán
    const paymentList = [];

    var colPayment = [];
    var headerPayment = [
      "STT",
      "Mã phiếu",
      "Chi nhánh",
      "Loại hoá đơn",
      "Ngày lập",
      "Tổng thanh toán",

      "Ngày thanh toán",
      "Thanh toán",
      "Hình thức thanh toán",

      "Hình thức chuyển khoản",
      "Tài khoản",

      "Nhân viên hưởng",
      "Tỉ lệ hưởng",
      "Số tiền hưởng",

      // "Mã hàng",
      // "Tên hàng",
      // "Nhóm hàng",
      // "Loại hàng",
      // "Ngành hàng",
      // "Số lượng",
      // "Giá",
      // "Giá vốn",
      // "CK / KM",
      // "Phụ phí",
      // "Tiền hàng",
      // "Trước thuế",
      // "Thuế",
      // "Sau thuế",

      "NV tạo",
      "NV phụ trách",
      "Kênh bán",
      "Mã khách hàng",
      "Khách hàng",
      "Sđt khách",
      "Địa chỉ khách",
      "Ghi chú",
    ].map((i, idx) => {
      colPayment[idx] = i.length;
      return { v: i, s: { fill: { fgColor: "00ff00" } } };
    });
    paymentList.push(headerPayment);
    var i2 = 1;
    list.map((invoice) => {
      const invoiceCashPayments = invoice.invoiceCashPayments.map((item) => ({
        ...item,
        paymentType: "Tiền mặt",
      }));
      const invoiceCardPayments = invoice.invoiceCardPayments.map((item) => ({
        ...item,
        paymentType: "Chuyển khoản",
      }));
      [...invoiceCashPayments, ...invoiceCardPayments].map((item) => {
        invoice.employeeSales.map((employee) => {
          const newItem = [
            i2++,
            invoice.code,
            invoice.branchName,
            invoice.orderType != "RETURN_PRODUCT" ? "Đơn bán " : "Trả hàng",
            formatDate(invoice.invoiceDate) +
              " " +
              formatTime(invoice.invoiceDate),

            invoice.grandTotal,

            moment(item.paymentTime).format("YYYY-MM-DD HH:mm"),
            item.totalPayment,
            item.paymentType,
            item.cardType,
            item.cardNumber,

            employee.employeeName,
            employee.percentSale,
            employee.percentSale * item.totalPayment * 0.01,

            // item.smallGroup,
            // item.middleGroup,
            // item.largeGroup,
            // item.productQuantity,
            // item.productPrice,
            // item.costTotal,
            // item.cashSaleOff + item.orderSaleOff + item.discountCustomer,
            // item.surcharge + item.orderSurcharge,
            // item.total,
            // item.totalBeforeTax,
            // item.taxMoney,
            // item.totalAfterTax,

            invoice.invoiceCreateByUserName,
            invoice.personInCharge ? invoice.personInCharge.fullName : "",
            invoice.channels,
            invoice.customers ? invoice.customers.customerCode : "",
            invoice.customers ? invoice.customers.customerName : "",
            invoice.customers ? invoice.customers.phoneNumber : "",
            invoice.customers ? invoice.customers.address : "",
            invoice.description,
          ];
          if (!haveCost) {
            newItem.splice(12, 1);
          }
          newItem.map((d, idx) => {
            colPayment[idx] = Math.max(colPayment[idx], (d + "").length);
          });
          paymentList.push(newItem);
        });
      });
    });

    var wsPayment = XLSX.utils.aoa_to_sheet(paymentList);
    wsPayment["!cols"] = colPayment.map((a) => ({ wch: a + 4 }));
    XLSX.utils.book_append_sheet(wb, wsPayment, "Thanh toán");

    // xuất cho danh sách sp
    const productList = [];

    var colProduct = [];
    var headerProduct = [
      "STT",
      "Mã phiếu",
      "Chi nhánh",
      "Loại hoá đơn",
      "Ngày lập",
      "Ngày ghi nhận",

      "Mã hàng",
      "Tên hàng",
      "Nhóm hàng",
      "Loại hàng",
      "Ngành hàng",
      "Số lượng",
      "Giá",
      "Giá vốn",
      "CK / KM",
      "Phụ phí",
      "Tiền hàng",
      "Trước thuế",
      "Thuế",
      "Sau thuế",

      "NV tạo",
      "NV phụ trách",
      "Kênh bán",
      "Mã khách hàng",
      "Khách hàng",
      "Sđt khách",
      "Địa chỉ khách",
      "Ghi chú",
    ].map((i, idx) => {
      colProduct[idx] = i.length;
      return { v: i, s: { fill: { fgColor: "00ff00" } } };
    });
    if (!haveCost) {
      headerProduct.splice(12, 1);
    }
    productList.push(headerProduct);
    var i2 = 1;
    list.map((invoice) => {
      invoice.invoiceDetails.map((item) => {
        console.log(item);
        const newItem = [
          i2++,
          invoice.code,
          invoice.branchName,
          invoice.orderType != "RETURN_PRODUCT" ? "Đơn bán " : "Trả hàng",
          formatDate(invoice.invoiceDate) +
            " " +
            formatTime(invoice.invoiceDate),
          formatDate(item.invoiceProcessSteps.processDate) +
            " " +
            formatTime(item.invoiceProcessSteps.processDate),
          item.productCode,
          item.productName,
          item.smallGroup,
          item.middleGroup,
          item.largeGroup,
          item.productQuantity,
          item.productPrice,
          item.costTotal,
          item.cashSaleOff + item.orderSaleOff + item.discountCustomer,
          item.surcharge + item.orderSurcharge,
          item.total,
          item.totalBeforeTax,
          item.taxMoney,
          item.totalAfterTax,

          invoice.invoiceCreateByUserName,
          invoice.personInCharge ? invoice.personInCharge.fullName : "",
          invoice.channels,
          invoice.customers ? invoice.customers.customerCode : "",
          invoice.customers ? invoice.customers.customerName : "",
          invoice.customers ? invoice.customers.phoneNumber : "",
          invoice.customers ? invoice.customers.address : "",
          invoice.description,
        ];
        if (!haveCost) {
          newItem.splice(12, 1);
        }
        newItem.map((d, idx) => {
          colProduct[idx] = Math.max(colProduct[idx], (d + "").length);
        });
        productList.push(newItem);
      });
    });

    var wsProduct = XLSX.utils.aoa_to_sheet(productList);
    wsProduct["!cols"] = colProduct.map((a) => ({ wch: a + 4 }));
    XLSX.utils.book_append_sheet(wb, wsProduct, "Sản phẩm bán");

    // xuất theo nv thụ hưởng

    const productListEmployee = [];

    var colProductEmployee = [];
    var headerProductEmployee = [
      "STT",
      "Mã phiếu",
      "Chi nhánh",
      "Loại hoá đơn",
      "Ngày lập",
      "Ngày ghi nhận",

      "Mã hàng",
      "Tên hàng",
      "Nhóm hàng",
      "Loại hàng",
      "Ngành hàng",
      "Số lượng",
      "Giá",
      "Giá vốn",
      "CK / KM",
      "Phụ phí",
      "Tiền hàng",
      "Trước thuế",
      "Thuế",
      "Sau thuế",

      "Nv thụ hưởng",
      "Tỉ lệ thụ hưởng",
      "Tiền thụ hưởng",

      "NV tạo",
      "NV phụ trách",
      "Kênh bán",
      "Mã khách hàng",
      "Khách hàng",
      "Sđt khách",
      "Địa chỉ khách",
      "Ghi chú",
    ].map((i, idx) => {
      colProductEmployee[idx] = i.length;
      return { v: i, s: { fill: { fgColor: "00ff00" } } };
    });
    if (!haveCost) {
      headerProductEmployee.splice(12, 1);
    }
    productListEmployee.push(headerProductEmployee);
    var i2 = 1;
    list.map((invoice) => {
      let employeeSales = invoice.employeeSales;
      if (!employeeSales || employeeSales.length == 0) {
        employeeSales = [{ employeeName: "-", percentSale: 100 }];
      }
      invoice.invoiceDetails.map((item) => {
        employeeSales.forEach((employee) => {
          const newItem = [
            i2++,
            invoice.code,
            invoice.branchName,
            invoice.orderType != "RETURN_PRODUCT" ? "Đơn bán " : "Trả hàng",
            formatDate(invoice.invoiceDate) +
              " " +
              formatTime(invoice.invoiceDate),
            formatDate(item.invoiceProcessSteps.processDate) +
              " " +
              formatTime(item.invoiceProcessSteps.processDate),
            item.productCode,
            item.productName,
            item.smallGroup,
            item.middleGroup,
            item.largeGroup,
            item.productQuantity,
            item.productPrice,
            item.costTotal,
            item.cashSaleOff + item.orderSaleOff + item.discountCustomer,
            item.surcharge + item.orderSurcharge,
            item.total,
            item.totalBeforeTax,
            item.taxMoney,
            item.totalAfterTax,

            employee.employeeName,
            employee.percentSale,

            employee.percentSale * 0.01 * item.totalBeforeTax,

            invoice.invoiceCreateByUserName,
            invoice.personInCharge ? invoice.personInCharge.fullName : "",
            invoice.channels,
            invoice.customers ? invoice.customers.customerCode : "",
            invoice.customers ? invoice.customers.customerName : "",
            invoice.customers ? invoice.customers.phoneNumber : "",
            invoice.customers ? invoice.customers.address : "",
            invoice.description,
          ];
          if (!haveCost) {
            newItem.splice(12, 1);
          }
          newItem.map((d, idx) => {
            colProductEmployee[idx] = Math.max(
              colProductEmployee[idx],
              (d + "").length
            );
          });
          productListEmployee.push(newItem);
        });
      });
    });

    var wsProduct = XLSX.utils.aoa_to_sheet(productListEmployee);
    wsProduct["!cols"] = colProductEmployee.map((a) => ({ wch: a + 4 }));
    XLSX.utils.book_append_sheet(wb, wsProduct, "Sản phẩm theo nhân viên");

    // xuất theo lô hàng

    // xuất theo nv thụ hưởng

    const productListStock = [];

    var colProductStock = [];
    var headerProductStock = [
      "STT",
      "Mã phiếu",
      "Chi nhánh",
      "Loại hoá đơn",
      "Ngày lập",
      "Ngày ghi nhận",

      "Mã hàng",
      "Tên hàng",
      "Nhóm hàng",
      "Loại hàng",
      "Ngành hàng",
      "Số lượng",
      "Giá",
      "Giá vốn",
      "CK / KM",
      "Phụ phí",
      "Tiền hàng",
      "Trước thuế",
      "Thuế",
      "Sau thuế",

      "Lô hàng",
      "Kho",
      "NSX",
      "HSD",

      "NV tạo",
      "NV phụ trách",
      "Kênh bán",
      "Mã khách hàng",
      "Khách hàng",
      "Sđt khách",
      "Địa chỉ khách",
      "Ghi chú",
    ].map((i, idx) => {
      colProductStock[idx] = i.length;
      return { v: i, s: { fill: { fgColor: "00ff00" } } };
    });
    if (!haveCost) {
      headerProductStock.splice(12, 1);
    }
    productListStock.push(headerProductStock);
    var i2 = 1;
    list.map((invoice) => {
      invoice.invoiceDetails.map((item) => {
        item.stocks.forEach((stock) => {
          const newItem = [
            i2++,
            invoice.code,
            invoice.branchName,
            invoice.orderType != "RETURN_PRODUCT" ? "Đơn bán " : "Trả hàng",
            formatDate(invoice.invoiceDate) +
              " " +
              formatTime(invoice.invoiceDate),
            formatDate(item.invoiceProcessSteps.processDate) +
              " " +
              formatTime(item.invoiceProcessSteps.processDate),
            item.productCode,
            item.productName,
            item.smallGroup,
            item.middleGroup,
            item.largeGroup,
            stock.quantity,
            item.productPrice,
            item.costTotal * (stock.quantity / item.productQuantity),
            (item.cashSaleOff + item.orderSaleOff + item.discountCustomer) *
              (stock.quantity / item.productQuantity),
            (item.surcharge + item.orderSurcharge) *
              (stock.quantity / item.productQuantity),
            item.total * (stock.quantity / item.productQuantity),
            item.totalBeforeTax * (stock.quantity / item.productQuantity),
            item.taxMoney * (stock.quantity / item.productQuantity),
            item.totalAfterTax * (stock.quantity / item.productQuantity),

            stock.batchName || "-",
            stock.stockName || "-",
            stock.manufacturingDate || "-",
            stock.expiryDate || "-",

            invoice.invoiceCreateByUserName,
            invoice.personInCharge ? invoice.personInCharge.fullName : "",
            invoice.channels,
            invoice.customers ? invoice.customers.customerCode : "",
            invoice.customers ? invoice.customers.customerName : "",
            invoice.customers ? invoice.customers.phoneNumber : "",
            invoice.customers ? invoice.customers.address : "",
            invoice.description,
          ];
          if (!haveCost) {
            newItem.splice(12, 1);
          }
          newItem.map((d, idx) => {
            colProductStock[idx] = Math.max(
              colProductStock[idx],
              (d + "").length
            );
          });
          productListStock.push(newItem);
        });
      });
    });

    var wsProduct = XLSX.utils.aoa_to_sheet(productListStock);
    wsProduct["!cols"] = colProductStock.map((a) => ({ wch: a + 4 }));
    XLSX.utils.book_append_sheet(wb, wsProduct, "Sản phẩm theo lô - kho");
  }

  XLSX.writeFile(wb, filename);
};

export const printFinanceDetail = (list, detail, config = {}) => {
  const formatDate = Vue.prototype.$formatDate;
  const formatMoney = Vue.prototype.$formatMoney;

  var filename = `${config.filename || "so_chi_tiet"}_${config.from}_${
    config.to
  }.xlsx`;
  let branch = localStorage.getItem("branch");
  branch = JSON.parse(branch);
  var ws_name = "Doanh thu";
  const header = [];
  const createXLSLFormatObj = [];
  header.push([branch.companies && branch.companies.companyName]);
  header.push([branch.address + " | " + (branch.phoneNumber || "")]);
  header.push(["Mã số thuế: " + branch.companies.taxCode]);
  header.push([config.title]);
  header.push([config.note]);
  header.push([config.name]);
  header.push(["Từ ngày: " + config.from + " đến ngày: " + config.to]);
  header.push([]);

  header.map((a) => {
    createXLSLFormatObj.push(a);
  });

  createXLSLFormatObj.push(
    [
      "Ngày ghi nhận",
      "Chứng từ",
      "",
      "Diễn giả",
      "TKĐU",
      "THông tin tiền",
      "",
    ].map((i) => {
      return { v: i, s: { fill: { fgColor: "00ff00" } } };
    })
  );
  createXLSLFormatObj.push(
    ["", "Số", "Ngày", "", "", "Nợ", "Có"].map((i) => {
      return { v: i, s: { fill: { fgColor: "00ff00" } } };
    })
  );
  var invoiceRow = [];
  createXLSLFormatObj.push([
    "Đầu kỳ",
    "",
    "",
    "",
    "",
    formatMoney(detail.openDebt),
    formatMoney(detail.openAvail),
  ]);

  list.map((item) => {
    invoiceRow = [
      formatDate(item.incomeExpense.startDate),
      item.incomeExpense.bookNo,
      formatDate(item.incomeExpense.startDate),
      item.incomeExpense.description,
      item.codeAcc,
      formatMoney(item.debt),
      formatMoney(item.avail),
    ];
    createXLSLFormatObj.push(invoiceRow);
  });

  createXLSLFormatObj.push([
    "Cuối kỳ",
    "",
    "",
    "",
    "",
    formatMoney(detail.closeDebt),
    formatMoney(detail.closeAvail),
  ]);

  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
  const totalRowHeader = header.length;
  const merge = Array(totalRowHeader)
    .fill(0)
    .map((i, index) => {
      return { s: { r: index, c: 0 }, e: { r: index, c: 6 } };
    });
  merge.push({
    s: { r: totalRowHeader, c: 0 },
    e: { r: totalRowHeader + 1, c: 0 },
  }); // merge cột ngày

  merge.push({
    s: { r: totalRowHeader, c: 1 },
    e: { r: totalRowHeader, c: 2 },
  }); //merge hàng chứng từ
  merge.push({
    s: { r: totalRowHeader, c: 3 },
    e: { r: totalRowHeader + 1, c: 3 },
  }); //merge cột diễn dải
  merge.push({
    s: { r: totalRowHeader, c: 4 },
    e: { r: totalRowHeader + 1, c: 4 },
  }); //merge cột TK
  merge.push({
    s: { r: totalRowHeader, c: 5 },
    e: { r: totalRowHeader, c: 6 },
  }); //merge hàng thành tiền

  merge.push({
    s: { r: totalRowHeader + 2, c: 0 },
    e: { r: totalRowHeader + 2, c: 4 },
  }); // merge hàng đầu kỳ
  merge.push({
    s: { r: totalRowHeader + list.length + 3, c: 0 },
    e: { r: totalRowHeader + list.length + 3, c: 4 },
  }); // merge hàng cuối kỳ kỳ
  ws["!merges"] = merge;

  var wscols = [
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 30 },
    { wch: 10 },
    { wch: 15 },
    { wch: 15 },
  ];

  ws["!cols"] = wscols;

  XLSX.utils.book_append_sheet(wb, ws, ws_name);
  XLSX.writeFile(wb, filename);
};

export const printFinance = (list, total, config = {}) => {
  var filename = `${config.filename || "bao-cao"}_${config.from}_${
    config.to
  }.xlsx`;
  let branch = localStorage.getItem("branch");
  branch = JSON.parse(branch);
  var ws_name = "Doanh thu";
  const header = [];
  const createXLSLFormatObj = [];
  header.push([branch.companies && branch.companies.companyName]);
  header.push([branch.address + " | " + (branch.phoneNumber || "")]);
  header.push(["Mã số thuế: " + branch.companies.taxCode]);
  header.push([config.title]);
  header.push([config.note]);
  header.push([config.name]);
  header.push(["Từ ngày: " + config.from + " đến ngày: " + config.to]);
  header.push([]);

  header.map((a) => {
    createXLSLFormatObj.push(a);
  });

  createXLSLFormatObj.push(
    [
      "TT",
      "Mã",
      "Tên đối tượng",
      "Số dư đầu kỳ (" + Vue.prototype.currency$currency + ")	",
      " ",
      "Phát sinh trong kỳ (" + Vue.prototype.currency$currency + ")	",
      "",
      "Số dư cuối kỳ (" + Vue.prototype.currency$currency + ")	",
      "",
    ].map((i) => {
      return { v: i, s: { fill: { fgColor: "00ff00" } } };
    })
  );
  createXLSLFormatObj.push(
    ["", "", "", "Nợ", "Có", "Nợ", "Có", "Nợ", "Có"].map((i) => {
      return { v: i, s: { fill: { fgColor: "00ff00" } } };
    })
  );
  var i = 1;
  var invoiceRow = [];
  list.map((item) => {
    invoiceRow = [
      i++,
      item.code,
      item.name,
      item.openDebt,
      item.openAvail,
      item.middleDebt,
      item.middleAvail,
      item.closeDebt,
      item.closeAvail,
    ];
    createXLSLFormatObj.push(invoiceRow);
  });

  createXLSLFormatObj.push([
    "Tổng cộng",
    "",
    "",
    total.openDebt,
    total.openAvail,
    total.middleDebt,
    total.middleAvail,
    total.closeDebt,
    total.closeAvail,
  ]);
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
  const totalRowHeader = header.length;
  const merge = Array(totalRowHeader)
    .fill(0)
    .map((i, index) => {
      return { s: { r: index, c: 0 }, e: { r: index, c: 8 } };
    });
  merge.push({
    s: { r: totalRowHeader, c: 0 },
    e: { r: totalRowHeader + 1, c: 0 },
  });
  merge.push({
    s: { r: totalRowHeader, c: 1 },
    e: { r: totalRowHeader + 1, c: 1 },
  });
  merge.push({
    s: { r: totalRowHeader, c: 2 },
    e: { r: totalRowHeader + 1, c: 2 },
  });

  merge.push({
    s: { r: totalRowHeader, c: 3 },
    e: { r: totalRowHeader, c: 4 },
  });
  merge.push({
    s: { r: totalRowHeader, c: 5 },
    e: { r: totalRowHeader, c: 6 },
  });
  merge.push({
    s: { r: totalRowHeader, c: 7 },
    e: { r: totalRowHeader, c: 8 },
  });

  merge.push({
    s: { r: totalRowHeader + list.length + 2, c: 0 },
    e: { r: totalRowHeader + list.length + 2, c: 2 },
  });
  ws["!merges"] = merge;

  var wscols = [
    { wch: 5 },
    { wch: 10 },
    { wch: 40 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
  ];

  ws["!cols"] = wscols;

  XLSX.utils.book_append_sheet(wb, ws, ws_name);
  XLSX.writeFile(wb, filename);
};

export const printFinanceFlow = (list, total, config) => {
  const formatDate = Vue.prototype.$formatDate;
  const formatMoney = Vue.prototype.$formatMoney;

  var filename = `${config.filename || "bao-cao"}_${config.from}_${
    config.to
  }.xlsx`;
  let branch = localStorage.getItem("branch");
  branch = JSON.parse(branch);
  var ws_name = "Doanh thu";
  const header = [];
  const createXLSLFormatObj = [];
  header.push([branch.companies && branch.companies.companyName]);
  header.push([branch.address + " | " + (branch.phoneNumber || "")]);
  header.push(["Mã số thuế: " + branch.companies.taxCode]);
  header.push([config.title]);
  header.push([config.note]);
  header.push([config.name]);
  header.push(["Từ ngày: " + config.from + " đến ngày: " + config.to]);
  header.push([]);

  header.map((a) => {
    createXLSLFormatObj.push(a);
  });

  createXLSLFormatObj.push(
    [
      "Ngày ghi sổ",
      "Ngày chứng từ",
      "Sổ chứng từ",
      "",
      "Diễn giải",
      "TKĐU",
      "Số tiền",
      "",
      "",
    ].map((i) => {
      return { v: i, s: { fill: { fgColor: "00ff00" } } };
    })
  );
  createXLSLFormatObj.push(
    ["", "", "Thu", "Chi", "", "", "Thu", "Chi", "Tồn"].map((i) => {
      return { v: i, s: { fill: { fgColor: "00ff00" } } };
    })
  );
  createXLSLFormatObj.push([
    "Tồn đầu kỳ",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    formatMoney(total.start),
  ]);

  var invoiceRow = [];
  list.map((item) => {
    invoiceRow = [
      formatDate(item.date),
      formatDate(item.date),
      item.codeNumberDebt,
      item.codeNumberAvailable,
      item.incomeExpense.description,
      item.isDebt ? item.availableCode : item.debtCode,
      formatMoney(item.totalDebt),
      formatMoney(item.totalAvailable),
      formatMoney(item.totalAfter),
    ];
    createXLSLFormatObj.push(invoiceRow);
  });

  createXLSLFormatObj.push([
    "Tổng cộng",
    "",
    "",
    "",
    "",
    "",
    formatMoney(total.income),
    formatMoney(total.expense),
    "",
  ]);
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
  const totalRowHeader = header.length;
  const merge = Array(totalRowHeader)
    .fill(0)
    .map((i, index) => {
      return { s: { r: index, c: 0 }, e: { r: index, c: 8 } };
    });
  merge.push({
    s: { r: totalRowHeader, c: 0 },
    e: { r: totalRowHeader + 1, c: 0 },
  });
  merge.push({
    s: { r: totalRowHeader, c: 1 },
    e: { r: totalRowHeader + 1, c: 1 },
  });
  merge.push({
    s: { r: totalRowHeader, c: 2 },
    e: { r: totalRowHeader, c: 3 },
  });

  merge.push({
    s: { r: totalRowHeader, c: 4 },
    e: { r: totalRowHeader + 1, c: 4 },
  });
  merge.push({
    s: { r: totalRowHeader, c: 5 },
    e: { r: totalRowHeader + 1, c: 5 },
  });
  merge.push({
    s: { r: totalRowHeader, c: 6 },
    e: { r: totalRowHeader, c: 8 },
  });

  merge.push({
    s: { r: totalRowHeader + 2, c: 0 },
    e: { r: totalRowHeader + 2, c: 5 },
  });

  merge.push({
    s: { r: totalRowHeader + list.length + 3, c: 0 },
    e: { r: totalRowHeader + list.length + 3, c: 5 },
  });
  ws["!merges"] = merge;

  var wscols = [
    { wch: 12 },
    { wch: 12 },
    { wch: 10 },
    { wch: 10 },
    { wch: 40 },
    { wch: 10 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
  ];

  ws["!cols"] = wscols;

  XLSX.utils.book_append_sheet(wb, ws, ws_name);
  XLSX.writeFile(wb, filename);
};

export const printFinanceCost = (list, total, config) => {
  const formatDate = Vue.prototype.$formatDate;
  const formatMoney = Vue.prototype.$formatMoney;

  var filename = `${config.filename || "chi_phi"}_${config.from}_${
    config.to
  }.xlsx`;
  let branch = localStorage.getItem("branch");
  branch = JSON.parse(branch);
  var ws_name = "Chi phí";
  const header = [];
  const createXLSLFormatObj = [];
  header.push([branch.companies && branch.companies.companyName]);
  header.push([branch.address + " | " + (branch.phoneNumber || "")]);
  header.push(["Mã số thuế: " + branch.companies.taxCode]);
  header.push([config.title]);
  header.push([config.note]);
  header.push([config.name]);
  header.push(["Từ ngày: " + config.from + " đến ngày: " + config.to]);
  header.push([]);

  header.map((a) => {
    createXLSLFormatObj.push(a);
  });

  createXLSLFormatObj.push(
    ["Ngày", "Nội dung", "Diễn giải", "Nợ", "Có", "Thành tiền"].map((i) => {
      return { v: i, s: { fill: { fgColor: "00ff00" } } };
    })
  );

  var invoiceRow = [];
  list.map((item) => {
    item.children.map((child) => {
      invoiceRow = [
        formatDate(child.date),
        child.codeNumber,
        child.description,

        child.debtAcc.detail.code,
        child.availableAcc.detail.code,
        formatMoney(child.totalPayment),
      ];
      createXLSLFormatObj.push(invoiceRow);
    });

    invoiceRow = [
      "",
      item.elementCode,
      "Cộng:" + item.elementName,
      "",
      "",
      formatMoney(item.totalPayment),
    ];
    createXLSLFormatObj.push(invoiceRow);
  });

  createXLSLFormatObj.push([
    "Tổng cộng",
    "",
    "",
    "",
    "",
    formatMoney(list.reduce((a, b) => (a += +b.totalPayment), 0)),
  ]);
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
  const totalRowHeader = header.length;
  const merge = Array(totalRowHeader)
    .fill(0)
    .map((i, index) => {
      return { s: { r: index, c: 0 }, e: { r: index, c: 5 } };
    });
  // console.log(totalRowHeader + list.length)
  merge.push({
    s: { r: createXLSLFormatObj.length - 1, c: 0 },
    e: { r: createXLSLFormatObj.length - 1, c: 4 },
  });
  ws["!merges"] = merge;

  var wscols = [
    { wch: 12 },
    { wch: 12 },
    { wch: 50 },
    { wch: 15 },
    { wch: 15 },
    { wch: 20 },
  ];

  ws["!cols"] = wscols;

  XLSX.utils.book_append_sheet(wb, ws, ws_name);
  XLSX.writeFile(wb, filename);
};
