<template>
  <section class="menu-sub">
    <div class="menu-list">
      <router-link
        tag="div"
        class="menu-item"
        v-for="(menu, index) in menus"
        :key="index"
        :to="menu.link"
        v-show="$permission(menu.permission)"
        :class="{ active: $route.path == menu.link }"
      >
        <div
          :class="'img'"
          :style="{ backgroundImage: 'url(' + menu.icon + ')' }"
        />
        <div class="title">
          <p>{{ menu.title }}</p>
        </div>
      </router-link>
    </div>
    <div class="data-outer">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        {
          title: "Tất cả đơn",
          icon: "/images/shopping-list.png",
          permission: "ORDER:ALL",
          link: "/order",
        },
        {
          title: "Bán POS",
          icon: "/images/tray.png",
          permission: "ORDER:NEW",
          link: "/order/pos",
        },
        {
          title: "Bán hàng",
          icon: "/images/b2b.png",
          permission: "ORDER:B2B",
          link: "/order/sale",
        },
        {
          title: "Nhiều đơn",
          icon: "/images/bulk.png",
          permission: "ORDER:B2B",
          link: "/order/bulk",
        },
        {
          title: "Đặt hàng",
          icon: "/images/shopping-cart.png",
          permission: "ORDER:CART",
          link: "/order/cart",
        },
        {
          title: "Tiến trình",
          icon: "/images/track.png",
          permission: "ORDER:TRACK",
          link: "/order/process",
        },
        {
          title: "Trả hàng",
          icon: "/images/return.png",
          permission: "ORDER:REFUND",
          link: "/order/refund",
        },
      ],
    };
  },
};
</script>
