import Vue from "vue";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import { ApolloLink, concat } from "apollo-link";

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_RES_API + "/graphql",
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    Vue.prototype.$noti.open({
      text:
        Vue.prototype.$lang == "en"
          ? graphQLErrors[0].messageEn
          : graphQLErrors[0].message,
      dataCopy: JSON.stringify({
        body: operation.query.loc.source.body,
        variables: operation.variables,
      }),
      error: true,
    });
  }
  if (networkError) {
    if (networkError.statusCode === 401) {
      localStorage.removeItem("token");
      // if (!localStorage.getItem("returnUrl")) {
      //     localStorage.setItem("returnUrl", window.location.href);
      // }
      window.location.href = "/";
      return;
    }
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: `Bearer ${
        localStorage.getItem("token") ? localStorage.getItem("token") : ""
      }`,
    },
  });
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: concat(authMiddleware, errorLink.concat(httpLink)),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

Vue.use(VueApollo);

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
