import {
  createCustomer,
  findCustomerByCode,
  findCustomers,
  getCustomerGroup,
  createCustomerGroup,
  updateCustomerGroup,
  updateProductVariant,
  createProductVariant,
  createPrintTemplate,
  findPrintTemplate,
  findWarehouseByCode,
  createCustomerDebt,
  findCustomerDebt,
  findPosts,
  findDiscussionMsg,
  addDiscussion,
  createPost,
  findOrderBulks,
  createOrderBulk,
  createQRPayment,
  inquiryQRPaymentStatus,
  incomeExpensePaymentSummary,
  updateReceiveBranch,
  saleProductByOrderType,
  addProductShopee,
  exportProductByType,
  createCustomerCareResult,
  createSearchHistory,
  getProductVariant,
  getProductSyncApp,
  customerProductSaleReport,
  productChannelReport,
  changeInvoiceDate,
  addServiceReceipt,
  createUser,
  createBrand,
  employeeSaleTargetByEmployee,
  employeeSaleTarget,
  employeeRevenueReport,
  findCredit,
  getAssetReport,
  inventoryWarehouseReport,
  createCredit,
  getCart,
  saveOrder,
  incomeExpensePaymentReport,
  purchaseOrderSummaryBySupplier,
  getPurchaseOrdersSummary,
  inventoryReceiverSummary,
  warehouseOutReceiptsSummaries,
  transferWarehouseSummaries,
  updateStock,
  findIncomeExpensePayment,
  findIncomeExpensePaymentDetail,
  findSmartInventory,
  updateWorkOrderProcess,
  createUserB2B,
  getWorkOrderDetail,
  getWorkOrders,
  addWorkOrderProcess,
  deleteOrder,
  createProduct,
  productImport,
  updateProduct,
  updateStockDefault,
  updateUserBranchDefault,
  findProducts,
  findProductsSelect,
  findProductsHaveBom,
  findProductsAvailable,
  findInvoiceAreasByBranch,
  findDiscountByCode,
  findMiddleGroup,
  findInvoices,
  findInvoicesProcessing,
  invoiceSummaries,
  productInventoryCheck,
  findStocksBranch,
  findOrders,
  findUsers,
  getProductSupplier,
  createProductSupplier,
  updateProductSupplier,
  findRoles,
  findRoleDetails,
  findCompanies,
  findBranches,
  findLargeGroups,
  findCategoriesProductTree,
  findMiddleGroups,
  findSmallGroups,
  findAreas,
  findSupliers,
  findInventories,
  findWarehouse,
  getPermissionSchema,
  findOrder,
  findDiscounts,
  findProductUnits,
  findUser,
  findUserBranches,
  findUserByCompany,
  createWorkOrder,
  findMembershipRank,
  findCurrentUser,
  findBranchOfUser,
  findCustomerOrderHistory,
  findExchangeRatesByBranch,
  findCardsByBranch,
  findEmployeeBranch,
  transferWarehouseApi,
  findBranchByCompany,
  updateCustomerInfo,
  productInventoryStatus,
  transferWarehouseList,
  transferWarehouseDetail,
  findInventoryByStockId,
  completedTransferWarehouse,
  cancelTransferWarehouse,
  updateRoleDetails,
  createRole,
  createUserBranches,
  updateUserBranchRole,
  createEmployee,
  updateCustomerForAdmin,
  updateProductPrices,
  updateProductSale,
  createLargeGroup,
  createSmallGroup,
  createMiddleGroup,
  createDiscount,
  updateDiscount,
  deleteDiscount,
  updateCompany,
  findEmployee,
  updateEmployee,
  createExchangeRates,
  createStockBranch,
  createCard,
  updateCard,
  deleteCard,
  createInvoiceArea,
  updateInvoiceArea,
  deleteInvoiceArea,
  createInvoiceLocation,
  updateInvoiceLocation,
  deleteInvoiceLocation,
  addEmployeeBranch,
  removeEmployeeBranch,
  updateExchangeRates,
  deleteExchangeRate,
  updateBranches,
  findRolesByAdmin,
  findRoleDetailsAdmin,
  createSupplier,
  warehouseInBySupplierDetail,
  updateSupplier,
  createProductUnit,
  updateProductUnit,
  createMembershipRank,
  updateMembershipRank,
  deleteMembershipRank,
  warehouseInReceipt,
  warehouseInReceiptDetail,
  salesReport,
  removeUserRole,
  removeUserBranch,
  findProductList,
  changeUserPassword,
  resetUserPassword,
  resetPassUserB2B,
  cancelOrder,
  findInvoicesSummary,
  updateEmployeeToUser,
  // importExcelProducts,
  productInventoryCheckByStock,
  findCardTypes,
  createCardType,
  updateCardType,
  warehouseImportExcel,
  warehouseOutReceipts,
  exportProduct,
  warehouseOutDetailReceipts,
  productTransferImport,
  exportProductsExcel,
  findInvoiceInfo,
  findUserByBranch,
  orderReturns,
  createAppConfig,
  outClockShiftSales,
  getShiftSales,
  productStockCard,
  supplierSalesReport,
  summaryReport,
  revenueDetailReport,
  findShiftSalesReport,
  updateMultiProducts,
  paymentInvoiceDebt,
  findMinQuantityWarning,
  findOutOfStockByBranch,
  createProductConfig,
  groupSalesReport,
  productSalesTopReport,
  profitProductTopReport,
  createMultiCustomers,
  findAllCircularCategory,
  updateProductCostPrice,
  getCircularCategory,
  createCompanyCircularCategory,
  updateBranchCircularCategory,
  deleteBranchCircularCategory,
  createBranchCircularCategory,
  updateCompanyCircularCategory,
  deleteCompanyCircularCategory,
  // createExpense,
  createIncomeExpense,
  // updateExpense,
  updateIncomeExpense,
  findIncomeExpense,
  // findExpenseExpense,
  getCompanyCircularCategory,
  getBranchCircularCategory,
  findInvoicesBranchForAdmin,
  incomeExpenseSummary,
  getConfigAutoFinance,
  createConfigAutoFinance,
  updateConfigAutoFinance,
  updateConfigAutoFinanceMulti,
  deleteConfigAutoFinance,
  findPurchaseOrders,
  createPurchaseOrder,
  updatePurchaseOrder,
  paymentPurchaseOrder,
  findPurchaseOrderDetail,
  findInvoicesBranchForAdminDetail,
  revenueCostSaleByDate,
  revenueSaleByDate,
  warehouseInReport,
  warehouseOutReport,
  updateWorkOrder,
  inventoryReport,
  findGroupInventory,
  revenueSaleByBranch,
  deleteIncomeExpense,
  findCategoriesTree,
  summaryReportByRange,
  createMultiProduct,
  findPaginationSupplierByCompany,
  updateSmallGroup,
  updateMiddleGroup,
  updateProductBOM,
  updateLargeGroup,
  purchaseOrderDetailReport,
  transferProductReport,
  purchaseOrderSupplierReport,
  updateTransferWarehouse,
  getInvoiceSummaryByCusCom,
  getTopCustomerBuyProduct,
  getTopCustomerGroupBuyProduct,
  getTopCustomer,
  saleChannelReportTop,
  findChannels,
  createSaleChannel,
  updateSaleChannel,
  saleChannelReport,
  incomeExpenseManualReport,
  updatePointCustomer,
  // addInvoiceProcessStep,
  // completeOrderB2B,
  getOrderTemplate,
  createOrderTemplate,
  updateOrderTemplate,
  getOrderTemplateDetail,
  updateInvoice,
  getCustomerSaleByBranch,
  updateCart,
  getProductVariantDetail,
  getPriceLevel,
  createPriceLevel,
  getInvoiceProcess,
  getWorkOrdersProcess,
  exportEInvoice,
  findIncomeExpenseElement,
  createIncomeExpenseElement,
  createManufactureCost,
  workProcessProduct,
  workOrderExternalCost,
  volumeMaterialsUsed,
  workProcessMaterial,
  volumeProductCompleted,
  workOrderProcessByDate,
  workOrderProcessMaterialDetail,
  workProcessProductQty,
  workOrderProcessProductDetail,
  createCustomerCompany,
  findCustomerCompany,
  findSearchHistory,
  createSaleTarget,
  createSaleTargetGroup,
  findSalesTarget,
  findSalesTargetGroup,
  createDepartment,
  findDepartment,
  createAssetType,
  createAsset,
  findAssetType,
  findAsset,
  customerDebtReport,
  personInChargeReport,
  branchesDebtReport,
  saleProductByChannels,
  saleProductByStock,
  productInventoryByStock,
  findEmployeeFundType,
  findEmployeeFund,
  createEmployeeFundType,
  createEmployeeFund,

  //
  findCustomerCareType,
  findCustomerCare,
  createCustomerCareType,
  createCustomerCare,

  //shopee
  getCategory,
  getCategoryRecommend,
  getAttributes,
  getAttributesRecommend,
  getBrands,
  getShippingLimit,
  getChannelShipping,
  syncProductToApp,
  getShopIds,
  getShopInfo,
  findNotice,
  createNotice,
  createCampaign,
  findCampaign,
  findContracts,
  updateContract,
  findDrivers,
  createDrivers,
  findVehicle,
  createVehicle,
  findShippingBooking,
  createShippingBooking,
  createRoutes,
  findRoute,
} from "@/graphql/common";

import { apolloClient } from "@/graphql/graphql";
import gql from "graphql-tag";

export function changePointCustomer(companyId, data) {
  return apolloClient.mutate({
    mutation: updatePointCustomer,
    variables: {
      companyId,
      data,
    },
  });
}

export function customerCreated(customerInfo, branchId) {
  return apolloClient.mutate({
    mutation: createCustomer,
    variables: {
      customerRequest: customerInfo,
      branchId: branchId,
    },
  });
}

export function updateCustomer(customerInfo, branchId) {
  return apolloClient.mutate({
    mutation: updateCustomerInfo,
    variables: {
      customerRequest: customerInfo,
      branchId: branchId,
    },
  });
}

export function updateCustomerAdmin(data, companyId) {
  return apolloClient.mutate({
    mutation: updateCustomerForAdmin,
    variables: {
      data,
      companyId,
    },
  });
}

export function updateRoles(companyId, data) {
  return apolloClient.mutate({
    mutation: updateRoleDetails,
    variables: {
      companyId,
      data,
    },
  });
}

export function addNewRoles(companyId, name, description) {
  return apolloClient.mutate({
    mutation: createRole,
    variables: {
      companyId,
      name,
      description,
    },
  });
}

export function newUserBranches(userId, branchId, companyId) {
  return apolloClient.mutate({
    mutation: createUserBranches,
    variables: {
      userId,
      branchId,
      companyId,
    },
  });
}

export function updateUserRole(companyId, userBranchId, roleIds) {
  return apolloClient.mutate({
    mutation: updateUserBranchRole,
    variables: {
      companyId,
      userBranchId,
      roleIds,
    },
  });
}

export function createNewEmployee(companyId, data) {
  return apolloClient.mutate({
    mutation: createEmployee,
    variables: {
      companyId,
      data,
    },
  });
}

export function editEmployee(companyId, data) {
  return apolloClient.mutate({
    mutation: updateEmployee,
    variables: {
      companyId,
      data,
    },
  });
}

export function createOne(model, data) {
  return apolloClient.mutate({
    mutation: gql`
            mutation createOne( $data: Json) {
                data:createOne( data: $data){
                id
                ... on ${model}{
                    id
                }
                }
            }`,
    variables: {
      data,
    },
  });
}

export function updateOne(model, data) {
  return apolloClient.mutate({
    mutation: gql`
            mutation updateOne( $data: Json) {
                data:updateOne( data: $data){
                id
                ... on ${model}{
                    id
                }
                }
            }`,
    variables: {
      data,
    },
  });
}
export function deleteOne(model, id) {
  return apolloClient.mutate({
    mutation: gql`
            mutation deleteOne( $id: ID) {
                data:deleteOne( id: $id){
                id
                ... on ${model}{
                    id
                }
                }
            }`,
    variables: {
      id,
    },
  });
}

export function createNewBranch(req, companyId) {
  return apolloClient.mutate({
    mutation: createBrand,
    variables: {
      req,
      companyId,
    },
  });
}

export function createNewUser(userName, password, branchId, companyId) {
  return apolloClient.mutate({
    mutation: createUser,
    variables: {
      userName,
      password,
      branchId,
      companyId,
    },
  });
}

export function setStock(id, data) {
  return apolloClient.mutate({
    mutation: updateStock,
    variables: {
      id,
      data,
    },
  });
}

export function setStockDefault(stockId, branchId) {
  return apolloClient.mutate({
    mutation: updateStockDefault,
    variables: {
      stockId,
      branchId,
    },
  });
}

export function createNewProduct(data) {
  return apolloClient
    .mutate({
      mutation: createProduct,
      variables: {
        data,
      },
    })
    .then((res) => res.data.data.data);
}

export function createNewProducts(data) {
  return apolloClient.mutate({
    mutation: createMultiProduct,
    variables: {
      data,
    },
  });
}

export function findCustomer(code, branchId) {
  return apolloClient.query({
    query: findCustomerByCode,
    variables: {
      code,
      branchId,
    },
    fetchPolicy: "no-cache",
  });
}

export function getOrder(invoiceId, branchId) {
  return apolloClient
    .query({
      query: findOrder,
      variables: {
        invoiceId,
        branchId,
      },
      fetchPolicy: "no-cache",
    })
    .then((data) => data.data.data);
}
export function findBranch() {
  return apolloClient.query({
    query: findBranchOfUser,
    fetchPolicy: "no-cache",
    context: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  });
}

export function getCardsByBranch(branchId) {
  return apolloClient.query({
    query: findCardsByBranch,
    fetchPolicy: "no-cache",
    context: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
    variables: { branchId },
  });
}

export function getProductsSelect(
  where,
  limit = 100,
  skip = 0,
  orderBy = "id_desc"
  // isStockCompany
) {
  const branchId = where["branchId"];
  delete where["branchId"];

  return apolloClient
    .query({
      query: findProductsSelect,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getProducts(
  where,
  limit = 100,
  skip = 0,
  orderBy = "id_desc",
  isStockCompany
) {
  const branchId = where["branchId"];
  const haveBOM = where["haveBOM"];
  delete where["branchId"];
  delete where["haveBOM"];

  var q = findProducts;
  if (isStockCompany) {
    q = findProductsAvailable;
  }
  if (haveBOM) {
    q = findProductsHaveBom;
  }
  return apolloClient
    .query({
      query: q,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getIncomeExpensePayment(
  where,
  limit = 100,
  skip = 0,
  orderBy = "id_desc"
) {
  const branchId = where["branchId"];
  delete where["branchId"];
  return apolloClient
    .query({
      query: findIncomeExpensePayment,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getIncomeExpensePaymentDetail(
  where,
  limit = 100,
  skip = 0,
  orderBy = "id_desc"
) {
  const branchId = where["branchId"];
  delete where["branchId"];
  return apolloClient
    .query({
      query: findIncomeExpensePaymentDetail,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getMiddleGroup(companyId, limit = 10000) {
  return apolloClient.query({
    query: findMiddleGroup,
    fetchPolicy: "no-cache",
    variables: {
      companyId,
      limit,
    },
  });
}

export function getInvoiceAreasByBranch(
  branchId,
  where,
  skip = 0,
  limit = 100,
  orderBy = "id_desc"
) {
  return apolloClient.query({
    query: findInvoiceAreasByBranch,
    fetchPolicy: "no-cache",
    variables: {
      branchId,
      where,
      limit,
      skip,
      orderBy,
    },
  });
}

export function getDiscountByCode(branchId, discountCode) {
  return apolloClient.query({
    query: findDiscountByCode,
    fetchPolicy: "no-cache",
    variables: {
      branchId,
      discountCode,
    },
  });
}

export function getEmployee(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findEmployee,
      fetchPolicy: "no-cache",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function findProductSupplier(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: getProductSupplier,
      fetchPolicy: "no-cache",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getEmployeeBranch(
  branchId,
  where,
  orderBy = "",
  limit = 25,
  skip = 0
) {
  return apolloClient
    .query({
      query: findEmployeeBranch,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}
export function getExchangeRatesByBranch(
  branchId,
  where,
  orderBy = "",
  limit = 25,
  skip = 0
) {
  return apolloClient
    .query({
      query: findExchangeRatesByBranch,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((res) => res.data.data);
}
export function getInvoices(
  branchId,
  where,
  orderBy = "",
  limit = 25,
  skip = 0
) {
  return apolloClient
    .query({
      query: findInvoices,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getInvoicesSummaryAdmin(
  where,
  limit = 25,
  skip = 0,
  orderBy = ""
) {
  const branchId = where["branchId"];
  const branchIds = where["branchIds"];
  delete where["branchId"];
  delete where["branchIds"];
  const _isDetail = where["_isDetail"];
  delete where["_isDetail"];
  if (branchIds) {
    return apolloClient
      .query({
        query: _isDetail
          ? findInvoicesBranchForAdminDetail
          : findInvoicesBranchForAdmin,
        fetchPolicy: "no-cache",
        variables: {
          branchIds,
          where,
          limit,
          skip,
          orderBy,
        },
      })
      .then((res) => res.data.data);
  }
  return apolloClient
    .query({
      query: findInvoicesSummary,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getInvoicesSummary(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const branchId = where.branchId;
  delete where.branchId;
  return apolloClient
    .query({
      query: findInvoicesSummary,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getInvoicesProcessing(
  branchId,
  where,
  orderBy = "",
  limit = 25,
  skip = 0
) {
  return apolloClient
    .query({
      query: findInvoicesProcessing,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function saveNewOrder(data, incomeData = []) {
  return apolloClient.mutate({
    mutation: saveOrder,
    variables: {
      data,
      incomeData,
    },
  });
}

export function createQRPaymentOrder(data, branchId = []) {
  return apolloClient.mutate({
    mutation: createQRPayment,
    variables: {
      branchId,
      data,
    },
  });
}

export function inquiryQRPayment(branchId, invoiceId) {
  return apolloClient
    .query({
      query: inquiryQRPaymentStatus,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        invoiceId,
      },
    })
    .then((res) => res.data.data);
}
// export function updateOrder(data = [], incomeData = []) {
//   return apolloClient
//     .mutate({
//       mutation: completeOrder,
//       variables: {
//         data,
//         incomeData,
//       },
//     })
//     .then((res) => res.data.data);
// }

export function createExchange(data) {
  return apolloClient
    .mutate({
      mutation: createExchangeRates,
      variables: {
        data,
      },
    })
    .then((res) => res.data.data);
}

export function updateBranchData(companyId, data) {
  return apolloClient
    .mutate({
      mutation: updateBranches,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function updateExchange(data) {
  return apolloClient
    .mutate({
      mutation: updateExchangeRates,
      variables: {
        data,
      },
    })
    .then((res) => res.data.data);
}

export function deleteExchange(branchId, id) {
  return apolloClient
    .mutate({
      mutation: deleteExchangeRate,
      variables: {
        branchId,
        id,
      },
    })
    .then((res) => res.data.data);
}

export function createStock(data) {
  return apolloClient
    .mutate({
      mutation: createStockBranch,
      variables: {
        data,
      },
    })
    .then((res) => res.data.data);
}

export function newCard(cardNumber, branchId) {
  return apolloClient
    .mutate({
      mutation: createCard,
      variables: { cardNumber, branchId },
    })
    .then((res) => res.data.data);
}

export function editCard(cardNumber, branchId, cardId) {
  return apolloClient
    .mutate({
      mutation: updateCard,
      variables: { cardNumber, branchId, cardId },
    })
    .then((res) => res.data.data);
}

export function removeCard(branchId, cardId) {
  return apolloClient
    .mutate({
      mutation: deleteCard,
      variables: { branchId, cardId },
    })
    .then((res) => res.data.data);
}

export function removeRole(branchId, roleId, userBranchId) {
  return apolloClient
    .mutate({
      mutation: removeUserRole,
      variables: { branchId, roleId, userBranchId },
    })
    .then((res) => res.data.data);
}

export function removeUserBranchAPI(branchId, userId) {
  return apolloClient
    .mutate({
      mutation: removeUserBranch,
      variables: { branchId, userId },
    })
    .then((res) => res.data.data);
}

export function newInvoiceArea(branchId, name) {
  return apolloClient
    .mutate({
      mutation: createInvoiceArea,
      variables: { branchId, name },
    })
    .then((res) => res.data.data);
}

export function editInvoiceArea(branchId, name, id) {
  return apolloClient
    .mutate({
      mutation: updateInvoiceArea,
      variables: { branchId, name, id },
    })
    .then((res) => res.data.data);
}

export function removeInvoiceArea(branchId, id) {
  return apolloClient
    .mutate({
      mutation: deleteInvoiceArea,
      variables: { branchId, id },
    })
    .then((res) => res.data.data);
}

export function removeEmployeeFromBranch(branchId, id) {
  return apolloClient
    .mutate({
      mutation: removeEmployeeBranch,
      variables: { branchId, id },
    })
    .then((res) => res.data.data);
}

export function newInvoiceLocation(branchId, name, invoiceAreaId) {
  return apolloClient
    .mutate({
      mutation: createInvoiceLocation,
      variables: { branchId, name, invoiceAreaId },
    })
    .then((res) => res.data.data);
}

export function editInvoiceLocation(
  branchId,
  name,
  invoiceAreaId,
  invoiceLocationId
) {
  return apolloClient
    .mutate({
      mutation: updateInvoiceLocation,
      variables: { branchId, name, invoiceLocationId, invoiceAreaId },
    })
    .then((res) => res.data.data);
}

export function removeInvoiceLocation(branchId, invoiceLocationId) {
  return apolloClient
    .mutate({
      mutation: deleteInvoiceLocation,
      variables: { branchId, invoiceLocationId },
    })
    .then((res) => res.data.data);
}

export function removeOrder(branchId, invoiceId) {
  return apolloClient.mutate({
    mutation: cancelOrder,
    variables: {
      invoiceId,
      branchId,
    },
  });
}

export function deleteOrderAPI(branchId, invoiceId, incomeData) {
  return apolloClient.mutate({
    mutation: deleteOrder,
    variables: {
      invoiceId,
      branchId,
      incomeData,
    },
  });
}

export function addEmployeeToBranch(branchId, employeeId) {
  return apolloClient.mutate({
    mutation: addEmployeeBranch,
    variables: {
      branchId,
      employeeId,
    },
  });
}

export function getInvoiceSummaries(branchIds, where) {
  return apolloClient
    .query({
      query: invoiceSummaries,
      fetchPolicy: "no-cache",
      variables: { branchIds, where },
    })
    .then((res) => res.data.data);
}

export function checkInStock(branchId, prodId) {
  return apolloClient.query({
    query: productInventoryCheck,
    fetchPolicy: "no-cache",
    variables: {
      branchId,
      prodId,
    },
  });
}

export function checkProductByStockId(branchId, prodId, stockId) {
  return apolloClient.query({
    query: productInventoryCheckByStock,
    fetchPolicy: "no-cache",
    variables: {
      branchId,
      prodId,
      stockId,
    },
  });
}

export function getCustomers(where, limit = 25, skip = 0, orderBy = "id_desc") {
  const companyId = where["companies.id"];
  return apolloClient
    .query({
      query: findCustomers,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
        companyId,
      },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((res) => res.data.data);
}

export function getCustomerGroups(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const companyId = where["companies.id"];
  return apolloClient
    .query({
      query: getCustomerGroup,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
        companyId,
      },
    })
    .then((res) => res.data.data);
}

export function getProductList(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const branchId = where["branchId"];
  delete where["branchId"];
  return apolloClient
    .query({
      query: findProductList,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function findProductVariant(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc",
  isProducts
) {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: isProducts ? getProductVariantDetail : getProductVariant,
      fetchPolicy: "no-cache",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function findSuppliers(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findPaginationSupplierByCompany,
      fetchPolicy: "no-cache",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getOrders(where, limit = 25, skip = 0, orderBy = "id_desc") {
  return apolloClient
    .query({
      query: findOrders,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getUsers(where, limit = 25, skip = 0, orderBy = "id_desc") {
  return apolloClient
    .query({
      query: findUsers,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getRoles(where, limit = 25, skip = 0, orderBy = "id_desc") {
  const companyId = where["companies.id"];
  delete where["companies.id"];
  return apolloClient
    .query({
      query: findRoles,
      fetchPolicy: "no-cache",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getRolesbyAdmin(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  return apolloClient
    .query({
      query: findRolesByAdmin,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getRoleDetailsAdmin(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  return apolloClient
    .query({
      query: findRoleDetailsAdmin,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getRoleDetails(where, roleId, companyId) {
  return apolloClient
    .query({
      query: findRoleDetails,
      fetchPolicy: "no-cache",
      variables: {
        roleId,
        companyId,
        where,
      },
    })
    .then((data) => data.data.data);
}

export function getCompanies(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc",
  includeInactive = false
) {
  return apolloClient
    .query({
      query: findCompanies,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
        includeInactive,
      },
    })
    .then((data) => data.data.data);
}

export function getBranches(where, limit = 25, skip = 0, orderBy = "id_desc") {
  return apolloClient
    .query({
      query: findBranches,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getCustomerOrderHistory(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const companyId = where.companyId;
  delete where.companyId;
  return apolloClient
    .query({
      query: findCustomerOrderHistory,
      fetchPolicy: "no-cache",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getBranchByCompany(companyId) {
  return apolloClient
    .query({
      query: findBranchByCompany,
      fetchPolicy: "no-cache",
      variables: {
        companyId,
      },
    })
    .then((data) => data.data.data);
}

export function getUserBranches(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  return apolloClient
    .query({
      query: findUserBranches,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data);
}

export function getCardTypes(branchId, where) {
  return apolloClient
    .query({
      query: findCardTypes,
      fetchPolicy: "no-cache",
      variables: {
        where,
        branchId,
      },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((data) => data.data);
}

export function newCardType(typeName, isDirect = false, branchId) {
  return apolloClient.mutate({
    mutation: createCardType,
    variables: {
      branchId,
      typeName,
      isDirect,
    },
  });
}

export function newCustomerGroup(data, companyId) {
  return apolloClient.mutate({
    mutation: createCustomerGroup,
    variables: {
      companyId,
      data,
    },
  });
}
export function newProductVariant(data, companyId) {
  return apolloClient.mutate({
    mutation: createProductVariant,
    variables: {
      companyId,
      data,
    },
  });
}

export function editProductVariant(id, data, companyId) {
  return apolloClient.mutate({
    mutation: updateProductVariant,
    variables: {
      id,
      companyId,
      data,
    },
  });
}

export function editCustomerGroup(id, data, companyId) {
  return apolloClient.mutate({
    mutation: updateCustomerGroup,
    variables: {
      companyId,
      data,
      id,
    },
  });
}

export function editCardType(typeName, isDirect, branchId, id) {
  return apolloClient.mutate({
    mutation: updateCardType,
    variables: {
      branchId,
      typeName,
      id,
      isDirect,
    },
  });
}

export async function getProductInventory(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const branchId = where["branchId"] || [];
  const stockIds = where["stockIds"] || [];
  const batchNames = where["batchNames"] || [];

  delete where["branchId"];
  delete where["batchNames"];
  delete where["productIds"];
  delete where["stockIds"];

  const productData = await apolloClient.query({
    query: findProductList,
    fetchPolicy: "no-cache",
    variables: {
      branchId,
      where: {
        ...where,
        noCheckWarehouse: false,
        isBundle: false,
      },
      limit,
      skip,
      orderBy,
    },
  });

  var outputs = [];
  outputs = productData.data.data;
  const productIds = productData.data.data.list.map((i) => i.id);

  var dataInventoryRaw = await apolloClient.query({
    query: findSmartInventory,
    fetchPolicy: "no-cache",
    variables: {
      stockIds,
      batchNames,
      productIds,
      branchId,
      where: {},
      limit: 100000,
      skip: 0,
      orderBy: "id_desc",
    },
  });
  var dataInventory = dataInventoryRaw.data.data.list;
  outputs.list = outputs.list.map((i) => {
    i.inventories = dataInventory.filter((d) => d.prdCode == i.code);
    return i;
  });

  return outputs;
}

export function getSmartInventory(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const stockIds = where["stockIds"] || [];
  const batchNames = where["batchNames"] || [];
  const productIds = where["productIds"] || [];
  const branchId = where["branchId"] || [];

  delete where["branchId"];
  delete where["batchNames"];
  delete where["productIds"];
  delete where["stockIds"];
  return apolloClient
    .query({
      query: findSmartInventory,
      fetchPolicy: "no-cache",
      variables: {
        stockIds,
        batchNames,
        productIds,
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getInventories(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  return apolloClient
    .query({
      query: findInventories,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}
export function getAreas(where, limit = 25, skip = 0, orderBy = "id_desc") {
  return apolloClient
    .query({
      query: findAreas,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getLargeGroups(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const companyId = where["companies.id"];
  return apolloClient
    .query({
      query: findLargeGroups,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        companyId,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getCategoriesProductsTree(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const companyId = where["companies.id"];
  return apolloClient
    .query({
      query: findCategoriesProductTree,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        companyId,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getCategoriesTree(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const companyId = where["companies.id"];
  return apolloClient
    .query({
      query: findCategoriesTree,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        companyId,
        orderBy,
      },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((data) => data.data.data);
}

export function getMiddleGroups(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const companyId = where["companies.id"];
  return apolloClient
    .query({
      query: findMiddleGroups,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
        companyId,
      },
    })
    .then((data) => data.data.data);
}

export function getSmallGroups(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const companyId = where["companies.id"];
  return apolloClient
    .query({
      query: findSmallGroups,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
        companyId,
      },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((data) => data.data.data);
}

export function getSuppliers(where) {
  const companyId = where["companies.id"];
  return apolloClient
    .query({
      query: findSupliers,
      fetchPolicy: "no-cache",
      variables: {
        where,
        companyId,
      },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((data) => data.data.data);
}
export function getWarehouses(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  return apolloClient
    .query({
      query: findWarehouse,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}
export function getDiscounts(where, limit = 25, skip = 0, orderBy = "id_desc") {
  const companyId = where.companyId;
  delete where.companyId;
  return apolloClient
    .query({
      query: findDiscounts,
      fetchPolicy: "no-cache",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getUsersCompany(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const companyId = where.companyId;
  delete where.companyId;
  return apolloClient
    .query({
      query: findUserByCompany,
      fetchPolicy: "no-cache",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getMembershipRank(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const companyId = where["companies.id"];
  delete where["companies.id"];
  return apolloClient
    .query({
      query: findMembershipRank,
      fetchPolicy: "no-cache",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((data) => data.data.data);
}

export function customerSaleByBranch(customerId, fromDate, toDate, branchIds) {
  return apolloClient
    .query({
      query: getCustomerSaleByBranch,
      fetchPolicy: "no-cache",
      variables: {
        customerId,
        fromDate,
        toDate,
        branchIds,
      },
    })
    .then((data) => data.data.data);
}

export function getProductUnits(where) {
  return apolloClient
    .query({
      query: findProductUnits,
      fetchPolicy: "no-cache",
      variables: {
        where,
      },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((data) => data.data.data);
}

export function getUser(id) {
  return apolloClient
    .query({
      query: findUser,
      fetchPolicy: "no-cache",
      variables: {
        id,
      },
    })
    .then((data) => data.data.data);
}

export function saveProduct(data) {
  return apolloClient.mutate({
    mutation: createProduct,
    variables: {
      data,
    },
  });
}

export function editCompany(data) {
  return apolloClient.mutate({
    mutation: updateCompany,
    variables: {
      data,
    },
  });
}
export function updateProductCost(data, companyId) {
  return apolloClient.mutate({
    mutation: updateProductCostPrice,
    variables: {
      data,
      companyId,
    },
  });
}

export function updatePricesProduct(productId, companyId, priceReqs) {
  return apolloClient.mutate({
    mutation: updateProductPrices,
    variables: {
      productId,
      companyId,
      priceReqs,
    },
  });
}

export function updateSaleProduct(companyId, data) {
  return apolloClient.mutate({
    mutation: updateProductSale,
    variables: {
      companyId,
      data,
    },
  });
}

export function newLargeGroup(
  companyId,
  largeGroupCode,
  largeGroupName,
  urlImage
) {
  return apolloClient.mutate({
    mutation: createLargeGroup,
    variables: {
      companyId,
      largeGroupName,
      largeGroupCode,
      urlImage,
    },
  });
}
export function newSmallGroup(data) {
  return apolloClient.mutate({
    mutation: createSmallGroup,
    variables: {
      data,
    },
  });
}

export function newMiddleGroup(
  companyId,
  middleGroupCode,
  middleGroupName,
  largeGroupId,
  urlImage
) {
  return apolloClient.mutate({
    mutation: createMiddleGroup,
    variables: {
      middleGroupCode,
      companyId,
      middleGroupName,
      largeGroupId,
      urlImage,
    },
  });
}

export function newDiscount(companyId, data) {
  return apolloClient.mutate({
    mutation: createDiscount,
    variables: {
      companyId,
      data,
    },
  });
}

export function editDiscount(companyId, data) {
  return apolloClient.mutate({
    mutation: updateDiscount,
    variables: {
      companyId,
      data,
    },
  });
}

export function removeDiscount(companyId, discountId) {
  return apolloClient.mutate({
    mutation: deleteDiscount,
    variables: {
      companyId,
      discountId,
    },
  });
}

export function editProduct(data) {
  return apolloClient
    .mutate({
      mutation: updateProduct,
      variables: {
        data,
      },
    })
    .then((res) => res.data);
}
export function changeBranch(branchId) {
  return apolloClient
    .mutate({
      mutation: updateUserBranchDefault,
      variables: {
        branchId,
      },
    })
    .then((res) => res.data);
}

export function transferWarehouse(data, incomeData) {
  return apolloClient
    .mutate({
      mutation: transferWarehouseApi,
      variables: {
        data,
        incomeData,
      },
    })
    .then((res) => res.data);
}

export function updateTransfer(data, incomeData) {
  return apolloClient
    .mutate({
      mutation: updateTransferWarehouse,
      variables: {
        data,
        incomeData,
      },
    })
    .then((res) => res.data);
}

export function completedTransfer(warehouseReceiptId, receiverBranchId) {
  return apolloClient
    .mutate({
      mutation: completedTransferWarehouse,
      variables: {
        warehouseReceiptId,
        receiverBranchId,
      },
    })
    .then((res) => res.data);
}

export function cancelTransfer(warehouseReceiptId, transferBranchId) {
  return apolloClient
    .mutate({
      mutation: cancelTransferWarehouse,
      variables: {
        warehouseReceiptId,
        transferBranchId,
      },
    })
    .then((res) => res.data);
}

export function importProduct(branchId, data, incomeData) {
  return apolloClient.mutate({
    mutation: productImport,
    variables: {
      branchId,
      data,
      incomeData,
    },
  });
}

export function productExport(branchId, dataReq, incomeData) {
  return apolloClient.mutate({
    mutation: exportProduct,
    variables: {
      branchId,
      dataReq,
      incomeData,
    },
  });
}

export function getCurrentUser(where = {}) {
  return apolloClient
    .query({
      query: findCurrentUser,
      fetchPolicy: "no-cache",
      variables: {
        where,
      },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((data) => data.data);
}

export function getProductInventoryStatus(branchId, prodId) {
  return apolloClient.query({
    query: productInventoryStatus,
    fetchPolicy: "no-cache",
    variables: {
      branchId,
      prodId,
    },
  });
}

export function getStocksBranch(branchId, where) {
  return apolloClient
    .query({
      query: findStocksBranch,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        where,
      },
    })
    .then((data) => data.data.data);
}

export function getTransferWarehouseList(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc",
  isDetail
) {
  const branchIds = where["branchIds"];
  delete where["branchIds"];
  return apolloClient
    .query({
      query: isDetail ? transferWarehouseDetail : transferWarehouseList,
      fetchPolicy: "no-cache",
      variables: {
        branchIds,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function getInventoriesByStockId(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const branchId = where["branchId"];
  const stockId = where["stockId"];
  delete where["stockId"];
  delete where["branchId"];
  return apolloClient
    .query({
      query: findInventoryByStockId,
      fetchPolicy: "no-cache",
      variables: {
        stockId,
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
}

export function newSupplier(data) {
  return apolloClient
    .mutate({
      mutation: createSupplier,
      variables: {
        data,
      },
    })
    .then((res) => res.data);
}

export function editSupplier(data) {
  return apolloClient
    .mutate({
      mutation: updateSupplier,
      variables: {
        data,
      },
    })
    .then((res) => res.data);
}

export function newProductUnit(req, companyId) {
  return apolloClient
    .mutate({
      mutation: createProductUnit,
      variables: {
        req,
        companyId,
      },
    })
    .then((res) => res.data);
}

export function editProductUnit(req, companyId) {
  return apolloClient
    .mutate({
      mutation: updateProductUnit,
      variables: {
        req,
        companyId,
      },
    })
    .then((res) => res.data);
}

export function newMembershipRank(data, companyId) {
  return apolloClient
    .mutate({
      mutation: createMembershipRank,
      variables: {
        data,
        companyId,
      },
    })
    .then((res) => res.data);
}

export function editMembershipRank(data, companyId) {
  return apolloClient
    .mutate({
      mutation: updateMembershipRank,
      variables: {
        data,
        companyId,
      },
    })
    .then((res) => res.data);
}

export function removeMembershipRank(rankId, companyId) {
  return apolloClient
    .mutate({
      mutation: deleteMembershipRank,
      variables: {
        rankId,
        companyId,
      },
    })
    .then((res) => res.data);
}

export const getInventoryReceivingList = (
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc",
  isDetail
) => {
  const branchIds = where["branchIds"];
  delete where["branchIds"];
  return apolloClient
    .query({
      query: isDetail ? warehouseInReceiptDetail : warehouseInReceipt,
      fetchPolicy: "no-cache",
      variables: {
        branchIds,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
};

export const getWarehouseOutReceipts = (
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc",
  isDetail
) => {
  const branchIds = where["branchIds"];
  delete where["branchIds"];
  return apolloClient
    .query({
      query: isDetail ? warehouseOutDetailReceipts : warehouseOutReceipts,
      fetchPolicy: "no-cache",
      variables: {
        branchIds,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((data) => data.data.data);
};

export const findInvoiceInfos = (branchId, data, where) => {
  return apolloClient
    .query({
      query: findInvoiceInfo,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
        data,
        where,
      },
    })
    .then((data) => data.data);
};

export const findUsersByBranch = (branchId) => {
  return apolloClient
    .query({
      query: findUserByBranch,
      fetchPolicy: "no-cache",
      variables: {
        branchId,
      },
      // context: {
      //     headers:{
      //         Gratikey: uniqueKey({branchId, name: 'findUsersByBranch' } )
      //     }
      // }
    })
    .then((data) => data.data);
};

export const getSalesReport = (fromDate, toDate, branchIds) => {
  return apolloClient
    .query({
      query: salesReport,
      fetchPolicy: "no-cache",
      variables: { fromDate, toDate, branchIds },
    })
    .then((data) => data.data);
};

export const getProductChannelReport = (fromDate, toDate, branchIds) => {
  return apolloClient
    .query({
      query: productChannelReport,
      fetchPolicy: "no-cache",
      variables: { fromDate, toDate, branchIds },
    })
    .then((data) => data.data);
};

export const getIncomeExpensePaymentReport = (fromDate, toDate, branchIds) => {
  return apolloClient
    .query({
      query: incomeExpensePaymentReport,
      fetchPolicy: "no-cache",
      variables: { fromDate, toDate, branchIds },
    })
    .then((data) => data.data);
};

export const changePassword = (companyId, oldPassword, newPassword) => {
  return apolloClient
    .mutate({
      mutation: changeUserPassword,
      variables: {
        companyId,
        oldPassword,
        newPassword,
      },
    })
    .then((data) => data.data);
};

export const resetPassword = (companyId, userName) => {
  return apolloClient
    .mutate({
      mutation: resetUserPassword,
      variables: { companyId, userName },
    })
    .then((data) => data.data);
};

export const resetPasswordCustomer = (companyId, userName) => {
  return apolloClient
    .mutate({
      mutation: resetPassUserB2B,
      variables: { companyId, userName },
    })
    .then((data) => data.data);
};

export const assignEmployeeToUser = (companyId, employeeId, userId) => {
  return apolloClient
    .mutate({
      mutation: updateEmployeeToUser,
      variables: { companyId, employeeId, userId },
    })
    .then((data) => data.data);
};

// export const importProductsExcel = (companyId, url) => {
//   return apolloClient
//     .mutate({
//       mutation: importExcelProducts,
//       variables: { companyId, url },
//     })
//     .then((data) => data.data);
// };

export const newMultiCustomers = (data, companyId) => {
  return apolloClient
    .mutate({
      mutation: createMultiCustomers,
      variables: { data, companyId },
    })
    .then((data) => data.data);
};

export const importWarehouseExcel = (branchId, url, description) => {
  return apolloClient
    .mutate({
      mutation: warehouseImportExcel,
      variables: { branchId, url, description },
    })
    .then((data) => data.data);
};

export const importTransferProducts = (
  url,
  transferBranchId,
  receiverBranchId,
  description
) => {
  return apolloClient
    .mutate({
      mutation: productTransferImport,
      variables: { url, transferBranchId, receiverBranchId, description },
    })
    .then((data) => data.data);
};
export const exportProductsExcels = (branchId, url, description, outType) => {
  return apolloClient
    .mutate({
      mutation: exportProductsExcel,
      variables: { branchId, url, description, outType },
    })
    .then((data) => data.data);
};

export const createAppConfigs = (keyName, keyValues, id, isBranch) => {
  return apolloClient
    .mutate({
      mutation: createAppConfig,
      variables: { keyName, keyValues, id, isBranch },
    })
    .then((data) => data.data);
};

export const orderReturn = (data, incomeData, orderRefId) => {
  return apolloClient
    .mutate({
      mutation: orderReturns,
      variables: { data, orderRefId, incomeData },
    })
    .then((data) => data.data);
};

export const getProductStockCard = (
  where,
  limit = 25,
  skip = 0,
  orderBy = ""
) => {
  // if (Object.keys(where).length === 0 && where.constructor === Object) return;
  const { productId, stockId, branchIds, fromDate, toDate } = where;
  delete where["productId"];
  delete where["stockId"];
  delete where["branchIds"];
  delete where["fromDate"];
  delete where["toDate"];
  return apolloClient
    .query({
      query: productStockCard,
      fetchPolicy: "no-cache",
      variables: {
        where,
        orderBy,
        limit,
        skip,
        productId,
        stockId,
        branchIds,
        fromDate,
        toDate,
      },
    })
    .then((data) => data.data.data);
};

export const getSupplierSalesReport = (
  branchIds,
  fromDate,
  toDate,
  where = {},
  orderBy = ""
) => {
  return apolloClient
    .query({
      query: supplierSalesReport,
      fetchPolicy: "no-cache",
      variables: {
        orderBy,
        where,
        branchIds,
        fromDate,
        toDate,
      },
    })
    .then((data) => data.data.data);
};

export const getAllCircularCategory = (
  where,
  limit = 10,
  skip = 0,
  orderBy = ""
) => {
  return apolloClient
    .query({
      query: findAllCircularCategory,
      fetchPolicy: "no-cache",
      variables: {
        where,
        limit,
        orderBy,
        skip,
      },
    })
    .then((data) => data.data.data);
};

export const getRevenueDetailReport = (branchIds, fromDate, toDate) => {
  return apolloClient
    .query({
      query: revenueDetailReport,
      fetchPolicy: "no-cache",
      variables: {
        branchIds,
        fromDate,
        toDate,
      },
    })
    .then((data) => data.data.data);
};

export const getSummaryReport = (companyId) => {
  return apolloClient
    .query({
      query: summaryReport,
      fetchPolicy: "no-cache",
      variables: { companyId },
    })
    .then((data) => data.data.data);
};

export const outClockShiftSalesReport = (branchId, userReceiverId) => {
  return apolloClient
    .mutate({
      mutation: outClockShiftSales,
      variables: {
        branchId,
        userReceiverId,
      },
    })
    .then((data) => data.data);
};

export const getShiftSalesData = (branchId) => {
  return apolloClient
    .query({
      query: getShiftSales,
      fetchPolicy: "no-cache",
      variables: { branchId },
    })
    .then((data) => data.data);
};

export const getShiftSaleReportList = (
  where,
  limit = 10,
  skip = 0,
  orderBy = ""
) => {
  let branchId = where["branchId"];
  let fromDate = where["fromDate"];
  let toDate = where["toDate"];
  delete where["branchId"];
  delete where["fromDate"];
  delete where["toDate"];
  return apolloClient
    .query({
      query: findShiftSalesReport,
      fetchPolicy: "no-cache",
      variables: { branchId, fromDate, toDate, where, orderBy, limit, skip },
    })
    .then((data) => data.data.data);
  // }).then(data => {
  // let list = data.data.data.list.map((item, i) => {
  //     return {
  //         ...item,
  //         index: i + 1
  //     }
  // })
  // return {
  //     list: list
  // }
  // });
};

export const updateProductList = (data = []) => {
  return apolloClient
    .mutate({
      mutation: updateMultiProducts,
      variables: {
        data,
      },
    })
    .then((data) => data.data);
};

export const paymentInvoice = (data, incomeData, branchId) => {
  return apolloClient
    .mutate({
      mutation: paymentInvoiceDebt,
      variables: {
        data,
        incomeData,
        branchId,
      },
    })
    .then((data) => data.data);
};

export const getGroupSalesReport = (
  where = {},
  orderBy = "",
  branchIds,
  fromDate,
  toDate
) => {
  return apolloClient
    .query({
      query: groupSalesReport,
      fetchPolicy: "no-cache",
      variables: {
        where,
        orderBy,
        branchIds,
        fromDate,
        toDate,
      },
    })
    .then((data) => data.data.data);
};

export const getProductSalesTopReport = (
  branchIds,
  fromDate,
  toDate,
  limit = 10
) => {
  return apolloClient
    .query({
      query: productSalesTopReport,
      fetchPolicy: "no-cache",
      variables: { branchIds, fromDate, toDate, limit },
    })
    .then((data) => data.data.data);
};

export const changeProductConfig = (data) => {
  return apolloClient
    .mutate({
      mutation: createProductConfig,
      variables: {
        data,
      },
    })
    .then((data) => data.data);
};

export const getIncomeExpense = (where, limit = 10, skip = 0, orderBy = "") => {
  let branchId = where["branchId"];
  delete where["branchId"];
  return apolloClient
    .query({
      query: findIncomeExpense,
      fetchPolicy: "no-cache",
      variables: { branchId, where, orderBy, limit, skip },
    })
    .then((data) => data.data.data);
};

export const removeIncomeExpense = (id, branchId) => {
  return apolloClient
    .mutate({
      mutation: deleteIncomeExpense,
      variables: {
        id,
        branchId,
      },
    })
    .then((data) => data.data);
};

export const getPurchaseOrders = (
  where,
  limit = 10,
  skip = 0,
  orderBy = "",
  isDetail = false
) => {
  let branches = where["branchIds"];
  delete where["branchIds"];
  return apolloClient
    .query({
      query: isDetail ? findPurchaseOrderDetail : findPurchaseOrders,
      fetchPolicy: "no-cache",
      variables: { branches, where, orderBy, limit, skip },
    })
    .then((data) => data.data.data);
};

export const getPurchaseOrderDetail = (
  where,
  limit = 10,
  skip = 0,
  orderBy = ""
) => {
  let branches = where["branchIds"];
  delete where["branchIds"];
  return apolloClient
    .query({
      query: findPurchaseOrderDetail,
      fetchPolicy: "no-cache",
      variables: { branches, where, orderBy, limit, skip },
    })
    .then((data) => data.data.data);
};

export const findIncomeExpenseSummary = (fromDate, toDate, branches) => {
  return apolloClient
    .query({
      query: incomeExpenseSummary,
      fetchPolicy: "no-cache",
      variables: { fromDate, toDate, branches },
    })
    .then((data) => data.data.data.data);
};

export const findConfigAutoFinance = (branchId, orderBy = "") => {
  const where = { "branches.id": branchId };
  return apolloClient
    .query({
      query: getConfigAutoFinance,
      fetchPolicy: "no-cache",
      variables: { where, orderBy },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((data) => data.data.data);
};

export const getMinQuantityWarning = (
  where,
  limit = 10,
  skip = 0,
  orderBy = ""
) => {
  let branchIds = where["branchIds"];
  delete where["branchIds"];
  return apolloClient
    .query({
      query: findMinQuantityWarning,
      fetchPolicy: "no-cache",
      variables: { branchIds, where, orderBy, limit, skip },
    })
    .then((data) => data.data.data);
};

export const getOutOfStockByBranch = (
  where,
  limit = 10,
  skip = 0,
  orderBy = ""
) => {
  let branchIds = where["branchIds"];
  delete where["branchIds"];
  return apolloClient
    .query({
      query: findOutOfStockByBranch,
      fetchPolicy: "no-cache",
      variables: { branchIds, where, orderBy, limit, skip },
    })
    .then((data) => data.data.data);
};
export const getProfitProductTopReport = (
  branchIds,
  fromDate,
  toDate,
  limit = 10
) => {
  return apolloClient
    .query({
      query: profitProductTopReport,
      fetchPolicy: "no-cache",
      variables: { branchIds, fromDate, toDate, limit },
    })
    .then((data) => data.data.data);
};

export const findCircularCategory = (where = {}, orderBy = "code") => {
  return apolloClient
    .query({
      query: getCircularCategory,
      fetchPolicy: "no-cache",
      variables: { orderBy, where },
    })
    .then((data) => data.data.data);
};

export const findCompanyCircularCategory = (where = {}, orderBy = "code") => {
  return apolloClient
    .query({
      query: getCompanyCircularCategory,
      fetchPolicy: "no-cache",
      variables: { orderBy, where },
    })
    .then((data) => data.data.data);
};

export const findBranchCircularCategory = (where = {}, orderBy = "") => {
  return apolloClient
    .query({
      query: getBranchCircularCategory,
      fetchPolicy: "no-cache",
      variables: { orderBy, where },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((data) => data.data.data);
};

export const newCompanyCircularCategory = (data, companyId) => {
  return apolloClient
    .mutate({
      mutation: createCompanyCircularCategory,
      variables: {
        data,
        companyId,
      },
    })
    .then((data) => data.data);
};

export const editCompanyCircularCategory = (data, companyId, id) => {
  return apolloClient
    .mutate({
      mutation: updateCompanyCircularCategory,
      variables: {
        data,
        companyId,
        id,
      },
    })
    .then((data) => data.data);
};

export const removeCompanyCircularCategory = (companyId, id) => {
  return apolloClient
    .mutate({
      mutation: deleteCompanyCircularCategory,
      variables: {
        companyId,
        id,
      },
    })
    .then((data) => data.data);
};

export const newBranchCircularCategory = (data) => {
  return apolloClient
    .mutate({
      mutation: createBranchCircularCategory,
      variables: {
        data,
      },
    })
    .then((data) => data.data);
};

export const newPurchaseOrder = (data, incomeExpenseReqs, branchId) => {
  return apolloClient
    .mutate({
      mutation: createPurchaseOrder,
      variables: {
        data,
        branchId,
        incomeExpenseReqs,
      },
    })
    .then((data) => data.data);
};

export const editPurchaseOrder = (poId, data, branchId) => {
  return apolloClient
    .mutate({
      mutation: updatePurchaseOrder,
      variables: {
        data,
        branchId,
        poId,
      },
    })
    .then((data) => data.data);
};

export const editBranchCircularCategory = (data, id) => {
  return apolloClient
    .mutate({
      mutation: updateBranchCircularCategory,
      variables: {
        data,
        id,
      },
    })
    .then((data) => data.data);
};

export const removeBranchCircularCategory = (branchId, id) => {
  return apolloClient
    .mutate({
      mutation: deleteBranchCircularCategory,
      variables: {
        branchId,
        id,
      },
    })
    .then((data) => data.data);
};

export const newIncomeExpense = (data, branchId) => {
  return apolloClient
    .mutate({
      mutation: createIncomeExpense,
      variables: {
        data,
        branchId,
      },
    })
    .then((data) => data.data);
};

export const editIncomeExpense = (id, data, branchId) => {
  return apolloClient
    .mutate({
      mutation: updateIncomeExpense,
      variables: {
        id,
        data,
        branchId,
      },
    })
    .then((data) => data.data);
};

export const editConfigAutoFinance = (data, branchId) => {
  return apolloClient
    .mutate({
      mutation: updateConfigAutoFinance,
      variables: {
        data,
        branchId,
      },
    })
    .then((data) => data.data);
};

export const editConfigAutoFinanceMulti = (data, branchId) => {
  return apolloClient
    .mutate({
      mutation: updateConfigAutoFinanceMulti,
      variables: {
        data,
        branchId,
      },
    })
    .then((data) => data.data);
};

export const newConfigAutoFinance = (data, branchId) => {
  return apolloClient
    .mutate({
      mutation: createConfigAutoFinance,
      variables: {
        data,
        branchId,
      },
    })
    .then((data) => data.data);
};

export const removeConfigAutoFinance = (id, branchId) => {
  return apolloClient
    .mutate({
      mutation: deleteConfigAutoFinance,
      variables: {
        id,
        branchId,
      },
    })
    .then((data) => data.data);
};

export const paymentPO = (data, branchId, incomeExpenseReqs) => {
  return apolloClient
    .mutate({
      mutation: paymentPurchaseOrder,
      variables: {
        data,
        branchId,
        incomeExpenseReqs,
      },
    })
    .then((data) => data.data);
};

export const getRevenueSaleByDate = (
  fromDate = "",
  toDate = "",
  branches = []
) => {
  return apolloClient
    .query({
      query: revenueSaleByDate,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branches,
      },
    })
    .then((data) => data.data.data);
};

export const getRevenueCostSaleByDate = (
  fromDate = "",
  toDate = "",
  branches = []
) => {
  return apolloClient
    .query({
      query: revenueCostSaleByDate,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branches,
      },
    })
    .then((data) => data.data.data);
};

export const getWarehouseInReport = (
  fromDate = "",
  toDate = "",
  branches = []
) => {
  return apolloClient
    .query({
      query: warehouseInReport,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branches,
      },
    })
    .then((data) => data.data.data);
};

export const getWarehouseOutReport = (
  fromDate = "",
  toDate = "",
  branches = []
) => {
  return apolloClient
    .query({
      query: warehouseOutReport,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branches,
      },
    })
    .then((data) => data.data.data);
};

export const getInventoryReport = (
  fromDate = "",
  toDate = "",
  branches = []
) => {
  return apolloClient
    .query({
      query: inventoryReport,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branches,
      },
    })
    .then((data) => data.data.data);
};

export const getGroupInventory = (
  branchIds = [],
  where = {},
  orderBy = "",
  limit = 10,
  skip = 0
) => {
  return apolloClient
    .query({
      query: findGroupInventory,
      fetchPolicy: "no-cache",
      variables: {
        branchIds,
        where,
        orderBy,
        limit,
        skip,
      },
    })
    .then((data) => data.data.data);
};

export const summaryOrderCount = (
  fromDate = "",
  toDate = "",
  branchIds = []
) => {
  return apolloClient
    .query({
      query: summaryReportByRange,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branchIds,
      },
    })
    .then((data) => data.data.data);
};

export const getRevenueSaleByBranch = (
  fromDate = "",
  toDate = "",
  branchIds = []
) => {
  return apolloClient
    .query({
      query: revenueSaleByBranch,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branchIds,
      },
    })
    .then((res) => res.data);
};

export const getPurchaseOrderDetailReport = (
  fromDate = "",
  toDate = "",
  branchIds = []
) => {
  return apolloClient
    .query({
      query: purchaseOrderDetailReport,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branchIds,
      },
    })
    .then((res) => res.data.data);
};

export const getTransferProductReport = (
  fromDate = "",
  toDate = "",
  branchIds = []
) => {
  return apolloClient
    .query({
      query: transferProductReport,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branchIds,
      },
    })
    .then((res) => res.data.data);
};

export const getSaleChannelReport = (
  fromDate = "",
  toDate = "",
  branchIds = []
) => {
  return apolloClient
    .query({
      query: saleChannelReport,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branchIds,
      },
    })
    .then((res) => res.data);
};

export const editSmallGroup = (id, data) => {
  return apolloClient
    .mutate({
      mutation: updateSmallGroup,
      variables: {
        id,
        data,
      },
    })
    .then((res) => res.data.data);
};
export const editMiddleGroup = (companyId, id, code, name, urlImage) => {
  return apolloClient
    .mutate({
      mutation: updateMiddleGroup,
      variables: {
        companyId,
        id,
        code,
        name,
        urlImage,
      },
    })
    .then((res) => res.data.data);
};
export const editLargeGroup = (companyId, id, code, name, urlImage) => {
  return apolloClient
    .mutate({
      mutation: updateLargeGroup,
      variables: {
        companyId,
        id,
        code,
        name,
        urlImage,
      },
    })
    .then((res) => res.data.data);
};

export const getPurchaseOrderSummaryBySupplier = (
  fromDate = "",
  toDate = "",
  branchIds = [],
  supplierId = ""
) => {
  return apolloClient
    .query({
      query: purchaseOrderSummaryBySupplier,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branchIds,
        supplierId,
      },
    })
    .then((res) => res.data.data);
};

export const getWarehouseInBySupplierDetail = (
  fromDate = "",
  toDate = "",
  branchIds = [],
  supplierId = ""
) => {
  return apolloClient
    .query({
      query: warehouseInBySupplierDetail,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branchIds,
        supplierId,
      },
    })
    .then((res) => res.data.data);
};

export const getPurchaseOrderSupplierReport = (
  fromDate = "",
  toDate = "",
  branchIds = []
) => {
  return apolloClient
    .query({
      query: purchaseOrderSupplierReport,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branchIds,
      },
    })
    .then((res) => res.data.data);
};

export const getPermissionSchemas = () => {
  return apolloClient
    .query({
      query: getPermissionSchema,
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
};

export const getIncomeExpenseManualReport = (
  fromDate = "",
  toDate = "",
  branchIds = []
) => {
  return apolloClient
    .query({
      query: incomeExpenseManualReport,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branchIds,
      },
    })
    .then((res) => res.data.data);
};

export const getSaleChannelReportTop = (
  fromDate = "",
  toDate = "",
  branchIds = [],
  limit = 10
) => {
  return apolloClient
    .query({
      query: saleChannelReportTop,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branchIds,
        limit,
      },
    })
    .then((res) => res.data.data);
};

export const findTopCustomer = (
  fromDate = "",
  toDate = "",
  branchIds = [],
  limit = 10
) => {
  return apolloClient
    .query({
      query: getTopCustomer,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branchIds,
        limit,
      },
    })
    .then((res) => res.data.data);
};

export const getTopCustomerGroup = (
  fromDate = "",
  toDate = "",
  branchIds = [],
  limit = 10
) => {
  return apolloClient
    .query({
      query: getTopCustomerGroupBuyProduct,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branchIds,
        limit,
      },
    })
    .then((res) => res.data.data);
};

export const getTopCustomers = (
  fromDate = "",
  toDate = "",
  branchIds = [],
  limit = 10
) => {
  return apolloClient
    .query({
      query: getTopCustomerBuyProduct,
      fetchPolicy: "no-cache",
      variables: {
        fromDate,
        toDate,
        branchIds,
        limit,
      },
    })
    .then((res) => res.data);
};

export const getChannels = (companyId) => {
  return apolloClient
    .query({
      query: findChannels,
      fetchPolicy: "no-cache",
      variables: { companyId },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((data) => data.data.data);
};

export const newSaleChannel = (companyId, data) => {
  return apolloClient
    .mutate({
      mutation: createSaleChannel,
      variables: { companyId, data },
    })
    .then((res) => res.data.data);
};

export const editSaleChannel = (companyId, data, id) => {
  return apolloClient
    .mutate({
      mutation: updateSaleChannel,
      variables: { companyId, data, id },
    })
    .then((res) => res.data.data);
};

// export const createInvoiceProcessStep = (branchId, data) => {
//   return apolloClient
//     .mutate({
//       mutation: addInvoiceProcessStep,
//       variables: { branchId, data },
//     })
//     .then((res) => res.data.data);
// };

export const newProductSupplier = (companyId, data) => {
  return apolloClient
    .mutate({
      mutation: createProductSupplier,
      variables: { companyId, data },
    })
    .then((res) => res.data.data);
};

export const editProductSupplier = (companyId, data, id) => {
  return apolloClient
    .mutate({
      mutation: updateProductSupplier,
      variables: { companyId, data, id },
    })
    .then((res) => res.data.data);
};

// export const doneOrderB2B = (incomeData, data, id) => {
//   return apolloClient
//     .mutate({
//       mutation: completeOrderB2B,
//       variables: { incomeData, data, id },
//     })
//     .then((res) => res.data.data);
// };

export function findOrderTemplate(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: getOrderTemplate,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function findCart(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: getCart,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function findOrderTemplateDetail(
  where,
  limit = 25,
  skip = 0,
  orderBy = ""
) {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: getOrderTemplateDetail,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getWarehouseByCode(where, limit = 25, skip = 0, orderBy = "") {
  const branchId = where["branchId"];
  const codes = where["codes"];
  delete where["codes"];
  delete where["branchId"];
  return apolloClient
    .query({
      query: findWarehouseByCode,
      fetchPolicy: "network-only",
      variables: {
        branchId,
        codes,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function findPriceLevel(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: getPriceLevel,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((res) => res.data.data);
}

export const newPriceLevel = (req, companyId) => {
  return apolloClient
    .mutate({
      mutation: createPriceLevel,
      variables: {
        req,
        companyId,
      },
    })
    .then((res) => res.data.data);
};

export function findWorkOrders(
  where,
  limit = 25,
  skip = 0,
  orderBy = "",
  detail = false
) {
  const branchId = where["branchId"];
  delete where["branchId"];
  return apolloClient
    .query({
      query: detail ? getWorkOrderDetail : getWorkOrders,
      fetchPolicy: "network-only",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function findInvoiceProcess(where, limit = 25, skip = 0, orderBy = "") {
  const branchId = where["branchId"];
  delete where["branchId"];
  return apolloClient
    .query({
      query: getInvoiceProcess,
      fetchPolicy: "network-only",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function findWorkOrdersProcess(
  where,
  limit = 25,
  skip = 0,
  orderBy = ""
) {
  const branchId = where["branchId"];
  delete where["branchId"];
  return apolloClient
    .query({
      query: getWorkOrdersProcess,
      fetchPolicy: "network-only",
      variables: {
        branchId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export const newOrderTemplate = (companyId, data) => {
  return apolloClient
    .mutate({
      mutation: createOrderTemplate,
      variables: { companyId, data },
    })
    .then((res) => res.data.data);
};

export const editOrderTemplate = (companyId, data, id) => {
  return apolloClient
    .mutate({
      mutation: updateOrderTemplate,
      variables: { companyId, data, id },
    })
    .then((res) => res.data.data);
};

export const updateOrderDate = (branchId, dateChange, orderId) => {
  return apolloClient
    .mutate({
      mutation: changeInvoiceDate,
      variables: {
        branchId,
        dateChange,
        orderId,
      },
    })
    .then((res) => res.data.data);
};

export const updateOrder = (branchId, data, incomeData = []) => {
  return apolloClient
    .mutate({
      mutation: updateInvoice,
      variables: {
        branchId,
        data,
        incomeData,
      },
    })
    .then((res) => res.data.data);
};

export const updateBOM = (productId, companyId, data) => {
  return apolloClient
    .mutate({
      mutation: updateProductBOM,
      variables: {
        productId,
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
};

export const newWorkOrder = (req) => {
  return apolloClient
    .mutate({
      mutation: createWorkOrder,
      variables: {
        req,
      },
    })
    .then((res) => res.data.data);
};

export const createWorkOrderProcess = (data, workOrderId) => {
  return apolloClient
    .mutate({
      mutation: addWorkOrderProcess,
      variables: {
        data,
        workOrderId,
      },
    })
    .then((res) => res.data.data);
};

export const editWorkOrder = (req, workOrderId) => {
  return apolloClient
    .mutate({
      mutation: updateWorkOrder,
      variables: {
        req,
        workOrderId,
      },
    })
    .then((res) => res.data.data);
};

export const editWorkOrderProcess = (
  data,
  workOrderProcessId,
  incomeData = []
) => {
  return apolloClient
    .mutate({
      mutation: updateWorkOrderProcess,
      variables: {
        data,
        workOrderProcessId,
        incomeData,
      },
    })
    .then((res) => res.data.data);
};

export const addUserB2B = (companyId, phone, fullName, pwd) => {
  return apolloClient
    .mutate({
      mutation: createUserB2B,
      variables: {
        companyId,
        phone,
        fullName,
        pwd,
      },
    })
    .then((res) => res.data.data);
};

export const editCart = (companyId, req) => {
  return apolloClient
    .mutate({
      mutation: updateCart,
      variables: {
        companyId,
        req,
      },
    })
    .then((res) => res.data.data);
};

export function createEInvoice(branchId, orderId, cusReq) {
  return apolloClient
    .mutate({
      mutation: exportEInvoice,
      variables: {
        branchId,
        orderId,
        cusReq,
      },
    })
    .then((res) => res.data.data);
}

export function getIncomeExpenseElement(
  where,
  limit = 25,
  skip = 0,
  orderBy = ""
) {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findIncomeExpenseElement,
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function newIncomeExpenseElement(companyId, req) {
  return apolloClient
    .mutate({
      mutation: createIncomeExpenseElement,
      variables: {
        companyId,
        req,
      },
    })
    .then((res) => res.data.data);
}

export function getCustomerCompany(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findCustomerCompany,
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function newCustomerCompany(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createCustomerCompany,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function newManufactureCost(companyId, productId, data) {
  return apolloClient
    .mutate({
      mutation: createManufactureCost,
      variables: {
        companyId,
        productId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function changeReceiveBranch(
  transferId,
  receiverBranch,
  transferBranch
) {
  return apolloClient
    .mutate({
      mutation: updateReceiveBranch,
      variables: {
        transferId,
        receiverBranch,
        transferBranch,
      },
    })
    .then((res) => res.data.data);
}

export function findPurchaseOrdersSummary(branchIds, where) {
  return apolloClient
    .query({
      query: getPurchaseOrdersSummary,
      variables: {
        branchIds,
        where,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}

export function findInventoryReceiverSummary(branchIds, where) {
  return apolloClient
    .query({
      query: inventoryReceiverSummary,
      variables: {
        branchIds,
        where,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}

export function findWarehouseOutReceiptsSummaries(branchIds, where) {
  return apolloClient
    .query({
      query: warehouseOutReceiptsSummaries,
      variables: {
        branchIds,
        where,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}

export function findTransferWarehouseSummaries(branchIds, where) {
  return apolloClient
    .query({
      query: transferWarehouseSummaries,
      variables: {
        branchIds,
        where,
      },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}

export function getVolumeProductCompleted(branchIds, fromDate, toDate) {
  return apolloClient
    .query({
      query: volumeProductCompleted,
      variables: { branchIds, fromDate, toDate },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}

export function getWorkProcessMaterial(branchIds, fromDate, toDate) {
  return apolloClient
    .query({
      query: workProcessMaterial,
      variables: { branchIds, fromDate, toDate },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}

export function getVolumeMaterialsUsed(branchIds, fromDate, toDate) {
  return apolloClient
    .query({
      query: volumeMaterialsUsed,
      variables: { branchIds, fromDate, toDate },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}

export function getWorkProcessProduct(branchIds, fromDate, toDate) {
  return apolloClient
    .query({
      query: workProcessProduct,
      variables: { branchIds, fromDate, toDate },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}

export function getWorkOrderExternalCost(branchIds, fromDate, toDate) {
  return apolloClient
    .query({
      query: workOrderExternalCost,
      variables: { branchIds, fromDate, toDate },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}

export function getWorkOrderProcessByDate(branchIds, fromDate, toDate) {
  return apolloClient
    .query({
      query: workOrderProcessByDate,
      variables: { branchIds, fromDate, toDate },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}

export function getWorkProcessProductQty(branchIds, fromDate, toDate) {
  return apolloClient
    .query({
      query: workProcessProductQty,
      variables: { branchIds, fromDate, toDate },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}

export function getEmployeeSaleTarget(branchIds, fromDate, toDate) {
  return apolloClient
    .query({
      query: employeeSaleTarget,
      variables: { branchIds, fromDate, toDate },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data);
}

export function getEmployeeSaleTargetByEmployee(
  branchIds,
  fromDate,
  toDate,
  employeeIds
) {
  return apolloClient
    .query({
      query: employeeSaleTargetByEmployee,
      variables: { branchIds, fromDate, toDate, employeeIds },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data);
}

export function getEmployeeRevenueReport(branchIds, fromDate, toDate) {
  return apolloClient
    .query({
      query: employeeRevenueReport,
      variables: { branchIds, fromDate, toDate },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data);
}

export function getIncomeExpensePaymentSummary(branchId, date, code) {
  return apolloClient
    .query({
      query: incomeExpensePaymentSummary,
      variables: { branchId, date, code },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}
export function findInvoiceSummaryByCusCom(
  companyId,
  fromDate,
  toDate,
  cusComId
) {
  return apolloClient
    .query({
      query: getInvoiceSummaryByCusCom,
      variables: { companyId, fromDate, toDate, cusComId },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}

export function getWorkOrderProcessMaterialDetail(where, limit = 25, skip = 0) {
  const branchIds = where["branchIds"];
  const fromDate = where["fromDate"];
  const toDate = where["toDate"];

  delete where["branchIds"];
  delete where["toDate"];
  delete where["fromDate"];
  return apolloClient
    .query({
      query: workOrderProcessMaterialDetail,
      fetchPolicy: "network-only",
      variables: {
        toDate,
        where,
        fromDate,
        branchIds,
        limit,
        skip,
      },
    })
    .then((res) => res.data.data);
}

export function getWorkOrderProcessProductDetail(where, limit = 25, skip = 0) {
  const branchIds = where["branchIds"];
  const fromDate = where["fromDate"];
  const toDate = where["toDate"];

  delete where["branchIds"];
  delete where["toDate"];
  delete where["fromDate"];
  return apolloClient
    .query({
      query: workOrderProcessProductDetail,
      fetchPolicy: "network-only",
      variables: {
        toDate,
        where,
        fromDate,
        branchIds,
        limit,
        skip,
      },
    })
    .then((res) => res.data.data);
}

export function getSearchHistory(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];

  delete where["companyId"];
  return apolloClient
    .query({
      query: findSearchHistory,
      fetchPolicy: "network-only",
      variables: {
        where,
        companyId,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function newSearchHistory(companyId, data) {
  return apolloClient
    .query({
      query: createSearchHistory,
      variables: { companyId, data },
      fetchPolicy: "no-cache",
    })
    .then((res) => res.data.data);
}

export function getSalesTarget(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];

  delete where["companyId"];
  return apolloClient
    .query({
      query: findSalesTarget,
      fetchPolicy: "network-only",
      variables: {
        where,
        companyId,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getSalesTargetGroup(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];

  delete where["companyId"];
  return apolloClient
    .query({
      query: findSalesTargetGroup,
      fetchPolicy: "network-only",
      variables: {
        where,
        companyId,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getDepartment(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];

  delete where["companyId"];
  return apolloClient
    .query({
      query: findDepartment,
      fetchPolicy: "network-only",
      variables: {
        where,
        companyId,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getAssetType(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];

  delete where["companyId"];
  return apolloClient
    .query({
      query: findAssetType,
      fetchPolicy: "network-only",
      variables: {
        where,
        companyId,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getAsset(where, limit = 25, skip = 0, orderBy = "") {
  const branchIds = where["branchIds"];

  delete where["branchIds"];
  return apolloClient
    .query({
      query: findAsset,
      fetchPolicy: "network-only",
      variables: {
        where,
        branchIds,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function newDepartment(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createDepartment,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function newSaleTargetGroup(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createSaleTargetGroup,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function newSaleTarget(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createSaleTarget,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function createServiceReceipt(branchId, data, incomeData) {
  return apolloClient
    .mutate({
      mutation: addServiceReceipt,
      variables: {
        branchId,
        data,
        incomeData,
      },
    })
    .then((res) => res.data.data);
}

export function newAssetType(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createAssetType,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function newAsset(branchId, data, incomeData) {
  return apolloClient
    .mutate({
      mutation: createAsset,
      variables: {
        branchId,
        data,
        incomeData,
      },
    })
    .then((res) => res.data.data);
}

export function getPrintTemplate(where, limit = 25, skip = 0, orderBy = "") {
  const branchId = where["branchId"];

  delete where["branchId"];
  return apolloClient
    .query({
      query: findPrintTemplate,
      fetchPolicy: "network-only",
      variables: {
        where,
        branchId,
        limit,
        skip,
        orderBy,
      },
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    })
    .then((res) => res.data.data);
}

export function newPrintTemplate(data, branchId) {
  return apolloClient
    .mutate({
      mutation: createPrintTemplate,
      variables: {
        data,
        branchId,
      },
    })
    .then((res) => res.data.data);
}

export function getCredit(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];

  delete where["companyId"];
  return apolloClient
    .query({
      query: findCredit,
      fetchPolicy: "network-only",
      variables: {
        where,
        companyId,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function newCredit(data, companyId, incomeData) {
  return apolloClient
    .mutate({
      mutation: createCredit,
      variables: {
        data,
        companyId,
        incomeData,
      },
    })
    .then((res) => res.data.data);
}

export function getInventoryWarehouseReport(
  where,
  limit = 25,
  skip = 0,
  orderBy = ""
) {
  const branchIds = where["branchIds"];
  const fromDate = where["fromDate"];
  const toDate = where["toDate"];

  delete where["branchIds"];
  delete where["fromDate"];
  delete where["toDate"];
  return apolloClient
    .query({
      query: inventoryWarehouseReport,
      fetchPolicy: "network-only",
      variables: {
        branchIds,
        fromDate,
        toDate,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export const findAssetReport = (fromDate, toDate, branchIds) => {
  return apolloClient
    .query({
      query: getAssetReport,
      fetchPolicy: "no-cache",
      variables: { fromDate, toDate, branchIds },
    })
    .then((data) => data.data.data);
};

export function getCustomerDebtReport(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const branchIds = where["branchIds"];
  const fromDate = where["fromDate"];
  const toDate = where["toDate"];

  delete where["branchIds"];
  delete where["toDate"];
  delete where["fromDate"];
  return apolloClient
    .query({
      query: customerDebtReport,
      fetchPolicy: "network-only",
      variables: {
        toDate,
        fromDate,
        branchIds,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getPersonInChargeReport(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const branchIds = where["branchIds"];
  const fromDate = where["fromDate"];
  const toDate = where["toDate"];

  delete where["branchIds"];
  delete where["toDate"];
  delete where["fromDate"];
  return apolloClient
    .query({
      query: personInChargeReport,
      fetchPolicy: "network-only",
      variables: {
        where,
        toDate,
        fromDate,
        branchIds,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getBranchesDebtReport(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const branchIds = where["branchIds"];
  const fromDate = where["fromDate"];
  const toDate = where["toDate"];

  delete where["branchIds"];
  delete where["toDate"];
  delete where["fromDate"];
  return apolloClient
    .query({
      query: branchesDebtReport,
      fetchPolicy: "network-only",
      variables: {
        where,
        toDate,
        fromDate,
        branchIds,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getCustomerProductSaleReport(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  const branchIds = where["branchIds"];
  const fromDate = where["fromDate"];
  const toDate = where["toDate"];

  delete where["branchIds"];
  delete where["toDate"];
  delete where["fromDate"];
  return apolloClient
    .query({
      query: customerProductSaleReport,
      fetchPolicy: "network-only",
      variables: {
        where,
        toDate,
        fromDate,
        branchIds,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

// saleProductByChannels,
// saleProductByStock,
// productInventoryByStock,

export function getProductInventoryByStock(where) {
  const branchIds = where["branchIds"];
  // const fromDate = where["fromDate"];
  // const toDate = where["toDate"];

  delete where["branchIds"];
  // delete where["toDate"];
  // delete where["fromDate"];
  return apolloClient
    .query({
      query: productInventoryByStock,
      fetchPolicy: "network-only",
      variables: {
        where,
        branchIds,
      },
    })
    .then((res) => res.data.data);
}

export function getSaleProductByChannels(where) {
  const branchIds = where["branchIds"];
  const fromDate = where["fromDate"];
  const toDate = where["toDate"];

  delete where["branchIds"];
  delete where["toDate"];
  delete where["fromDate"];
  return apolloClient
    .query({
      query: saleProductByChannels,
      fetchPolicy: "network-only",
      variables: {
        where,
        toDate,
        fromDate,
        branchIds,
      },
    })
    .then((res) => res.data.data);
}

export function getSaleProductByStock(where) {
  const branchIds = where["branchIds"];
  const fromDate = where["fromDate"];
  const toDate = where["toDate"];

  delete where["branchIds"];
  delete where["toDate"];
  delete where["fromDate"];
  return apolloClient
    .query({
      query: saleProductByStock,
      fetchPolicy: "network-only",
      variables: {
        where,
        toDate,
        fromDate,
        branchIds,
      },
    })
    .then((res) => res.data.data);
}

export function getSaleProductByOrderType(where) {
  const branchIds = where["branchIds"];
  const fromDate = where["fromDate"];
  const toDate = where["toDate"];

  delete where["branchIds"];
  delete where["toDate"];
  delete where["fromDate"];
  return apolloClient
    .query({
      query: saleProductByOrderType,
      fetchPolicy: "network-only",
      variables: {
        where,
        toDate,
        fromDate,
        branchIds,
      },
    })
    .then((res) => res.data.data);
}

export function getExportProductByType(where) {
  const branchIds = where["branchIds"];
  const fromDate = where["fromDate"];
  const toDate = where["toDate"];

  delete where["branchIds"];
  delete where["toDate"];
  delete where["fromDate"];
  return apolloClient
    .query({
      query: exportProductByType,
      fetchPolicy: "network-only",
      variables: {
        where,
        toDate,
        fromDate,
        branchIds,
      },
    })
    .then((res) => res.data.data);
}

export function createProductShopee(shopId, req) {
  return apolloClient
    .mutate({
      mutation: addProductShopee,
      variables: {
        shopId,
        req,
      },
    })
    .then((res) => res.data.data);
}

export function syncProductShopeeToApp(shopId) {
  return apolloClient
    .mutate({
      mutation: syncProductToApp,
      variables: {
        shopId,
      },
    })
    .then((res) => res.data.data);
}

export function getShopMarketIds(shopId) {
  return apolloClient
    .query({
      query: getShopIds,
      fetchPolicy: "network-only",
      variables: {
        shopId,
      },
    })
    .then((res) => res.data.data);
}

export function getShopMarketInfo(shopId) {
  return apolloClient
    .query({
      query: getShopInfo,
      fetchPolicy: "network-only",
      variables: {
        shopId,
      },
    })
    .then((res) => res.data.data);
}

export function getProductEcommerce(
  where,
  limit = 25,
  skip = 0,
  orderBy = "id_desc"
) {
  return apolloClient
    .query({
      query: getProductSyncApp,
      fetchPolicy: "network-only",
      variables: {
        limit,
        where,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getCategoryShopee(shopId) {
  return apolloClient
    .query({
      query: getCategory,
      fetchPolicy: "network-only",
      variables: {
        shopId,
      },
    })
    .then((res) => res.data.data.data.response.categoryItems);
}

export function getCategoryRecommendShopee(shopId) {
  return apolloClient
    .query({
      query: getCategoryRecommend,
      fetchPolicy: "network-only",
      variables: {
        shopId,
      },
    })
    .then((res) => res.data.data.data.response);
}

export function getAttributesShopee(shopId) {
  return apolloClient
    .query({
      query: getAttributes,
      fetchPolicy: "network-only",
      variables: {
        shopId,
      },
    })
    .then((res) => res.data.data);
}

export function getAttributesRecommendShopee(shopId) {
  return apolloClient
    .query({
      query: getAttributesRecommend,
      fetchPolicy: "network-only",
      variables: {
        shopId,
      },
    })
    .then((res) => res.data.data);
}

export function findBrands(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: getBrands,
      fetchPolicy: "no-cache",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function getShippingLimitShopee(shopId) {
  return apolloClient
    .query({
      query: getShippingLimit,
      fetchPolicy: "network-only",
      variables: {
        shopId,
      },
    })
    .then((res) => res.data.data);
}

export function getChannelShippingShopee(shopId) {
  return apolloClient
    .query({
      query: getChannelShipping,
      fetchPolicy: "network-only",
      variables: {
        shopId,
      },
    })
    .then((res) => res.data.data);
}

export function getNoticeList(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findNotice,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function createNewNotice(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createNotice,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function getEmployeeFundTypes(
  where,
  limit = 25,
  skip = 0,
  orderBy = ""
) {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findEmployeeFundType,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function createNewEmployeeFundType(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createEmployeeFundType,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function getEmployeeFund(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findEmployeeFund,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function createNewEmployeeFund(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createEmployeeFund,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

////

export function getCustomerCareTypes(
  where,
  limit = 25,
  skip = 0,
  orderBy = ""
) {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findCustomerCareType,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function createNewCustomerCareType(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createCustomerCareType,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function getCustomerCare(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findCustomerCare,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function createNewCustomerCare(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createCustomerCare,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function createNewCustomerCareResult(companyId, customerCareId, req) {
  return apolloClient
    .mutate({
      mutation: createCustomerCareResult,
      variables: {
        companyId,
        customerCareId,
        req,
      },
    })
    .then((res) => res.data.data);
}

export function getCampaigns(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findCampaign,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function createNewCampaign(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createCampaign,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

///

export function getCustomerDebt(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findCustomerDebt,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function newCustomerDebt(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createCustomerDebt,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function getPosts(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findPosts,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function createNewPost(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createPost,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function getOrderBulks(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findOrderBulks,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function createNewOrderBulk(companyId, data) {
  return apolloClient
    .mutate({
      mutation: createOrderBulk,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function getDiscussionMsg(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findDiscussionMsg,
      fetchPolicy: "network-only",
      variables: {
        companyId,
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function createDiscussion(companyId, data) {
  return apolloClient
    .mutate({
      mutation: addDiscussion,
      variables: {
        companyId,
        data,
      },
    })
    .then((res) => res.data.data);
}

export function getContracts(where, limit = 25, skip = 0, orderBy = "") {
  return apolloClient
    .query({
      query: findContracts,
      fetchPolicy: "network-only",
      variables: {
        where,
        limit,
        skip,
        orderBy,
      },
    })
    .then((res) => res.data.data);
}

export function addContract(data) {
  return apolloClient
    .mutate({
      mutation: updateContract,
      variables: {
        data,
      },
    })
    .then((res) => res.data.data);
}
// driver

export function getDrivers(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findDrivers,
      fetchPolicy: "network-only",
      variables: {
        where,
        limit,
        skip,
        orderBy,
        companyId,
      },
    })
    .then((res) => res.data.data);
}

export function addDriver(data, companyId) {
  return apolloClient
    .mutate({
      mutation: createDrivers,
      variables: {
        data,
        companyId,
      },
    })
    .then((res) => res.data.data);
}

export function getVehicles(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findVehicle,
      fetchPolicy: "network-only",
      variables: {
        where,
        limit,
        skip,
        orderBy,
        companyId,
      },
    })
    .then((res) => res.data.data);
}

export function addVehicle(data, companyId) {
  return apolloClient
    .mutate({
      mutation: createVehicle,
      variables: {
        data,
        companyId,
      },
    })
    .then((res) => res.data.data);
}

export function getShippingBooking(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findShippingBooking,
      fetchPolicy: "network-only",
      variables: {
        where,
        limit,
        skip,
        orderBy,
        companyId,
      },
    })
    .then((res) => res.data.data);
}

export function addShippingBooking(data, companyId) {
  return apolloClient
    .mutate({
      mutation: createShippingBooking,
      variables: {
        data,
        companyId,
      },
    })
    .then((res) => res.data.data);
}

export function getRoute(where, limit = 25, skip = 0, orderBy = "") {
  const companyId = where["companyId"];
  delete where["companyId"];
  return apolloClient
    .query({
      query: findRoute,
      fetchPolicy: "network-only",
      variables: {
        where,
        limit,
        skip,
        orderBy,
        companyId,
      },
    })
    .then((res) => res.data.data);
}

export function addRoute(data, companyId) {
  return apolloClient
    .mutate({
      mutation: createRoutes,
      variables: {
        data,
        companyId,
      },
    })
    .then((res) => res.data.data);
}
