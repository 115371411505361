<template>
  <div class="auto-complete" v-outside="outside" :class="{ focused: showList }">
    <input
      v-model="searchValue"
      ref="input"
      :disabled="disabled"
      @keyup="onChange"
      @click="onFocus()"
      class="form-control"
      :class="{
        'form-control-sm': small,
        error: required && !value,
      }"
      :placeholder="placeholder || 'Chọn '"
    />
    <div
      v-if="showList"
      class="search-list"
      :class="{ 'search-list-top': position == 'top' || isTop }"
    >
      <div
        v-for="(item, idx) in showedList"
        :tabindex="idx"
        :key="item.key"
        class="search-item"
        :class="{ active: !multi && item.id == value }"
        @click="onSelect(item)"
      >
        <check-box
          v-if="multi"
          :value="(value || []).indexOf(item.id) > -1"
          :title="item.name"
        />
        <template v-else>
          <slot name="item" :item="item">
            <span>{{ item.name }}</span>
          </slot>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "list",
    "multi",
    "value",
    "placeholder",
    "disabled",
    "isDynamic",
    "small",
    "required",
    "position",
    "valueShow",
    "searchFunction",
  ],
  data: function () {
    return {
      searchValue: "",
      originalList: [],
      showedList: [],
      showList: false,
      isTop: false,
    };
  },
  created() {
    this.originalList = JSON.parse(JSON.stringify(this.$props.list || []));
    this.showedList = this.originalList;
    this.getName();
  },
  watch: {
    list: function () {
      this.originalList = JSON.parse(JSON.stringify(this.$props.list || []));
      this.showedList = this.originalList;
      if (!this.isDynamic) {
        this.getName();
      }
    },
    value: function () {
      this.getName();
    },
  },
  methods: {
    getName() {
      if (this.multi) {
        this.searchValue = this.originalList
          .filter((i) => (this.value || "").indexOf(i.id) > -1)
          .map((i) => i.name)
          .join(",");
      } else {
        var item = this.originalList.find((i) => i.id == this.value);
        if (!item) {
          item = this.showedList.find((i) => i.id == this.value);
        }
        if (item) {
          this.searchValue = item.name;
        } else {
          this.searchValue = this.valueShow || '';
        }
      }
    },
    onChange(e) {
      if (this.multi) {
        return;
      }
      let key = e.target.value;

      if (this.searchFunction) {
        const findFn = () => {
          this.searchFunction(key).then((res) => {
            this.showedList = res;
          });
        };
        findFn();
        // _.debounce(findFn, 500);
      } else {
        this.showedList = this.originalList.filter((p) =>
          this.$changeAlias(p.name + p.search).includes(this.$changeAlias(key))
        );
      }
      this.$emit("change", key);
    },
    onSelect(item) {
      if (this.multi) {
        var temp = this.value || [];
        if (temp.indexOf(item.id) > -1) {
          temp = temp.filter((i) => i != item.id);
        } else {
          temp.push(item.id);
        }
        this.$emit("input", temp);
        this.$emit("change", temp);
        this.$emit("changeOption", temp);
        this.searchValue = this.originalList
          .filter((i) => temp.indexOf(i.id) > -1)
          .map((i) => i.name)
          .join(",");
      } else {
        console.log(item);
        this.$emit("input", item.id);
        this.$emit("change", item.id);
        this.$emit("changeOption", item.id);
        this.searchValue = item.name;
        this.showList = false;
      }
    },
    onFocus() {
      this.showList = true;
      this.$refs.input.select();
      this.showedList = this.originalList;
      this.isTop =
        window.innerHeight -
          this.$refs.input.getBoundingClientRect().top +
          window.scrollY <
        200;
    },
    outside() {
      if (this.showList === true) {
        this.showList = false;
      }
      if (this.multi) {
        this.searchValue = this.originalList
          .filter((i) => (this.value || "").indexOf(i.id) > -1)
          .map((i) => i.name)
          .join(",");
      }
    },
  },
};
</script>
